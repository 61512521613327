import React, { useEffect, useRef, useState } from 'react'
import Theme from '@styles/theme'
import Input from '@components/Micro/Fields/Input'
import { CreateHighlightList, HighlightsList } from '@customTypes/highlight'
import useBrandings from '@helpers/hooks/useBrandings'
import { SimpleGrid } from '@styles/Grid'
import SelectField from '@components/Micro/Fields/Select'
import CategoryHeader from '@components/Micro/Content/CategoryHeader'
import Domains from '@components/Templates/Domains'
import useDomains from '@helpers/hooks/useDomains'
import { watchlistFormClasses } from './classes'
import { CreateWatchlistButton } from './style'
import { useAxiosCall } from '@helpers/hooks/useAxios'
import { routes } from '@helpers/hooks/useAplozeApi'
import router from 'next/router'
import validate from '@helpers/validate'
import { Domain } from '@customTypes/channel'
import { SaveStatus } from '@customTypes/common'

interface WatchlistForm {
	name: string
	brandingId: string
	domains: Domain[]
}

interface CreateWatchListFormProps {
	onSubmit?: (watchlits: HighlightsList) => void
}

const initState = {
	name: '',
	brandingId: '',
	domains: []
}

const CreateWatchListForm: React.FC<CreateWatchListFormProps> = ({ onSubmit }) => {
	const channelId = router.query.channelId as string
	const nameRef = useRef(null)
	const [formData, setFormData] = useState<WatchlistForm>(initState)
	const { brandings } = useBrandings()
	const domains = useDomains()
	const [{ data: createWatchlitData, loading: createWatchlitLoading }, createWatchlit] = useAxiosCall({
		method: 'POST'
	})

	const toCreateHighlightsList = (): CreateHighlightList => ({
		name: formData.name,
		brandingId: formData.brandingId,
		domainIds: formData.domains.map((d) => d.uuid)
	})

	const validateForm = () => {
		if (!validate(formData.name, { type: 'string', min: 2, max: 100 })) {
			nameRef.current.setError()
			return false
		}
		if (!formData.brandingId) return false
		return true
	}

	const handleCreate = () => {
		if (validateForm()) {
			createWatchlit({ url: routes.watchlists(channelId) }, { data: toCreateHighlightsList() })
		}
	}

	const handleSaveStatus = (): SaveStatus => {
		if (createWatchlitLoading) return 'saving'
		return !!formData.name ? 'hasChange' : 'noChange'
	}

	useEffect(() => {
		if (createWatchlitData && !createWatchlitLoading) {
			onSubmit(createWatchlitData)
			setFormData(initState)
		}
	}, [createWatchlitData, createWatchlitLoading])

	useEffect(() => {
		if (brandings?.length && !formData.brandingId) {
			setFormData({ ...formData, brandingId: brandings[0].uuid })
		}
	}, [brandings])

	return (
		<>
			<Input
				label="PLAYLIST NAME"
				name="playlistName"
				innerRef={nameRef}
				value={formData?.name}
				maxLength={20}
				suffix={!formData?.name ? 20 : 20 - formData?.name.length}
				onChange={(e) => {
					setFormData({ ...formData, name: e.target.value })
				}}
			/>
			{brandings && (
				<div className={watchlistFormClasses.brandings}>
					<SelectField
						label={'BRANDING'}
						name={'branding'}
						entries={[{ entries: brandings }]}
						position="bottom"
						initialValue={brandings.findIndex((item) => item.uuid === formData?.brandingId)}
						onChange={(e) => setFormData({ ...formData, brandingId: brandings[e.target.value].uuid })}
					/>
				</div>
			)}
			{domains && (
				<SimpleGrid gap={Theme.spacing(6)} className={watchlistFormClasses.domains}>
					<CategoryHeader iconName="link" title="Domains" noMt noMb />
					<div>
						<Domains domains={domains} formDatas={formData} setFormDatas={setFormData} isTest={false} />
					</div>
				</SimpleGrid>
			)}
			<CreateWatchlistButton
				alwaysSave
				status={handleSaveStatus()}
				label="Create"
				savingLabel="Creating..."
				onClick={handleCreate}
				className={watchlistFormClasses.button}
			/>
		</>
	)
}

export default CreateWatchListForm
