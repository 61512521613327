import styled from '@emotion/styled'
import { css } from '@emotion/react'

export type Griddable = {
	gridArea?: string
}

export const handleGriddable = ({ gridArea }: Griddable) => {
	return gridArea
		? css`
				grid-area: ${gridArea};
		  `
		: null
}

export const makeClickable = () => css`
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
`

// Use this anywhere you'd like the text to wrap like this 'Lorem Ipsum Dolor s...'
export const ellipsisText = css`
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

// Ellipsis after X rows
export const ellipsisRows = (rowsNumber: number) => css`
	-webkit-line-clamp: ${rowsNumber};
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
`

export const HiddenInput = styled.input`
	display: none;
`
