import Icon from '@components/Micro/Icon'
import Popin from '@components/Templates/Popin'
import Theme from '@styles/theme'
import React from 'react'
import { Box } from 'rebass'
import { classes, NotificationContent, NotificationPopinWrapper, NotificationTexts, NotificationType } from './style'

export interface NotificationProps {
	isOpened?: boolean
	icon?: any
	text?: string | Array<string>
	autohideDuration?: number
	onClose?: () => void
	variant?: NotificationType
}

const Notification: React.FC<NotificationProps> = ({ icon, text, variant, ...props }) => {
	return (
		<NotificationPopinWrapper variant={variant}>
			<Popin noClose noLock {...props}>
				<NotificationContent onClick={props.onClose}>
					{icon && (
						<Box className={classes.icon}>
							<Icon name={icon} width="100%" height="100%" fill={Theme.colors.white()} />
						</Box>
					)}
					{Array.isArray(text) && (
						<NotificationTexts>
							{text.map((textContent, i) => (
								<Box key={`notification-text-${i}`} className={classes.text}>
									{textContent}
								</Box>
							))}
						</NotificationTexts>
					)}
					{typeof text === 'string' && <Box className={classes.text}>{text || 'Error'}</Box>}
				</NotificationContent>
			</Popin>
		</NotificationPopinWrapper>
	)
}

export default Notification
