import {
	AllHighlightsAnalyticsRequest,
	AllHighlightsAnalyticsResponse
} from '@customTypes/analytics/all_highlights_report'
import { OverallAnalyticReport, OverallComsumption } from '@customTypes/analytics/dashboard'
import {
	HighlighAnalyticsRequest,
	HighlighAnalyticsResponse,
	HighlightAnalyticsReport
} from '@customTypes/analytics/highlight_analytics_report'
import { ApiRequestResult } from '@customTypes/api'
import { SubscriptionPlan, SubscriptionPlanCreation, Domain } from '@customTypes/channel'
import { MediaFile } from '@customTypes/common'
import { Event, RestartEventRecordRequest } from '@customTypes/event'
import { ExportEventDataRequest, ExportEventDataResult, ExportHighlightDataRequest } from '@customTypes/export'
import { Boost, Highlight, HighlightCreation } from '@customTypes/highlight'
import {
	CreateSynchronisedProductsResponse,
	SearchCatalogProductsRequest,
	SearchCatalogProductsResponse
} from '@customTypes/catalog_product'
import {
	StreamingDestination,
	StreamingDestinationCreation,
	StreamingDestinationUpdate
} from '@customTypes/streaming_destinations'
import { useAuth } from '@helpers/contexts/authUserContext'
import { onError, onResponse } from '@helpers/fetchHelpers'

import getConfig from 'next/config'
import { Branding } from 'types/branding'
import { LiveProgram } from 'types/live_program'
import { Paginated } from 'types/pagination'
import { Product, PublicProductInformation } from 'types/product'
const { publicRuntimeConfig } = getConfig()

const NEXT_PUBLIC_API_ENDPOINT = publicRuntimeConfig.NEXT_PUBLIC_API_ENDPOINT

export const routes = {
	upload: (channelId) => `/api/channels/${channelId}/files`,
	replay_upload: (channelId, eventId) => `/api/channels/${channelId}/events/${eventId}/upload_replay`,
	deleteFile: (channelId: string, fileId: string) => `/api/channels/${channelId}/files/${fileId}`,

	// channels
	channels: () => `/api/channels`,
	channel: (channelId) => `/api/channels/${channelId}`,
	channelLegalSettings: (channelId) => `/api/channels/${channelId}/legal_settings`,
	channelCatalogSettings: (channelId) => `/api/channels/${channelId}/catalog_settings`,

	eventLegalSettings: (channelId, eventId) => `/api/channels/${channelId}/events/${eventId}/legal_settings`,
	events: (channelId) => `/api/channels/${channelId}/events`,
	event: (channelId, eventId = '') => `/api/channels/${channelId}/events/${eventId}`,
	eventLiveStats: (channelId, eventId = '') => `/api/channels/${channelId}/events/${eventId}/livestats`,
	duplicateEvent: (channelId, eventId = '') => `/api/channels/${channelId}/events/${eventId}/duplicate`,
	blacklist: (channelId, eventId) => `/api/channels/${channelId}/events/${eventId}/blacklist`,
	banEventUser: (channelId, eventId, userId) => `/api/channels/${channelId}/events/${eventId}/users/${userId}/ban`,
	publicEvent: (eventId) => `/api/public/events/${eventId}`,
	publicEventMessages: (eventId) => `/api/public/events/${eventId}/messages`,
	createAdminMessage: (channelId, eventId) => `/api/channels/${channelId}/events/${eventId}/messages`,
	updateAdminMessage: (channelId, eventId, messageId) =>
		`/api/channels/${channelId}/events/${eventId}/messages/${messageId}`,
	program: (channelId, eventId) => `/api/channels/${channelId}/events/${eventId}/program`,
	replayProgram: (channelId, eventId) => `/api/channels/${channelId}/events/${eventId}/replay_program`,
	products: (channelId) => `/api/channels/${channelId}/products`,
	product: (channelId, productId = '') => `/api/channels/${channelId}/products/${productId}`,
	brandings: (channelId) => `/api/channels/${channelId}/brandings`,
	branding: (channelId, brandingId) => `/api/channels/${channelId}/brandings/${brandingId}`,
	user: (userId) => `/api/users/${userId}`,
	picture: (userId) => `/api/users/${userId}/picture`,
	channelUsers: (channelId) => `/api/channels/${channelId}/users`,
	channelUser: (channelId, userId) => `/api/channels/${channelId}/users/${userId}`,
	domains: (channelId) => `/api/channels/${channelId}/domains`,
	domain: (channelId, domainId) => `/api/channels/${channelId}/domains/${domainId}`,
	resetPassword: () => `/api/passwords/reset`,
	polls: (channelId, eventId) => `/api/channels/${channelId}/events/${eventId}/polls`,
	poll: (channelId, eventId, pollId) => `/api/channels/${channelId}/events/${eventId}/polls/${pollId}`,
	actOnPoll: (channelId, eventId, pollId, action) =>
		`/api/channels/${channelId}/events/${eventId}/polls/${pollId}/${action}`,
	winningInstants: (channelId, eventId) => `/api/channels/${channelId}/events/${eventId}/winning_instants`,
	winningInstant: (channelId, eventId, wiId) =>
		`/api/channels/${channelId}/events/${eventId}/winning_instants/${wiId}`,
	actOnWinningInstant: (channelId, eventId, wiId, action) =>
		`/api/channels/${channelId}/events/${eventId}/winning_instants/${wiId}/${action}`,
	eventsAnalytics: (channelId, eventId) => `/api/channels/${channelId}/events/${eventId}/analytics`,
	// old routes
	startLive: (channelId, eventId) => `/api/channels/${channelId}/events/${eventId}/live`,
	resetLive: (channelId, eventId) => `/api/channels/${channelId}/events/${eventId}/reset`,
	createTimecode: (eventId) => `/api/events/${eventId}/timecodes`,

	//streaming destinations
	destinations: (channelId: string) => `/api/channels/${channelId}/streaming_destinations`,
	destination: (channelId: string, destinationId: string) =>
		`/api/channels/${channelId}/streaming_destinations/${destinationId}`,

	//product scraper
	productInformation: (channelId: string) => `/api/channels/${channelId}/product-scraper`,

	// csats
	csat: (channelId: string, eventId: string) => `/api/channels/${channelId}/events/${eventId}/csat`,

	// data export
	exportEventData: (channelId: string, eventId: string) => `/api/channels/${channelId}/events/${eventId}/data_export`,
	exportAllHighlightsData: (channelId: string) => `/api/channels/${channelId}/highlights_lists/data_export`,
	exportWatchlistData: (channelId: string, highlightsListId: string) =>
		`/api/channels/${channelId}/highlights_lists/${highlightsListId}/data_export`,
	exportHighlightData: (channelId: string, highlightId: string) =>
		`/api/channels/${channelId}/highlights/${highlightId}/data_export`,

	// test display conditions
	testConditions: (channelId: string) => `/api/channels/${channelId}/test_condition`,

	// highlights
	highlights: (channelId: string) => `/api/channels/${channelId}/highlights`,
	highlight: (channelId: string, highlightId: string) => `/api/channels/${channelId}/highlights/${highlightId}`,
	uploadHighlightVideo: (channelId: string) => `/api/channels/${channelId}/highlights/upload_video`,
	defaultWatchlist: (channelId: string) => `/api/channels/${channelId}/highlights_lists/get_default`,
	watchlists: (channelId: string) => `/api/channels/${channelId}/highlights_lists`,
	watchlist: (channelId: string, highlightsListId: string) =>
		`/api/channels/${channelId}/highlights_lists/${highlightsListId}`,
	highlightAnalytics: (channelId: string, highlightId: string) =>
		`/api/channels/${channelId}/highlights/${highlightId}/analytics`,
	highlightChannelAnalytics: (channelId: string) => `/api/channels/${channelId}/analytics/highlights`,
	highlightsListAnalytics: (channelId: string, highlightsListId: string) =>
		`/api/channels/${channelId}/analytics/highlights_lists/${highlightsListId}`,
	boosts: (channelId: string, highlightId: string) => `/api/channels/${channelId}/highlights/${highlightId}/boosts`,
	boost: (channelId: string, highlightId: string, boostId: string) =>
		`/api/channels/${channelId}/highlights/${highlightId}/boosts/${boostId}`,
	restartHighlightConversion: (channelId: string, highlightId: string) =>
		`/api/channels/${channelId}/highlights/${highlightId}/restartConversion`,

	// translations
	translations: (channelId: string) => `/api/channels/${channelId}/translations`,

	// channel_plans
	subscriptionPlans: (channelId: string) => `/api/channels/${channelId}/subscription_plans`,

	// dashboard analytics
	overallAnalytics: (channelId: string) => `/api/channels/${channelId}/analytics/dashboard`,

	// overall consummed minutes
	overallConsumedMinutes: (channelId: string) => `/api/channels/${channelId}/minutes_consumed`,

	//synchronised products
	iadvizeProductsSearch: (channelId: string) => `/api/channels/${channelId}/iadvize-products-search`,
	catalogFeedSearch: (channelId: string) => `/api/channels/${channelId}/catalog-feed-search`,
	createSynchronisedProducts: (channelId: string) => `/api/channels/${channelId}/synchronised-products`,

	//event records
	restartEventRecordConversion: (channelId: string, recordId: string) =>
		`/api/channels/${channelId}/records/${recordId}/restartRecordProcess`
}

export default function useAplozeApi() {
	//

	const { authUser, getUserToken } = useAuth()

	const processOld = (route, method = 'GET', body = {}) => {
		return fetch(NEXT_PUBLIC_API_ENDPOINT + route, {
			body: JSON.stringify(body),
			headers: {
				'Content-Type': 'application/json'
			},
			method
		})
			.then((r) => r.json())
			.then((response) => onResponse(response, authUser), onError)
	}

	const _process = (route, method = 'GET', body = {}): Promise<Response> => {
		return getUserToken().then((token: string) =>
			fetch(NEXT_PUBLIC_API_ENDPOINT + route + '?new_format=true', {
				method,
				headers: {
					Authorization: token,
					'Content-type': 'application/json; charset=UTF-8'
				},
				body: Object.keys(body).length ? JSON.stringify(body) : null
			})
		)
	}

	const process = <T,>(route, method = 'GET', body = {}, bypassErrors: boolean = false): Promise<T> => {
		return _process(route, method, body)
			.then((r) => (r.status !== 204 ? r.json() : null))
			.then<T, T>((response) => onResponse(response, authUser, bypassErrors), onError)
	}

	const processSafe = <T,>(route, method = 'GET', body = {}, timeout = 10000): Promise<ApiRequestResult<T>> => {
		return getUserToken().then((token: string) =>
			fetch(NEXT_PUBLIC_API_ENDPOINT + route + '?new_format=true', {
				method,
				headers: {
					Authorization: token,
					'Content-type': 'application/json; charset=UTF-8'
				},
				body: Object.keys(body).length ? JSON.stringify(body) : null,
				// @ts-ignore
				timeout: timeout
			})
				.then((r) => {
					if (r.status === 204) {
						return { _type: 'Success', value: null }
					} else {
						return r.json()
					}
				})
				.then((json) =>
					json.errors
						? {
								_type: 'Failure',
								errors: json.errors
						  }
						: {
								_type: 'Success',
								value: json as T
						  }
				)
				.then<ApiRequestResult<T>, ApiRequestResult<T>>((response) => onResponse(response, authUser), onError)
		)
	}

	// files

	const setFile = (channelId, data, file = 'image.png') => {
		return getUserToken().then((token: string) =>
			fetch(NEXT_PUBLIC_API_ENDPOINT + routes.upload(channelId), {
				method: 'POST',
				headers: { Authorization: token, 'Content-Type': 'application/json' },
				body: JSON.stringify({
					fileName: file,
					mimeType: data.type,
					size: data.size
				})
			})
				.then((r) => r.json())
				.then((result) => {
					return fetch(result.presignedUrl, {
						method: 'PUT',
						body: data
					}).then((_) => onResponse(result, authUser), onError)
				})
		)
	}
	// replay_video

	const makeRequestWithProgress = <T,>(method, mediaFileResult, data, onProgressCallback): Promise<T> => {
		return new Promise((resolve, reject) => {
			const xhr = new XMLHttpRequest()
			xhr.open(method, mediaFileResult.presignedUrl)
			xhr.upload.addEventListener('progress', (e) => {
				// upload progress as percentage
				onProgressCallback((e.loaded / e.total) * 100)
			})
			xhr.onload = () => {
				resolve(mediaFileResult)
			}
			xhr.onerror = () => {
				reject({
					status: this.status,
					statusText: xhr.statusText
				})
			}
			xhr.send(data)
		})
	}

	const uploadReplayVideo = (
		channelId,
		eventId,
		data,
		file = 'replay_video.mp4',
		onProgressCallback
	): Promise<MediaFile> => {
		return getUserToken().then((token: string) =>
			fetch(NEXT_PUBLIC_API_ENDPOINT + routes.replay_upload(channelId, eventId), {
				method: 'POST',
				headers: { Authorization: token, 'Content-Type': 'application/json' },
				body: JSON.stringify({
					fileName: file,
					mimeType: data.type,
					size: data.size
				})
			})
				.then((r) => r.json())
				.then((result) => {
					return makeRequestWithProgress<MediaFile>('PUT', result, data, onProgressCallback)
				})
		)
	}
	const uploadHighlightVideo = (
		channelId: string,
		data: Record<string, any>,
		file = 'highlight_video.mp4',
		onProgressCallback: Function
	): Promise<MediaFile> => {
		return getUserToken().then((token: string) =>
			fetch(NEXT_PUBLIC_API_ENDPOINT + routes.uploadHighlightVideo(channelId), {
				method: 'POST',
				headers: { Authorization: token, 'Content-Type': 'application/json' },
				body: JSON.stringify({
					fileName: file,
					mimeType: data.type,
					size: data.size
				})
			})
				.then((r) => r.json())
				.then((result) => {
					return makeRequestWithProgress<MediaFile>('PUT', result, data, onProgressCallback)
				})
		)
	}

	const deleteFile = (channelId: string, fileId: string): Promise<void> => {
		return process<void>(routes.deleteFile(channelId, fileId), 'DELETE').catch((_) => {})
	}

	// channels

	const getChannels = () => {
		return process(routes.channels())
	}

	const getChannel = (channelId) => {
		return process(routes.channel(channelId))
	}

	const updateChannel = (channelId, body) => {
		return process(routes.channel(channelId), 'PATCH', body)
	}

	// legal settings

	const updateChannelLegalSettings = (channelId, body) => {
		return process(routes.channelLegalSettings(channelId), 'PATCH', body)
	}

	const updateEventLegalSettings = (channelId, eventId, body) => {
		return process(routes.eventLegalSettings(channelId, eventId), 'PATCH', body)
	}

	// channel users

	const getChannelUsers = (channelId) => {
		return process(routes.channelUsers(channelId))
	}

	const createChannelUser = (channelId, body) => {
		return process(routes.channelUsers(channelId), 'POST', body)
	}

	const updateChannelUser = (channelId, userId, body) => {
		return process(routes.channelUser(channelId, userId), 'PATCH', body)
	}

	const deleteChannelUser = (channelId, userId) => {
		return process(routes.channelUser(channelId, userId), 'DELETE')
	}

	// domains

	const getDomains = (channelId) => {
		return process(routes.domains(channelId))
	}

	const createDomain = (channelId, body) => {
		return process(routes.domains(channelId), 'POST', body)
	}

	const updateDomain = (channelId, domainId, body: Partial<Domain>): Promise<Domain> => {
		return process<Domain>(routes.domain(channelId, domainId), 'PATCH', body)
	}

	const deleteDomain = (channelId, domainId) => {
		return process(routes.domain(channelId, domainId), 'DELETE')
	}

	// events

	const getEvents = (channelId): Promise<Event[]> => {
		return process(routes.events(channelId))
	}

	const getEvent = (channelId, eventId): Promise<Event> => {
		return process(routes.event(channelId, eventId))
	}

	const getPublicEvent = (eventId) => {
		return process(routes.publicEvent(eventId))
	}

	const setEvent = (channelId, eventId, body) => {
		const route = eventId ? routes.event(channelId, eventId) : routes.events(channelId)
		const method = eventId ? 'PUT' : 'POST'
		return process(route, method, body)
	}

	const deleteEvent = (channelId, eventId) => {
		return process(routes.event(channelId, eventId), 'DELETE')
	}

	const duplicateEvent = (channelId, eventId) => {
		return process(routes.duplicateEvent(channelId, eventId), 'POST')
	}

	const getEventLiveStats = (channelId, eventId, body) => {
		return process(routes.eventLiveStats(channelId, eventId), 'POST', body)
	}

	const restartEventRecordConversion = (channelId: string, recordId: string, body: RestartEventRecordRequest) => {
		return process(routes.restartEventRecordConversion(channelId, recordId), 'PATCH', body)
	}

	// program

	const getProgram = (channelId, eventId): Promise<LiveProgram> => {
		return process<LiveProgram>(routes.program(channelId, eventId))
	}

	const setProgram = (channelId, eventId, body) => {
		return process(routes.program(channelId, eventId), 'PUT', body)
	}

	// products
	const getProducts = (channelId) => {
		return process<Paginated<Product>>(routes.products(channelId))
	}

	const getProduct = (channelId, productId) => {
		return process(routes.product(channelId, productId))
	}

	const setProduct = (channelId, productId, body) => {
		const route = productId ? routes.product(channelId, productId) : routes.products(channelId)
		const method = productId ? 'PUT' : 'POST'
		return process(route, method, body)
	}

	const deleteProduct = (channelId, productId) => {
		return process(routes.product(channelId, productId), 'DELETE')
	}

	// brandings

	const getBrandings = (channelId) => {
		return process<Paginated<Branding>>(routes.brandings(channelId))
	}

	const getBranding = (channelId, brandingId) => {
		return process(routes.branding(channelId, brandingId))
	}

	const setBranding = (channelId, brandingId, body) => {
		const route = brandingId ? routes.branding(channelId, brandingId) : routes.brandings(channelId)
		const method = brandingId ? 'PUT' : 'POST'
		return process(route, method, body)
	}

	const deleteBranding = (channelId, brandingId) => {
		return process(routes.branding(channelId, brandingId), 'DELETE')
	}

	// users

	const getUser = (userId) => {
		return process(routes.user(userId))
	}

	const updateUser = (userId, body) => {
		return process(routes.user(userId), 'PATCH', body)
	}

	// interactions

	const getPolls = (channelId, eventId) => {
		return process(routes.polls(channelId, eventId))
	}

	const getPoll = (channelId, eventId, pollId) => {
		return process(routes.poll(channelId, eventId, pollId))
	}

	const createPoll = (channelId, eventId, body) => {
		return process(routes.polls(channelId, eventId), 'POST', body)
	}
	const updatePoll = (channelId, eventId, pollId, body) => {
		return process(routes.poll(channelId, eventId, pollId), 'PATCH', body)
	}

	const startPoll = (channelId, eventId, pollId) => {
		return process(routes.actOnPoll(channelId, eventId, pollId, 'launch'), 'PATCH')
	}

	const stopPoll = (channelId, eventId, pollId) => {
		return process(routes.actOnPoll(channelId, eventId, pollId, 'stop'), 'PATCH')
	}

	const deletePoll = (channelId, eventId, pollId) => {
		return process(routes.poll(channelId, eventId, pollId), 'DELETE')
	}

	const showPollResults = (channelId, eventId, pollId, body) => {
		return process(routes.actOnPoll(channelId, eventId, pollId, 'results'), 'PATCH', body)
	}

	const getWinningInstants = (channelId, eventId) => {
		return process(routes.winningInstants(channelId, eventId))
	}

	const getWinningInstant = (channelId, eventId, wiId) => {
		return process(routes.winningInstant(channelId, eventId, wiId))
	}

	const createWinningInstant = (channelId, eventId, body) => {
		return process(routes.winningInstants(channelId, eventId), 'POST', body)
	}
	const updateWinningInstant = (channelId, eventId, wiId, body) => {
		return process(routes.winningInstant(channelId, eventId, wiId), 'PATCH', body)
	}

	const startWinningInstant = (channelId, eventId, wiId) => {
		return process(routes.actOnWinningInstant(channelId, eventId, wiId, 'launch'), 'PATCH')
	}

	const stopWinningInstant = (channelId, eventId, wiId) => {
		return process(routes.actOnWinningInstant(channelId, eventId, wiId, 'stop'), 'PATCH')
	}

	const deleteWinningInstant = (channelId, eventId, wiId) => {
		return process(routes.winningInstant(channelId, eventId, wiId), 'DELETE')
	}

	const showWinningInstantResults = (channelId, eventId, wiId, body) => {
		return process(routes.actOnWinningInstant(channelId, eventId, wiId, 'showResults'), 'PATCH', body)
	}

	const setUserPicture = (userId, data) => {
		return getUserToken().then((token: string) =>
			fetch(NEXT_PUBLIC_API_ENDPOINT + routes.picture(userId), {
				method: 'POST',
				headers: { Authorization: token, 'Content-Type': 'application/json' },
				body: JSON.stringify({
					fileName: 'profile-picture',
					mimeType: data.type,
					size: data.size
				})
			})
				.then((r) => r.json())
				.then((result) => {
					return fetch(result.presignedUrl, {
						method: 'PUT',
						body: data
					}).then((_) => onResponse(result, authUser), onError)
				})
		)
	}

	const resetPassword = (body) => {
		return processOld(routes.resetPassword(), 'POST', body)
	}

	// live

	const startLive = (channelId, eventId) => {
		return process(routes.startLive(channelId, eventId), 'POST')
	}

	const resetLive = (channelId, eventId) => {
		return process(routes.resetLive(channelId, eventId), 'POST')
	}

	// timecodes

	const createTimecode = (eventId, body) => {
		return processOld(routes.createTimecode(eventId), 'POST', body)
	}

	const getEventAnalytics = (channelId: string, eventId: string, body) => {
		return process(routes.eventsAnalytics(channelId, eventId), 'POST', body)
	}

	// streaming destinations

	const getDestinations = (channelId: string): Promise<Array<StreamingDestination>> => {
		return process<Paginated<StreamingDestination>>(routes.destinations(channelId), 'GET').then((d) => d.data)
	}

	const deleteDestination = (channelId: string, destinationId: string): Promise<void> => {
		return process<void>(routes.destination(channelId, destinationId), 'DELETE')
	}

	// product scraper

	const getPublicProductInformation = (
		channelId: string,
		url: string
	): Promise<ApiRequestResult<PublicProductInformation>> => {
		return processSafe<PublicProductInformation>(routes.productInformation(channelId), 'POST', { url })
	}

	const updateDestination = (
		channelId: string,
		destinationId: string,
		update: StreamingDestinationUpdate
	): Promise<StreamingDestination> => {
		return process<StreamingDestination>(routes.destination(channelId, destinationId), 'PATCH', update)
	}

	const createDestination = (
		channelId: string,
		creation: StreamingDestinationCreation
	): Promise<StreamingDestination> => {
		return process<StreamingDestination>(routes.destinations(channelId), 'POST', creation)
	}

	const getCsats = (channelId: string, eventId: string, startAfter?: Date) => {
		return process(routes.csat(channelId, eventId), 'PATCH', { startAfter })
	}

	const exportEventData = (channelId: string, eventId: string, request: ExportEventDataRequest) => {
		return processSafe<ExportEventDataResult>(routes.exportEventData(channelId, eventId), 'POST', request, 100000)
	}

	const exportHighlightData = (channelId: string, highlightId: string, request: ExportHighlightDataRequest) => {
		return processSafe<{ data: HighlightAnalyticsReport }>(
			routes.exportHighlightData(channelId, highlightId),
			'POST',
			request
		)
	}

	const exportWatchlistData = (channelId: string, watchlistId: string, request: ExportHighlightDataRequest) => {
		return processSafe<{ data: HighlightAnalyticsReport }>(
			routes.exportWatchlistData(channelId, watchlistId),
			'POST',
			request
		)
	}
	const exportAllHighlightsData = (channelId: string, request: ExportHighlightDataRequest) => {
		return processSafe<{ data: HighlightAnalyticsReport }>(
			routes.exportAllHighlightsData(channelId),
			'POST',
			request
		)
	}

	// Highlights
	const getHighlights = (channelId: string): Promise<Array<Highlight>> => {
		return process<Paginated<Highlight>>(routes.highlights(channelId)).then((d) => d.data)
	}

	const getHighlight = (channelId: string, highlightId: string): Promise<Highlight> => {
		return process<Highlight>(routes.highlight(channelId, highlightId))
	}

	const deleteHighlight = (channelId: string, highlightId: string): Promise<{}> => {
		return process<{}>(routes.highlight(channelId, highlightId), 'DELETE')
	}

	const disableHighlight = (channelId: string, highlightId: string, body: any): Promise<{}> => {
		return process<{}>(`${routes.highlight(channelId, highlightId)}/disable`, 'PATCH', body)
	}

	const createHighlight = (channelId: string, body: HighlightCreation): Promise<Highlight> => {
		return process<Highlight>(routes.highlights(channelId), 'POST', body)
	}

	const deleteWatchlist = (channelId: string, highlightsListId: string): Promise<{}> => {
		return process<{}>(routes.watchlist(channelId, highlightsListId), 'DELETE')
	}

	const restartHighlightConversion = (channelId: string, highlightsListId: string) => {
		return process(routes.restartHighlightConversion(channelId, highlightsListId), 'PATCH')
	}

	const getHighlightAnalytics = (
		channelId: string,
		highlightId: string,
		body: HighlighAnalyticsRequest
	): Promise<ApiRequestResult<HighlighAnalyticsResponse>> => {
		return processSafe<HighlighAnalyticsResponse>(routes.highlightAnalytics(channelId, highlightId), 'POST', body)
	}

	const getAllHighlightsAnalytics = (
		channelId: string,
		body: AllHighlightsAnalyticsRequest & { exportTransactions: boolean }
	): Promise<ApiRequestResult<AllHighlightsAnalyticsResponse>> => {
		return processSafe<AllHighlightsAnalyticsResponse>(routes.highlightChannelAnalytics(channelId), 'POST', body)
	}

	const getOverallAnalytics = (
		channelId: string,
		body: AllHighlightsAnalyticsRequest
	): Promise<ApiRequestResult<OverallAnalyticReport>> => {
		return processSafe<OverallAnalyticReport>(routes.overallAnalytics(channelId), 'POST', body)
	}

	const getOverallConsumedMinutes = (channelId: string): Promise<ApiRequestResult<OverallComsumption>> => {
		return processSafe<OverallComsumption>(routes.overallConsumedMinutes(channelId), 'GET')
	}

	const getHighlightsListAnalytics = (
		channelId: string,
		highlightsListId: string,
		body: AllHighlightsAnalyticsRequest
	): Promise<ApiRequestResult<AllHighlightsAnalyticsResponse>> => {
		return processSafe<AllHighlightsAnalyticsResponse>(
			routes.highlightsListAnalytics(channelId, highlightsListId),
			'POST',
			body
		)
	}

	//boosts
	const deleteBoost = (channelId: string, highlightId: string, boostId: string): Promise<ApiRequestResult<void>> =>
		processSafe<void>(routes.boost(channelId, highlightId, boostId), 'DELETE')

	const createBoost = (channelId: string, highlightId: string, body: Boost): Promise<ApiRequestResult<Boost>> => {
		return processSafe<Boost>(routes.boosts(channelId, highlightId), 'POST', body)
	}

	const updateBoost = (
		channelId: string,
		highlightId: string,
		boostId: string,
		body: Boost
	): Promise<ApiRequestResult<Boost>> =>
		processSafe<Boost>(routes.boost(channelId, highlightId, boostId), 'PATCH', body)

	// Translations
	const getChannelTranslations = (channelId: string): Promise<Record<string, any>> => {
		return process<Record<string, any>>(routes.translations(channelId), undefined, undefined, true)
	}

	// Channel Plans
	const createSubscriptionPlan = (
		channelId: string,
		body: SubscriptionPlanCreation
	): Promise<ApiRequestResult<SubscriptionPlan>> => {
		return processSafe<SubscriptionPlan>(routes.subscriptionPlans(channelId), 'POST', body)
	}

	// DEPRECATED
	const searchIAdvizeProducts = (
		channelId: string,
		request: SearchCatalogProductsRequest
	): Promise<ApiRequestResult<SearchCatalogProductsResponse>> =>
		processSafe<SearchCatalogProductsResponse>(routes.iadvizeProductsSearch(channelId), 'POST', request)
	const searchCatalogFeedProducts = (
		channelId: string,
		request: SearchCatalogProductsRequest
	): Promise<ApiRequestResult<SearchCatalogProductsResponse>> =>
		processSafe<SearchCatalogProductsResponse>(routes.catalogFeedSearch(channelId), 'POST', request)

	const createSynchronisedProducts = (
		channelId: string,
		request: SearchCatalogProductsRequest
	): Promise<ApiRequestResult<CreateSynchronisedProductsResponse>> =>
		processSafe<CreateSynchronisedProductsResponse>(routes.createSynchronisedProducts(channelId), 'POST', request)

	return {
		setFile,
		deleteFile,
		getChannels,
		getChannel,
		updateChannel,
		updateChannelLegalSettings,
		updateEventLegalSettings,
		getChannelUsers,
		createChannelUser,
		deleteChannelUser,
		updateChannelUser,
		getEvents,
		getEvent,
		getEventLiveStats,
		getPublicEvent,
		setEvent,
		deleteEvent,
		duplicateEvent,
		getProgram,
		setProgram,
		getProducts,
		getProduct,
		setProduct,
		deleteProduct,
		getBrandings,
		getBranding,
		setBranding,
		deleteBranding,
		startLive,
		resetLive,
		createTimecode,
		getUser,
		updateUser,
		setUserPicture,
		getDomains,
		createDomain,
		updateDomain,
		deleteDomain,
		resetPassword,
		getPolls,
		startPoll,
		stopPoll,
		createPoll,
		deletePoll,
		getPoll,
		updatePoll,
		showPollResults,
		getWinningInstants,
		startWinningInstant,
		stopWinningInstant,
		createWinningInstant,
		deleteWinningInstant,
		getWinningInstant,
		updateWinningInstant,
		showWinningInstantResults,
		getEventAnalytics,
		getDestinations,
		deleteDestination,
		createDestination,
		updateDestination,
		getPublicProductInformation,
		getCsats,
		exportEventData,
		exportHighlightData,
		exportWatchlistData,
		exportAllHighlightsData,
		uploadReplayVideo,
		getHighlights,
		getHighlight,
		createHighlight,
		deleteWatchlist,
		uploadHighlightVideo,
		deleteHighlight,
		disableHighlight,
		restartHighlightConversion,
		getHighlightAnalytics,
		getAllHighlightsAnalytics,
		getHighlightsListAnalytics,
		getChannelTranslations,
		createBoost,
		updateBoost,
		deleteBoost,
		createSubscriptionPlan,
		getOverallAnalytics,
		getOverallConsumedMinutes,
		searchIAdvizeProducts,
		searchCatalogFeedProducts,
		createSynchronisedProducts,
		restartEventRecordConversion
	}
}
