import React from 'react'
import { BaseColoredPaperProps } from '../types'
import BaseColoredPaper from '../BaseColoredPaper'
import { warningPaperClasses } from '../style'

const WarningPaper: React.FC<BaseColoredPaperProps> = ({ icon, title, subTitle }) => {
	return (
		<BaseColoredPaper
			icon={icon}
			title={title}
			subTitle={subTitle}
			classes={warningPaperClasses}
			type={'WARNING_PAPER'}
		/>
	)
}

export default WarningPaper
