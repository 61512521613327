import SelectField from '@components/Micro/Fields/Select'
import { PopinContent, PopinText } from '@components/Pages/Channel/Form/Configuration/DestinationsPopin/style'
import { PopinTitle } from '@components/Templates/Popin'
import { Condition, conditionsList, ConditionType } from '@customTypes/channel'
import Input from '@components/Micro/Fields/Input'
import { SimpleGrid } from '@styles/Grid'
import Theme from '@styles/theme'
import { useEffect, useRef, useState } from 'react'
import Button from '@components/Micro/Button/SimpleCta'
import Validate from '@helpers/validate'
import { ButtonWrapper } from './style'

export type AddDisplayConditionModalProps = {
	onSave: (condition: Condition) => void
	condition?: Condition
	description: string
}

interface ConditionListEntry {
	name: string
	value: ConditionType
}

const conditionsListEntries: ConditionListEntry[] = Object.keys(conditionsList).map((key) => {
	return {
		name: conditionsList[key],
		value: key as ConditionType
	}
})

const defaultConditionValues: Condition = { condition: 'contains', value: '' }

const AddDisplayConditionModal: React.FC<AddDisplayConditionModalProps> = ({ onSave, condition, description }) => {
	const [tempCondition, setTempCondition] = useState<Condition>(defaultConditionValues)
	const valueRef = useRef(null)

	const handleSave = () => {
		if (validate()) {
			onSave(tempCondition)
			setTempCondition(defaultConditionValues)
		}
	}

	const validate = () => {
		if (Object.keys(conditionsList).indexOf(tempCondition?.condition) === -1) {
			return false
		}

		if (!Validate(tempCondition.value, { type: 'string', min: 1 })) {
			valueRef.current.setError('Value can not be blank')
			return false
		}

		return true
	}

	useEffect(() => {
		if (condition) {
			setTempCondition(condition)
		}
	}, [condition])

	const initialValue = condition
		? conditionsListEntries.findIndex((conditionsList) => conditionsList.value === condition?.condition)
		: 0

	return (
		<>
			<PopinContent>
				<>
					<PopinTitle>Add display condition</PopinTitle>
					<PopinText>{description}</PopinText>
				</>
			</PopinContent>
			<SimpleGrid gap={Theme.spacing(10)} width="80%">
				<SimpleGrid gap={Theme.spacing(4)}>
					<div>
						<SelectField
							initialValue={initialValue}
							name="condition"
							label="CONDITION"
							entries={[{ entries: conditionsListEntries }]}
							onChange={(e) =>
								setTempCondition({
									...tempCondition,
									condition: conditionsListEntries[e.target.value].value
								})
							}
						/>
					</div>
					<Input
						label={'VALUE'}
						name="value"
						onChange={(e) => setTempCondition({ ...tempCondition, value: e.target.value })}
						innerRef={valueRef}
						value={tempCondition.value}
						placeholder="Type in a value (ex: www.aploze.com)"
					/>
				</SimpleGrid>
				<ButtonWrapper>
					<Button
						size="medium"
						bgColor={Theme.colors.grey1}
						onClick={handleSave}
						disabled={!tempCondition.value.length}
					>
						Save
					</Button>
				</ButtonWrapper>
			</SimpleGrid>
		</>
	)
}

export default AddDisplayConditionModal
