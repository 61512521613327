import Utils from '@utils/index'

const validateUrl = (url: string): boolean => {
	try {
		new URL(url)
		return true
	} catch {
		return false
	}
}

interface Rules {
	type?: 'url' | 'string' | 'email' | 'number' | 'file' | 'color' | 'date'
	min?: number
	max?: number
	minDate?: Date
	regex?: RegExp
	isOptional?: boolean
}

export default (value: any, rules: Rules): boolean => {
	//

	let isValid = true

	if (value) {
		//

		// type
		if (rules.type === 'url') {
			isValid = rules.isOptional ? !value.length || validateUrl(value) : validateUrl(value)
		} else if (rules.type === 'email') {
			isValid = Utils.is.isEmail(value)
		} else if (rules.type === 'string') {
			isValid = Utils.is.isString(value)
		} else if (rules.type === 'number') {
			isValid = Utils.is.isNumber(value)
		}

		// length
		if (rules.min) {
			if (value.length < rules.min) {
				isValid = false
			}
		}

		if (rules.max) {
			if (value.length > rules.max) {
				isValid = false
			}
		}

		// date
		if (rules.minDate && rules.type === 'date') {
			if (new Date(value).getTime() < rules.minDate.getTime()) {
				isValid = false
			}
		}

		// file
		if (rules.type === 'file') {
			// Must contain the 3 elements
			if (!value || !value.url || !value.uuid || !value.mimeType) {
				isValid = false
			}
		}

		// colors
		if (rules.type === 'color') {
			const regex = new RegExp(`^#(?:[0-9a-fA-F]{3}){1,2}$`)
			isValid = regex.test(value)
		}

		if (rules.regex) {
			const regex = new RegExp(rules.regex)
			isValid = regex.test(value)
		}
	} else {
		if (!rules.isOptional) {
			isValid = false
		}
	}

	return isValid
}
