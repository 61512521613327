import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { css } from '@emotion/react'

const PREFIX = 'tooltip'

export type TooltipDirection = 'top' | 'bottom'

const handleTooltipDirection = ({ direction }: { direction: TooltipDirection }) => {
	switch (direction) {
		case 'bottom':
			return css`
				top: ${Theme.spacing(4)};
				&:before {
					top: -4px;
				}
			`
		case 'top':
			return css`
				bottom: ${Theme.spacing(4)};
				&:before {
					bottom: -4px;
				}
			`
	}
}

export const classes = {
	root: PREFIX
}

export const TooltipWrapper = styled.span`
	cursor: pointer;
	position: relative;
	vertical-align: middle;
	i {
		display: inline-block;
	}
`

export const TooltipBox = styled.div<{ direction: TooltipDirection }>`
	position: absolute;
	background: ${Theme.colors.grey1};
	color: ${Theme.colors.white()};
	z-index: 100;
	overflow: visible;
	border-radius: 8px;
	right: -99px;
	text-align: center;
	padding: 20px;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	width: 200px;
	height: fit-content;
	box-shadow: 8px 8px 30px 0 ${Theme.colors.black(0.8)};

	${handleTooltipDirection}

	a {
		color: ${Theme.colors.white()};
		text-decoration: underline;
		font-weight: bold;
		overflow-wrap: break-word;
	}

	&:before {
		position: absolute;
		width: 8px;
		height: 8px;
		right: 96px;
		content: '';
		background-color: ${Theme.colors.grey1};
		transform: translateX(-50%) rotate(45deg);
	}
`
