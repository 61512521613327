import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()
const NEXT_PUBLIC_IMGIX_DOMAIN = publicRuntimeConfig.NEXT_PUBLIC_IMGIX_DOMAIN
const NEXT_PUBLIC_CDN_DOMAIN = publicRuntimeConfig.NEXT_PUBLIC_CDN_DOMAIN

export const computeSource = (url: string, useCdn = false): string => {
	try {
		new URL(url)
		return url
	} catch {
		if (url) return (useCdn ? NEXT_PUBLIC_CDN_DOMAIN : NEXT_PUBLIC_IMGIX_DOMAIN) + '/' + url
	}
}
