import { css } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'

export const WarningWrapper = styled.div`
	width: 75%;
`

export default {
	icon: css`
		border-radius: 100%;
	`,
	panelText: css`
		text-align: center;
	`,

	title: css`
		color: ${Theme.colors.white()};
		font-size: 20px;
		font-weight: bold;
	`,
	description: css`
		color: ${Theme.colors.grey3};
		width: 75%;
		font-size: 14px;
		line-height: 24px;
		position: relative;
	`
}
