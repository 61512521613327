import React, { Dispatch, SetStateAction, useState } from 'react'
import Link from 'next/link'
import Style from './style'
import { Flex, Box } from 'rebass'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { connect } from 'react-redux'
import Theme from '@styles/theme'
import { css } from '@emotion/react'
import Icon, { IconUnion } from '@components/Micro/Icon'
import OutsideClickHandler from 'react-outside-click-handler'
import { DropdownEntry } from 'types/common'
import Spinner from '../Spinner'
import cx from 'classnames'
import { ctaClasses } from './classes'

export interface CtaProps {
	dispatch?: (action) => void
	href?: any
	params?: any
	label?: any
	className?: any
	mt?: any
	mb?: string
	mr?: string | number
	icon?: IconUnion
	iconLabel?: string
	iconRotate?: number
	style?: any
	onClick?: (event?) => void
	onDirectClick?: any
	size?: 'xsmall' | 'small' | 'medium' | 'large'
	type?: 'plain' | 'border' | 'border-light'
	justifyContent?: any
	iconOnly?: boolean
	color?: any
	hoverColor?: string
	bigIcon?: boolean
	disable?: boolean
	dropdownEntries?: DropdownEntry[]
	textColor?: any
	target?: string
	p?: string | number
	fullWidth?: boolean
	children?: any
	name?: string
}

const Cta: React.FC<CtaProps> = ({
	href,
	label = 'Btn Label',
	className,
	mt,
	mb,
	mr,
	icon,
	iconRotate = 0,
	style,
	onClick,
	onDirectClick,
	size = 'medium',
	type = 'plain',
	justifyContent,
	iconOnly = false,
	color,
	hoverColor,
	bigIcon = false,
	disable = false,
	dropdownEntries = [],
	textColor = null,
	target = '_self',
	p = null,
	fullWidth,
	children,
	name
}) => {
	const [dropdownToggled, setDropdownToggled]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false)
	let $icon = null

	const iconSizes = {
		small: {
			font: 13,
			box: 30
		},
		big: {
			font: 20,
			box: 50
		}
	}

	const iSize = bigIcon ? 'big' : 'small'

	if (icon) {
		$icon = (
			<Box
				width={iconSizes[iSize].font}
				height={iconSizes[iSize].font}
				css={[iconOnly ? Style.iconOnly : Style.icon]}
				className={'icon'}
			>
				<Icon name={icon} width="100%" r={iconRotate} height="100%" fill={textColor || Theme.colors.white()} />
			</Box>
		)

		if (iconOnly) {
			label = null
		}
	}

	const onDropdownClick = () => {
		setTimeout(() => {
			setDropdownToggled(!dropdownToggled)
		}, 5)
	}

	let $cta = (
		<Flex
			as="a"
			onClick={(event) => {
				event.stopPropagation()
				if (dropdownEntries.length) {
					return onDropdownClick()
				}
				if (onClick) {
					onClick(event)
				}
			}}
			p={p}
			css={[
				style,
				Style.cta,
				href && disable ? Style.isDisabled : null,
				icon ? Style.hasIcon : null,
				type === 'border' || type === 'border-light'
					? css`
							border: 1px solid
								${dropdownToggled
									? Theme.colors.white()
									: type === 'border-light'
									? Theme.colors.grey2
									: color};
							color: ${textColor || Theme.colors.white()};
					  `
					: null,
				iconOnly
					? css`
							padding: 0 !important;
							width: 100% !important;
					  `
					: null,
				fullWidth &&
					css`
						width: 100% !important;
					`,
				hoverColor &&
					css`
						&:hover {
							background-color: ${hoverColor};
						}
					`,
				textColor &&
					css`
						color: ${textColor || Theme.colors.white()};
					`
			]}
			className={cx(size + ' ' + type, ctaClasses.cta)}
			backgroundColor={type === 'plain' ? color : 'none'}
			flexWrap="wrap"
			alignContent="center"
			justifyContent="center"
			target={target}
		>
			{children}
			{$icon}
			<Box ml={$icon ? '5px' : 0}>{label}</Box>
			{dropdownToggled && dropdownEntries.length && (
				<OutsideClickHandler onOutsideClick={onDropdownClick}>
					{/* TODO: Clean all this nonsense */}
					<Box css={[Style.dropdown, bigIcon || Style.mediumPosition]} height={'fit-content'}>
						{dropdownEntries.map((entry, i) => {
							return (
								<Flex
									css={Style.dropdownItem}
									onClick={entry.onClickHandler}
									alignItems={'center'}
									key={`cta-dropdown-entry-${i}`}
								>
									{entry.iconName === 'spinner' ? (
										<Spinner width={15} height={15} small={true} />
									) : (
										<Icon
											name={entry.iconName}
											height={15}
											width={15}
											fill={Theme.colors.white()}
										/>
									)}
									<Box ml={entry.iconName === 'spinner' ? '22px' : 2}>{entry.name}</Box>
								</Flex>
							)
						})}
					</Box>
				</OutsideClickHandler>
			)}
		</Flex>
	)

	if (href) {
		$cta = (
			<Link href={href} passHref>
				{$cta}
			</Link>
		)
	}

	return (
		<Flex
			className={cx(className, ctaClasses.root)}
			mt={mt}
			mb={mb}
			onClick={onDirectClick}
			width={iconOnly ? iconSizes[iSize].box : null}
			height={iconOnly ? iconSizes[iSize].box : null}
			flexWrap={'wrap'}
			justifyContent={justifyContent}
			css={disable ? Style.isDisabled : null}
			mr={mr}
			name={name}
		>
			{$cta}
		</Flex>
	)
}

export default connect(null, mapDispatchToProps)(Cta)
