import { ApiResponseError } from './../types/errors'
import { captureException, setUser } from '@sentry/nextjs'

export const onResponse = <T>(response, user, bypassErrors?): Promise<T> => {
	if (response?.errors && !bypassErrors) {
		if (user) {
			setUser(user._delegate)
		}
		if (typeof response.errors === 'string') {
			captureException(new ApiResponseError(response.errors))
		}
		if (Array.isArray(response.errors)) {
			response.errors.forEach((errorData) => {
				// Adds user context to error response from api
				if (user) {
					setUser(user._delegate)
				}
				captureException(new ApiResponseError(errorData.error))
			})
		}
	}
	return Promise.resolve(response)
}
export const onError = <T>(error): Promise<T> => {
	captureException(error)
	return Promise.reject(error)
}
