class Easing {
	static backInOut(t) {
		const s = 1.70158 * 1.525
		let _t = t
		if ((_t *= 2) < 1) {
			return 0.5 * (_t * _t * ((s + 1) * _t - s))
		}
		return 0.5 * ((_t -= 2) * _t * ((s + 1) * _t + s) + 2)
	}

	static backIn(t) {
		const s = 1.70158
		return t * t * ((s + 1) * t - s)
	}

	static backOut(t) {
		const s = 1.70158
		let _t = t
		return --_t * _t * ((s + 1) * _t + s) + 1
	}

	static bounceInOut(t) {
		return t < 0.5 ? 0.5 * (1.0 - this.bounceOut(1.0 - t * 2.0)) : 0.5 * this.bounceOut(t * 2.0 - 1.0) + 0.5
	}

	static bounceIn(t) {
		return 1.0 - this.bounceOut(1.0 - t)
	}

	static bounceOut(t) {
		const a = 4.0 / 11.0
		const b = 8.0 / 11.0
		const c = 9.0 / 10.0

		const ca = 4356.0 / 361.0
		const cb = 35442.0 / 1805.0
		const cc = 16061.0 / 1805.0

		const t2 = t * t

		return t < a
			? 7.5625 * t2
			: t < b
			? 9.075 * t2 - 9.9 * t + 3.4
			: t < c
			? ca * t2 - cb * t + cc
			: 10.8 * t * t - 20.52 * t + 10.72
	}

	static circInOut(t) {
		let _t = t
		if ((_t *= 2) < 1) return -0.5 * (Math.sqrt(1 - _t * _t) - 1)
		return 0.5 * (Math.sqrt(1 - (_t -= 2) * _t) + 1)
	}

	static circIn(t) {
		return 1.0 - Math.sqrt(1.0 - t * t)
	}

	static circOut(t) {
		let _t = t
		return Math.sqrt(1 - --_t * _t)
	}

	static cubicInOut(t) {
		return t < 0.5 ? 4.0 * t * t * t : 0.5 * Math.pow(2.0 * t - 2.0, 3.0) + 1.0
	}

	static cubicIn(t) {
		return t * t * t
	}

	static cubicOut(t) {
		const f = t - 1.0
		return f * f * f + 1.0
	}

	static elasticInOut(t) {
		return t < 0.5
			? 0.5 * Math.sin(((+13.0 * Math.PI) / 2) * 2.0 * t) * Math.pow(2.0, 10.0 * (2.0 * t - 1.0))
			: 0.5 * Math.sin(((-13.0 * Math.PI) / 2) * (2.0 * t - 1.0 + 1.0)) * Math.pow(2.0, -10.0 * (2.0 * t - 1.0)) +
					1.0
	}

	static elasticIn(t) {
		return Math.sin((13.0 * t * Math.PI) / 2) * Math.pow(2.0, 10.0 * (t - 1.0))
	}

	static elasticOut(t) {
		return Math.sin((-13.0 * (t + 1.0) * Math.PI) / 2) * Math.pow(2.0, -10.0 * t) + 1.0
	}

	static expoInOut(t) {
		return t === 0.0 || t === 1.0
			? t
			: t < 0.5
			? +0.5 * Math.pow(2.0, 20.0 * t - 10.0)
			: -0.5 * Math.pow(2.0, 10.0 - t * 20.0) + 1.0
	}

	static expoIn(t) {
		return t === 0.0 ? t : Math.pow(2.0, 10.0 * (t - 1.0))
	}

	static expoOut(t) {
		return t === 1.0 ? t : 1.0 - Math.pow(2.0, -10.0 * t)
	}

	static linear(t) {
		return t
	}

	static quadInOut(t) {
		let _t = t
		_t /= 0.5
		if (_t < 1) return 0.5 * _t * _t
		_t--
		return -0.5 * (_t * (_t - 2) - 1)
	}

	static quadIn(t) {
		return t * t
	}

	static quadOut(t) {
		return -t * (t - 2.0)
	}

	static quartInOut(t) {
		return t < 0.5 ? +8.0 * Math.pow(t, 4.0) : -8.0 * Math.pow(t - 1.0, 4.0) + 1.0
	}

	static quartIn(t) {
		return Math.pow(t, 4.0)
	}

	static quartOut(t) {
		return Math.pow(t - 1.0, 3.0) * (1.0 - t) + 1.0
	}

	static quintInOut(t) {
		let _t = t
		if ((_t *= 2) < 1) return 0.5 * _t * _t * _t * _t * _t
		return 0.5 * ((_t -= 2) * _t * _t * _t * _t + 2)
	}

	static quintIn(t) {
		return t * t * t * t * t
	}

	static quintOut(t) {
		let _t = t
		return --_t * _t * _t * _t * _t + 1
	}

	static sineInOut(t) {
		return -0.5 * (Math.cos(Math.PI * t) - 1)
	}

	static sineIn(t) {
		const v = Math.cos(t * Math.PI * 0.5)
		if (Math.abs(v) < 1e-14) return 1
		else return 1 - v
	}

	static sineOut(t) {
		return Math.sin((t * Math.PI) / 2)
	}
}

export default Easing
