import { Tab } from '@components/TabView/types'
import { TimeFormater } from '@helpers/TimeFormater'
import { SelectEntry } from './../components/Micro/Fields/Select/index'
import { Branding, brandingDefaultValues } from './branding'
import { Link } from './common'
import { LegalSettings } from './legal_settings'
import { Product } from './product'

export const eventDefaultValues: Event = {
	content: {
		concept: {
			description: '',
			title: ''
		},
		footer: { links: [] },
		popinLegal: null,
		preliveMedias: { poster: null, media: null },
		program: { text: '', title: '' },
		shopper: {
			description: '',
			name: '',
			picture: null,
			socialNetworks: []
		},
		picture: null,
		socialNetworks: []
	},
	settings: {
		brandingId: null,
		branding: brandingDefaultValues,
		registrationTypes: ['sms', 'calendar'],
		videoMode: 'portrait',
		options: {
			hasCart: false,
			hasCommercialOptin: true,
			hasPrelivePrices: true,
			hasPreliveProducts: true,
			hasConversationalReplay: false,
			hasCsat: true,
			hasFloatingPlayer: false,
			hasReplayChat: true
		},
		domains: [],
		productCta: 'see_product'
	},
	title: '',
	time: TimeFormater.getDefaultDate(),
	localDateTime: {
		dateAndTime: TimeFormater.getDefaultDate(),
		timezone: 'UTC',
		offset: 0,
		name: 'Etc/GMT'
	},
	status: 'prelive',
	isTest: false,
	legalSettings: null
}

export const eventVideoModeEntries: SelectEntry<EventVideoMode>[] = [
	{
		name: 'Portrait',
		value: 'portrait',
		icon: 'portrait',
		bigIcon: true
	},
	{
		name: 'Landscape',
		value: 'landscape',
		icon: 'landscape',
		bigIcon: true
	}
]

export const eventProductCtaEntries: SelectEntry<EventProductCta>[] = [
	{ name: 'See product', value: 'see_product' },
	{ name: 'Make an appointment', value: 'appointment' },
	{ name: 'Book', value: 'book' }
]

export interface Event {
	content: EventContent
	settings: EventSettings
	title: string
	time: string
	status: EventStatus
	uuid?: string
	isTest: boolean
	legalSettings: LegalSettings
	interactions?: EventInteraction[]
	localDateTime: {
		dateAndTime: string
		timezone: string
		offset: number
		name: string
	}
}

export interface EventContent {
	concept: {
		description: string
		title: string
	}
	footer: {
		links: any[]
	}
	popinLegal: any
	preliveMedias: {
		poster: any
		media: any
	}
	program: {
		text: string
		title: string
	}
	shopper: {
		description: string
		name: string
		picture: any
		socialNetworks: Link[]
	}
	picture: any
	socialNetworks: Link[]
}

export interface EventSettings {
	brandingId: string
	branding: Branding
	videoMode: EventVideoMode
	options: EventOptions
	registrationTypes: string[]
	domains: EventSettingsDomain[]
	productCta: EventProductCta
}

export type EventProductCta = 'see_product' | 'appointment' | 'book'

export type EventVideoMode = 'portrait' | 'landscape'

export interface EventOptions {
	hasCart: boolean
	hasCommercialOptin: boolean
	hasPrelivePrices: boolean
	hasPreliveProducts: boolean
	hasConversationalReplay: boolean
	hasCsat: boolean
	hasFloatingPlayer: boolean
	hasReplayChat: boolean
}

export interface EventSettingsDomain {
	uuid: string
	cartUrl?: string
	hasPip: boolean
	host: string
	isTest: boolean
	name: string
	channelId?: string
}

export type EventStatus = 'prelive' | 'live' | 'finishing' | 'replay'

const constEventTabs = [
	{
		label: 'Program',
		path: 'program',
		liveIndex: 1
	},
	{
		label: 'Content',
		path: 'content',
		liveIndex: 2,
		replayIndex: 2
	},
	{
		label: 'Interactions',
		path: 'interactions',
		liveIndex: 3,
		replayIndex: 6
	},
	{
		label: 'Moderation',
		path: 'moderation',
		liveIndex: 4
	},
	{
		label: 'Settings',
		path: 'settings',
		liveIndex: 5,
		replayIndex: 4
	},
	{
		label: 'Analytics',
		path: 'analytics',
		liveIndex: 6,
		replayIndex: 1
	},
	{
		label: 'Chat',
		path: 'chat',
		replayIndex: 3
	},
	{
		label: 'Products',
		path: 'products',
		replayIndex: 5
	},
	{
		label: 'Highlight',
		path: 'highlights'
	},
	{
		label: 'Boosts',
		path: 'boosts'
	}
] as const

export const eventTabs: Tab[] = [...constEventTabs]

export type EventTag = (typeof constEventTabs)[number]['path']

export type InteractionType = 'poll' | 'winning_instant'

export type EventInteraction = {
	index: number
	type: InteractionType
	uuid: string
}

export type ConversionStatus = 'PENDING' | 'PROCESSING' | 'COMPLETED' | 'FAILED'

export type ProductSequence = {
	url: string
	products: Array<{
		uuid: string
	}>
}

export type EventRecord = {
	uuid: string
	event: string
	replay_url: string
	replay_duration: number
	stream_start_time: string
	stream_end_time: string
	live_start_time: string
	live_end_time: string
	conversion_status: ConversionStatus
	new_replay_url: string
	product_sequences_conversion_status: ConversionStatus
	product_sequences: ProductSequence
	download_url: string
	thumbnail_url: string
	gif_url: string
}

export type EventRecordConversionType = 'conversion_status' | 'product_sequences_conversion_status'

export type RestartEventRecordRequest = {
	type: EventRecordConversionType
}

export type EventProgram = {
	product: Product
	stepIndex: number
}
