import { HighlightsList, HighlightsListModification } from '@customTypes/highlight'
import { routes } from '@helpers/hooks/useAplozeApi'
import { useAxiosCall } from '@helpers/hooks/useAxios'
import { useRouter } from 'next/router'
import { MutableRefObject, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import isEqual from 'lodash.isequal'
import deepClone from 'lodash.clonedeep'
import cloneDeep from 'lodash.clonedeep'
import { FormStatus, SaveStatus } from '@customTypes/common'
import UnsavedChangesHandler from '@helpers/UnsavedChangesHandler'
import { ImperativeHandleType } from '@components/Micro/Fields/Input'
import validate from '@helpers/validate'
import { ErrorSetter } from '@helpers/ErrorSetter'
import { useGlobalContext } from './global'
import { DisplayCondition } from '@customTypes/channel'

export type HighlightsListFormDatas = HighlightsList & { brandingId: string }

interface HighlighstListContext {
	highlightsList?: HighlightsList
	highlightsListLoading?: boolean
	setFormDatas?: (highlightsList: HighlightsListFormDatas) => void
	formDatas?: HighlightsListFormDatas
	saveStatus?: SaveStatus
	formStatus?: FormStatus
	setFormStatus?: (status: FormStatus) => void
	saveHighlightsList?: (update?: HighlightsListFormDatas) => void
	settingsNameRef?: MutableRefObject<ImperativeHandleType>
	settingStoryTitleRef?: MutableRefObject<ImperativeHandleType>
	setDisplayedCount?: (count: number) => void
	displayedCount?: number
	coverUrl?: string
	refreshDefaultHighlightsList?: () => void
	storySettings?: {
		displayConditions: Array<DisplayCondition>
	}
}

const Context = createContext<HighlighstListContext>({})

export const useHighlighstListContext = () => useContext(Context)

export const HighlighstListProvider = ({ children }) => {
	const channelId = useRouter().query.channelId as string
	const watchlistId = useRouter().query.watchlistId as string
	const [defaultHighlightsList, setDefaultHighlightsList] = useState<HighlightsList>(null)
	const [highlightsList, setHighlightsList] = useState<HighlightsList>(null)
	const [formDatas, setFormDatas] = useState<HighlightsListFormDatas>(null)
	const [savedDatas, setSavedDatas] = useState<HighlightsListFormDatas>(null)
	const [saveStatus, setSaveStatus] = useState<SaveStatus>('noChange')
	const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.INITIAL)
	const [displayedCount, setDisplayedCount] = useState<number>(null)
	const settingsNameRef = useRef<ImperativeHandleType>(null)
	const settingStoryTitleRef = useRef<ImperativeHandleType>(null)
	const { openSuccessNotification } = useGlobalContext()

	const [{ data: defaultHighlightsListData, loading: defaultHighlightsListLoading }, getDefaultHighlightsList] =
		useAxiosCall({
			method: 'GET'
		})
	const [{ data: highlightsListData, loading: highlightsListLoading }, getHighlightsList] = useAxiosCall({
		method: 'GET'
	})

	const [{ data: updatedHighlightsList, loading: updatedHighlightsListLoading }, updateHighlightsList] = useAxiosCall(
		{
			method: 'PATCH'
		}
	)

	const fetchDefaultHighlightsList = () => getDefaultHighlightsList({ url: routes.defaultWatchlist(channelId) })

	const saveHighlightsList = () => {
		if (!watchlistId || validateForm()) {
			setSaveStatus('saving')
			const data = mapFormDatasToUpdateData(formDatas)
			const hlsList = highlightsList || defaultHighlightsList
			updateHighlightsList({ url: routes.watchlist(channelId, hlsList.uuid) }, { data })
			if (formStatus === FormStatus.SAVE_UNSAVED_CHANGES) {
				setFormStatus(FormStatus.REDIRECT)
			}
		}
	}

	const validateForm = () => {
		if (
			settingsNameRef?.current &&
			!validate(settingsNameRef.current.getValue(), { type: 'string', min: 1, max: 256 })
		) {
			settingsNameRef.current.setError(
				`Name is ${ErrorSetter.errorMessageByLength(settingsNameRef.current.getValue(), 256)}`
			)
			return false
		}
		if (
			settingStoryTitleRef?.current &&
			!validate(settingStoryTitleRef.current.getValue(), { type: 'string', min: 1, max: 256 })
		) {
			settingStoryTitleRef.current.setError(
				`Story Title is ${ErrorSetter.errorMessageByLength(settingStoryTitleRef.current.getValue(), 256)}`
			)
			return false
		}
		return true
	}

	// This can be used for either the default list or the highlight list selected
	const syncForm = (highlightsList) => {
		setFormDatas(cloneDeep({ ...formDatas, ...highlightsList, brandingId: highlightsList.branding.uuid }))
		setSavedDatas(cloneDeep({ ...savedDatas, ...highlightsList, brandingId: highlightsList.branding.uuid }))
	}

	const mapFormDatasToUpdateData = (update: HighlightsListFormDatas): HighlightsListModification => {
		if (update) {
			return deepClone({
				...update,
				domainIds: update.domains.map((d) => d.uuid),
				brandingId: update.brandingId || update.branding.uuid,
				highlights: update.highlights.map((hl, index) => ({ uuid: hl.uuid, index }))
			})
		}
	}

	useEffect(() => {
		if (channelId) {
			// ⚠️ (watchlistId === highlightsListId)
			if (watchlistId) {
				// If we're on the page with a watchlist id
				// We fetch the list by id
				getHighlightsList({ url: routes.watchlist(channelId, watchlistId) })
			} else {
				// Else we get the default list (with all highlights)
				fetchDefaultHighlightsList()
			}
		}
	}, [channelId, watchlistId])

	useEffect(() => {
		if (updatedHighlightsList && !updatedHighlightsListLoading) {
			setSaveStatus('noChange')
			setFormStatus(FormStatus.INITIAL)
			openSuccessNotification('Your changes have been saved')
			if (!watchlistId || highlightsList.default) {
				fetchDefaultHighlightsList()
			} else {
				syncForm(formDatas)
			}
		}
	}, [updatedHighlightsList, updatedHighlightsListLoading])

	useEffect(() => {
		if (defaultHighlightsListData) {
			setDefaultHighlightsList(defaultHighlightsListData.data)
			syncForm(defaultHighlightsListData.data)
		}
	}, [defaultHighlightsListData])

	useEffect(() => {
		if (highlightsListData) {
			setHighlightsList(highlightsListData.data)
			syncForm(highlightsListData.data)
		}
	}, [highlightsListData])

	useEffect(() => {
		if (!isEqual(formDatas, savedDatas)) setSaveStatus('hasChange')
		else setSaveStatus('noChange')
	}, [formDatas, savedDatas])

	const values = useMemo(
		(): HighlighstListContext => ({
			highlightsList: watchlistId ? highlightsList : defaultHighlightsList,
			highlightsListLoading: watchlistId ? highlightsListLoading : defaultHighlightsListLoading,
			setFormDatas,
			formDatas,
			saveStatus,
			formStatus,
			setFormStatus,
			saveHighlightsList,
			settingsNameRef,
			settingStoryTitleRef,
			displayedCount,
			setDisplayedCount,
			refreshDefaultHighlightsList: fetchDefaultHighlightsList
		}),
		[
			highlightsList,
			highlightsListLoading,
			defaultHighlightsListLoading,
			defaultHighlightsListData,
			updatedHighlightsList,
			saveStatus,
			formStatus,
			formDatas,
			settingsNameRef,
			settingStoryTitleRef,
			displayedCount
		]
	)

	return (
		<Context.Provider value={values}>
			{children}
			<UnsavedChangesHandler
				formStatus={formStatus}
				setStatusChange={setFormStatus}
				action={saveHighlightsList}
				loading={updatedHighlightsList}
				formData={formDatas}
				initData={savedDatas}
			/>
		</Context.Provider>
	)
}
