import { createContext, useContext } from 'react'
import useFirebaseAuth from '@helpers/hooks/useFirebaseAuth'

const authUserContext = createContext({
	authUser: null,
	userToken: null,
	isLoading: true,
	accesses: [],
	signInWithEmailAndPassword: async (email, password) => {},
	signInAnonymously: async () => {},
	createUserWithEmailAndPassword: async () => {},
	signOut: async () => {},
	setAuthUser: async (user) => {},
	getUserToken: async () => Promise.resolve('')
})

export function AuthUserProvider({ children }) {
	const auth = useFirebaseAuth()
	return <authUserContext.Provider value={auth}>{children}</authUserContext.Provider>
}

export const useAuth = () => useContext(authUserContext)
