import Cta from '@components/Micro/Button'
import SaveCta from '@components/Micro/Button/SaveCta'
import Icon from '@components/Micro/Icon'
import WarningPaper from '@components/Micro/Paper/Warning'
import Popin from '@components/Templates/Popin'
import Theme from '@styles/theme'
import React from 'react'
import { Box, Flex } from 'rebass'
import { confirmPopinClasses } from './classes'
import Style, { WarningWrapper } from './style'
import { BaseColoredPaperProps } from '@components/Micro/Paper/types'

interface CtaElProps {
	label?: string
	color?: string
}

interface ActionsProps {
	confirm?: CtaElProps
	cancel?: CtaElProps
}

export interface ConfirmPopinProps {
	isOpened?: boolean
	ctaProps?: ActionsProps
	icon?: any
	iconBackgroundColor?: string
	iconColor?: string
	title?: string
	description?: string
	onCancel?: () => void
	onClose?: () => void
	onConfirm?: () => void
	warningProps?: BaseColoredPaperProps
	children?: any
}

const ConfirmPopin: React.FC<ConfirmPopinProps> = ({
	isOpened,
	ctaProps,
	icon,
	iconBackgroundColor,
	iconColor,
	title,
	description,
	onCancel,
	onClose,
	onConfirm,
	warningProps,
	children
}) => {
	/**
	 * @Methods
	 */
	const handleClose = () => {
		if (onClose) {
			onClose()
		}
		if (onCancel) {
			onCancel()
		}
	}

	return (
		<Popin isOpened={isOpened} onClose={handleClose} hasCta={true}>
			<>
				{icon && (
					<Box
						width={50}
						height={50}
						mb={25}
						p={'15px'}
						backgroundColor={iconBackgroundColor || Theme.colors.red()}
						css={Style.icon}
					>
						<Icon name={icon} width="100%" height="100%" fill={iconColor || Theme.colors.white()} />
					</Box>
				)}
				<Flex flexWrap={'wrap'} justifyContent="center" css={[Style.panelText, Style.title]} mb={2}>
					{title || 'Error'}
				</Flex>
				<Flex flexWrap={'wrap'} justifyContent="center" css={[Style.panelText, Style.description]} mb={20}>
					{description || 'An error has occured ! '}
				</Flex>
				{children && (
					<Flex
						flexWrap={'wrap'}
						justifyContent="center"
						css={[Style.panelText, Style.description]}
						mt={25}
						mb={20}
					>
						{children}
					</Flex>
				)}
				{warningProps && (
					<WarningWrapper>
						<WarningPaper {...warningProps} />
					</WarningWrapper>
				)}
				<Flex flexWrap={'wrap'} justifyContent="center" mt={30}>
					{onCancel && onClose && (
						<Box>
							<Cta
								className={confirmPopinClasses.cancel}
								justifyContent={'flex-start'}
								color={ctaProps?.cancel.color || Theme.colors.grey1}
								label={ctaProps?.cancel.label || 'Cancel'}
								onClick={handleClose}
							/>
						</Box>
					)}
					{onConfirm && (
						<Box ml={10}>
							{ctaProps.confirm.label === 'Save' ? (
								<SaveCta status="hasChange" onClick={onConfirm} />
							) : (
								<Cta
									className={confirmPopinClasses.valid}
									justifyContent={'flex-start'}
									color={ctaProps?.confirm.color || Theme.colors.red()}
									label={ctaProps?.confirm.label || 'Yes'}
									onClick={onConfirm}
								/>
							)}
						</Box>
					)}
				</Flex>
			</>
		</Popin>
	)
}

export default ConfirmPopin
