import React, { useState, useEffect, useCallback } from 'react'
import { AddHighlightToListPopinContent } from './style'
import Theme from '@styles/theme'
import Cta from '@components/Micro/Button'
import router from 'next/router'
import { useModalsContext } from '@helpers/contexts/modals/ModalsContext'
import { useAxiosCall } from '@helpers/hooks/useAxios'
import { routes } from '@helpers/hooks/useAplozeApi'
import { Highlight } from '@customTypes/highlight'
import CardPicker from '@components/Templates/CardPicker'
import { CardPickerItemData } from '@components/Templates/CardPicker/CardPickerItem'
import { HighlightsListFormDatas, useHighlighstListContext } from '@helpers/contexts/highlightsListContext'
import { highlightCardPickerClasses } from './classes'

interface HighlightsDatePickerProps {
	perPage?: number
	description?: string
	title?: string
	onClose?: () => void
	onSubmit: (highlights: Highlight[]) => void
	watchlist?: HighlightsListFormDatas
}

type ChildCtaProps = Partial<any> & {
	onClick: () => void
}

interface AddWatchlistPopinCtaProps {
	onClick?: () => void
	onSubmit?: (highlightsList: HighlightsListFormDatas) => void
	onConfirmClose?: () => void
	Component?: React.FC<ChildCtaProps>
}

export const AddHighlightsToWatchlistCta: React.FC<AddWatchlistPopinCtaProps> = ({ Component, onClick }) => {
	const { openModal, closeModal } = useModalsContext()
	const { formDatas: watchlist, setFormDatas: setWatchlist } = useHighlighstListContext()

	const handleSubmit = (hlsToAdd: Highlight[]) => {
		setWatchlist({ ...watchlist, highlights: [...hlsToAdd, ...watchlist.highlights] })
		closeModal()
	}

	const handleClick = () => {
		if (onClick) onClick()
		openModal('ADD_HIGHLIGHTS_TO_WATCHLIST', {
			onSubmit: handleSubmit,
			watchlist
		})
	}

	return (
		<>
			{Component ? (
				<Component className={highlightCardPickerClasses.button} onClick={handleClick} />
			) : (
				<Cta
					className={highlightCardPickerClasses.button}
					onClick={handleClick}
					label={'Add'}
					color={Theme.colors.grey1}
				/>
			)}
		</>
	)
}

const HighlightsDatePicker: React.FC<HighlightsDatePickerProps> = ({ watchlist, onSubmit }) => {
	/**
	 * @States
	 */
	const channelId = router.query.channelId as string
	const [selectedHighlights, setSelectedHighlights] = useState<Array<string>>([])
	const [{ data: highlights, loading: getHighlightsLoading }, getHighlights] = useAxiosCall({
		method: 'GET'
	})

	const getImage = (highlight: Highlight) => {
		const firstProduct = highlight?.steps[0]?.products[0]
		if (highlight.thumbnailUrl) return { url: highlight.thumbnailUrl }

		if (firstProduct?.images?.length) {
			return firstProduct.images[0]
		}
		return { url: 'medias/default/avatar-100x100.jpg' }
	}

	const highligtsToCardPickerItemsData = useCallback((): CardPickerItemData[] => {
		const items: CardPickerItemData[] = []

		if (highlights?.count > 0 && watchlist) {
			const wlHighlights = watchlist.highlights.map((hl) => hl.uuid)

			highlights.data.forEach((hl: Highlight) => {
				if (wlHighlights.indexOf(hl.uuid) === -1) {
					items.push({
						value: hl.uuid,
						image: getImage(hl),
						title: hl.name
					})
				}
			})
		}
		return items
	}, [highlights, watchlist])

	const getSelectedHighlights = (): Highlight[] => {
		const result: Highlight[] = []
		if (highlights) {
			highlights.data.forEach((hl: Highlight) => {
				if (selectedHighlights.indexOf(hl.uuid) >= 0) {
					result.push(hl)
				}
			})
		}

		return result
	}

	const handleSubmit = () => {
		onSubmit(getSelectedHighlights())
	}

	useEffect(() => {
		if (channelId) {
			getHighlights({ url: routes.highlights(channelId) })
		}
	}, [channelId])

	return (
		<AddHighlightToListPopinContent className={highlightCardPickerClasses.popin}>
			<CardPicker
				className={highlightCardPickerClasses.root}
				items={highligtsToCardPickerItemsData()}
				value={selectedHighlights}
				onChange={(selected) => setSelectedHighlights(selected)}
				title="Add highlight"
				description="Select the highlight to add on the watchlist"
				loading={getHighlightsLoading}
				emptyStateProps={{
					title: 'No highlight',
					description: 'There is no highlight matching your request.',
					iconName: 'play'
				}}
			/>
			<Cta
				className={highlightCardPickerClasses.submit}
				color={Theme.colors.grey1}
				label={'Add'}
				onClick={handleSubmit}
			/>
		</AddHighlightToListPopinContent>
	)
}

export default HighlightsDatePicker
