import React, { useState, useImperativeHandle, useRef, Dispatch, SetStateAction, useEffect } from 'react'
import { Flex, Box } from 'rebass'
import Style, { EndAdornment, InputWrapper, SpinnerWrapper } from './style'
import Theme from '@styles/theme'
import Icon from '@components/Micro/Icon'
import Switch from '@components/Micro/Switch'
import { InputLabel as Label } from './Label'
import Spinner from '@components/Micro/Spinner'
import Tooltip from '@components/Micro/Tooltip'

export interface ImperativeHandleType {
	setError: (errorMessage?: string, e?: boolean) => void
	setSuccess: (successMessage: string) => void
	getValue: () => any
	clear: () => void
}
export interface InputProps {
	label?: string
	name?: string
	type?: string
	value?: string | number
	onChange?: Function
	onKeyUp?: Function
	onBlur?: Function
	onEnter?: Function
	onFocus?: Function
	inputMode?: any
	isDisabled?: boolean
	isHidden?: boolean
	optional?: boolean
	as?: string
	rows?: string | number
	cols?: string | number
	mb?: string | number
	placeholder?: string
	style?: any
	innerRef?: React.MutableRefObject<ImperativeHandleType>
	suffix?: any
	endAdornment?: any
	id?: string
	prefixIconName?: string
	shortcuts?: any
	isDark?: boolean
	smallIcon?: boolean
	isToggled?: boolean
	setIsToggled?: (action) => void
	optionalText?: string
	maxLength?: number
	error?: boolean
	padding?: string
	onDebounce?: (e: any) => Promise<unknown>
	debounceTime?: number
	step?: string
	controlled?: boolean
	tooltipText?: string
	onlyToggle?: boolean
	className?: string
	min?: number
	max?: number
}

const Input: React.FC<InputProps> = ({
	label,
	name,
	type = 'text',
	value,
	onChange,
	onKeyUp,
	onEnter,
	onBlur,
	onFocus,
	inputMode,
	isDisabled = false,
	isHidden,
	optional = false,
	mb = 0,
	as = 'input',
	rows,
	cols,
	placeholder,
	style,
	innerRef,
	suffix,
	endAdornment,
	id,
	prefixIconName,
	shortcuts,
	isDark = false,
	smallIcon = false,
	isToggled,
	setIsToggled,
	optionalText,
	maxLength = Infinity,
	error,
	padding,
	onDebounce,
	debounceTime = 0,
	step,
	controlled,
	tooltipText,
	onlyToggle = false,
	className,
	min = 0,
	max = 100
}) => {
	const [hasError, setHasError]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false)
	const [success, setSuccess]: [boolean, Dispatch<SetStateAction<boolean>>] = useState(false)
	const [errorMessage, setErrorMessage]: [string, Dispatch<SetStateAction<string>>] = useState(null)
	const inputRef: React.MutableRefObject<any> = useRef(null)
	const inputFileRef: React.MutableRefObject<any> = useRef(null)
	const [debouncedValue, setDebouncedValue] = useState(null)

	useEffect(() => {
		setHasError(error)
	}, [error])

	useImperativeHandle(
		innerRef,
		(): ImperativeHandleType => ({
			setError(errorMessage = null, e: boolean = true) {
				setHasError(e)
				if (e) {
					setErrorMessage(errorMessage)
					scrollToError()
				}
			},
			setSuccess(successMessage: string) {
				setSuccess(true)
				setErrorMessage(successMessage)
			},
			getValue() {
				return value
			},
			clear() {
				clear()
			}
		}),
		[value]
	)

	const scrollToError = () =>
		inputRef.current.scrollIntoView({
			block: 'center',
			inline: 'center',
			behavior: 'smooth',
			alignToTop: true
		})

	const getColorError = () => {
		return hasError || success ? (success ? Theme.colors.green() : Theme.colors.red()) : Theme.colors.white()
	}

	const onChangeHandler = (e) => {
		if (onChange) {
			onChange(e)
		}

		if (onDebounce) {
			setDebouncedValue(e)
		}

		if (!controlled) {
			clear()
		}
	}

	const onBlurHandler = (e) => {
		if (onBlur) {
			onBlur(e)
		}
		if (!controlled) {
			clear()
		}
	}

	const onKeyUpHandler = (e) => {
		if (onKeyUp) onKeyUp(e)
		if (as !== 'textarea') {
			if (e.keyCode === 13) {
				if (onEnter) {
					onEnter()
					inputFileRef.current?.blur()
				}
			}
		}
	}

	const onWheelHandler = (e: WheelEvent) => {
		;(e.currentTarget as HTMLInputElement).blur()
	}

	let $shortcuts = null
	if (shortcuts) {
		$shortcuts = shortcuts.map((shortcut, i) => (
			<Box
				key={`shortcut-${i}`}
				css={[Style.shortcut]}
				onClick={() => {
					if (onChange) {
						onChange({ currentTarget: { value: inputFileRef.current.value + shortcut } })
					}
				}}
			>
				{shortcut}
			</Box>
		))
	}

	useEffect(() => {
		if (debouncedValue) {
			const timeout = setTimeout(() => {
				onDebounce(debouncedValue).finally(() => setDebouncedValue(null))
			}, debounceTime)
			return () => clearTimeout(timeout)
		}
	}, [debouncedValue])

	const showText = (setIsToggled && isToggled) || !setIsToggled

	const clear = () => {
		setErrorMessage(null)
		setHasError(false)
		setSuccess(false)
	}
	return (
		<Box
			width={1}
			mb={mb}
			ref={inputRef}
			css={[
				Style.el,
				style,
				isHidden ? Style.isHidden : null,
				prefixIconName ? Style.hasPrefixIcon : null,
				smallIcon && prefixIconName ? Style.smallIconInput : null,
				prefixIconName && hasError && errorMessage ? Style.prefixIconWithError : null
			]}
			className={className}
		>
			<Flex justifyContent="space-between">
				<Box>
					{label && (
						<Label
							optional={optional}
							optionalText={optionalText}
							fontSize={['12px']}
							lineHeight="14px"
							mb={20}
							color={getColorError()}
						>
							{label} {tooltipText && <Tooltip text={tooltipText} bicolor />}
						</Label>
					)}
					{prefixIconName && (
						<Icon
							fill={hasError ? Theme.colors.red() : Theme.colors.white()}
							height={smallIcon ? 15 : 18}
							width={smallIcon ? 15 : 18}
							name={prefixIconName}
							className={`prefix_icon--${prefixIconName}`}
						/>
					)}
				</Box>
				{setIsToggled && (
					<Switch
						isDisabled={isDisabled}
						isActive={isToggled}
						onClick={() => {
							if (hasError || success) setHasError(false)
							setIsToggled(!isToggled)
						}}
					/>
				)}
			</Flex>
			{showText && !onlyToggle && (
				<>
					<InputWrapper>
						<Box
							id={id}
							as={as}
							rows={rows}
							cols={cols}
							type={type}
							step={step}
							name={name || (label && `input-${label.toLocaleLowerCase()}`)}
							inputMode={inputMode}
							ref={inputFileRef}
							value={value || ''}
							onChange={onChangeHandler}
							onBlur={onBlurHandler}
							onFocus={onFocus}
							placeholder={placeholder}
							autoComplete="off"
							color={getColorError()}
							onKeyUp={onKeyUpHandler}
							onWheel={onWheelHandler}
							maxLength={maxLength}
							p={padding}
							css={[
								as === 'textarea' ? Style.textArea : null,
								Style.input,
								style,
								isDisabled ? Style.isDisabled : null,
								hasError ? Style.error : null,
								success && Style.success,
								isDark && isDisabled ? Style.darkAndDisabled : null
							]}
							className="input"
							readOnly={isDisabled}
							min={type === 'number' ? min : null}
							max={type === 'number' ? max : null}
						/>
						{shortcuts && (
							<Flex
								width={1}
								css={[Style.shortcuts]}
								p={'0px 20px'}
								justifyContent="space-around"
								alignItems="center"
								flexWrap="wrap"
							>
								{$shortcuts}
							</Flex>
						)}
						{debouncedValue && (
							<SpinnerWrapper>
								<Spinner height={25} width={25}></Spinner>
							</SpinnerWrapper>
						)}
						{(suffix !== null || suffix !== undefined) && (
							<Box css={[Style.suffix, errorMessage ? Style.suffixHasError : null]}>{suffix}</Box>
						)}
						{endAdornment && <EndAdornment>{endAdornment}</EndAdornment>}
					</InputWrapper>
					{errorMessage && (
						<Box css={Style.errorMessage} mt={2} color={getColorError()}>
							{errorMessage}
						</Box>
					)}
				</>
			)}
		</Box>
	)
}

export const InputLabel = Label

export default Input
