import React from 'react'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Theme, { Variant } from '@styles/theme'
import { ellipsisText } from '@styles/shared'

const handleTextVariant = (props) => {
	const { variant, color, weight, size, height, font } = props
	return css`
		font-size: ${size ? size : Theme.text[variant]?.fontSize};
		font-weight: ${weight ? weight : Theme.text[variant]?.fontWeight};
		line-height: ${height ? height : Theme.text[variant]?.lineHeight};
		color: ${color ? color : Theme.text[variant]?.color};
		font-family: ${font ? font : Theme.fontFamilies.Main};
	`
}

const handleAsStyle = ({ href }: TextInterfaceProps) => {
	if (href) {
		return css`
			color: ${Theme.colors.white()};
			text-decoration: underline;
			cursor: pointer;
		`
	}
	return css``
}

export interface TextInterfaceProps {
	variant?: Variant
	fullWidth?: boolean
	className?: string
	size?: string
	height?: string
	weight?: string
	color?: string
	fontCase?: 'capitalize' | 'uppercase' | 'lowercase' | 'none'
	ellipsis?: boolean
	align?: string
	href?: string
	wordBreak?: string
	foont?: string
	letterSpacing?: string
	whiteSpace?: string
	onClick?: () => void
}

const TextWrapper = styled('p')<TextInterfaceProps>`
	min-width: ${({ ellipsis }) => (ellipsis ? 'unset' : 'fit-content')};
	width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
	${(props) => handleTextVariant(props)}
	text-transform: ${({ fontCase }) => (fontCase ? fontCase : '')};
	text-align: ${({ align }) => align || 'unset'};
	${({ ellipsis }) => ellipsis && ellipsisText};
	word-break: ${({ wordBreak }) => wordBreak || 'normal'};
	letter-spacing: ${({ letterSpacing }) => letterSpacing || 'normal'};
	white-space: ${({ whiteSpace }) => whiteSpace || 'normal'};
	${handleAsStyle}
`

const Text: React.FC<TextInterfaceProps> = ({ children, onClick, ...props }) => {
	const handleClick = () => {
		if (props?.href) {
			window.open(props.href, '_blank').focus()
		}
		if (onClick) {
			onClick()
		}
	}

	return (
		<TextWrapper onClick={handleClick} {...props}>
			{children}
		</TextWrapper>
	)
}

export default Text
