import { css } from '@emotion/react'
import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { Flex, Box } from 'rebass'
import { popinClasses } from './classes'

export const PopinWrapper = styled(Flex)(() => ({
	position: 'fixed',
	alignItems: 'center',
	pointerEvents: 'none',
	top: 0,
	left: 0,
	zIndex: 100,
	fontFamily: 'PlusJakartaSans',
	flexWrap: 'wrap',
	height: '100%',
	width: '100%',
	[`&.${popinClasses.opened}`]: {
		pointerEvents: 'all',
		overflow: 'auto'
	}
}))

export const Title = styled(Flex)(() => ({
	fontWeight: 'bold',
	fontSize: '20px',
	color: Theme.colors.white(),
	width: '100%',
	marginBottom: Theme.spacing(2),
	'& span': {
		color: Theme.colors.green(),
		textTransform: 'capitalize'
	}
}))

export const Subtitle = styled(Box)(() => ({
	fontSize: '14px',
	lineHeight: '140%',
	color: Theme.colors.grey3,
	marginBottom: Theme.spacing(2)
}))

export const Overlay = styled(Box)(() => ({
	height: '100%',
	opacity: 0,
	background: Theme.colors.black(0.8),
	transition: `opacity 600ms ${Theme.eases.outExpo} 0ms`,
	position: 'fixed',
	top: 0,
	left: 0,
	[`&.${popinClasses.opened}`]: {
		opacity: 1
	}
}))

export const Panel = styled(Flex)(() => ({
	width: '600px',
	margin: `${Theme.spacing(10)} auto`,
	paddingTop: Theme.spacing(16),
	borderRadius: '30px',
	transition: `all 600ms ${Theme.eases.outExpo} 0ms`,
	backgroundColor: Theme.colors.blue2,
	transform: 'translateY(100%)',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	opacity: 0,
	[`&.${popinClasses.opened}`]: {
		transform: 'translateY(0)',
		opacity: 1
	}
}))

export default {
	closeIcon: css`
		position: absolute;
		top: 30px;
		right: 30px;
		padding: 10px;
		border: 1px solid ${Theme.colors.grey2};
		border-radius: 8px;
		cursor: pointer;

		i {
			color: ${Theme.colors.white()};
		}
	`
}
