import styled from '@emotion/styled'
import { Box, Flex } from 'rebass'
import { css } from '@emotion/react'
import Theme from '@styles/theme'
import { Griddable, handleGriddable } from '@styles/shared'

const disableStyle = css`
	background-color: ${Theme.colors.blue1};
	color: ${Theme.colors.white()};
	cursor: initial;
	pointer-events: none;
`

export const baseInputCss = () => {
	return css`
		position: relative;
		display: flex;
		height: 50px;
		min-height: 50px;
		color: ${Theme.colors.white()};
		background-color: ${Theme.colors.grey1};
		font-size: 14px;
		border: 1px solid ${Theme.colors.grey2};
		border-radius: 10px;
		align-content: center;
		font-family: 'PlusJakartaSans';
		&:focus-within {
			border: 1px solid ${Theme.colors.grey3};
		}
	`
}

export const BaseField = styled(Box)<{ outlined?: boolean }>`
	${() => baseInputCss()}
	max-height: '50px';
	background-color: ${({ outlined }) => (outlined ? 'transparent' : `${Theme.colors.blue1}`)};
	border: ${({ outlined }) => (outlined ? `1px solid ${Theme.colors.grey1}` : 'none')};
	transition: border 600ms ${Theme.eases.outExpo} 0ms;
`

export const BaseFieldButton = styled(Box)<{ outlined?: boolean; disabled?: boolean }>`
	height: fit-content;
	cursor: pointer;
	padding: 7px 13px 7px 9px;
	background: ${({ outlined }) => (outlined ? 'transparent' : `${Theme.colors.grey1}`)};
	text-align: center;
	border-radius: 4px;
	font-size: 10px;
	font-weight: bold;
	border: ${({ outlined }) => (outlined ? `1px solid ${Theme.colors.grey2}` : 'none')};
	color: ${Theme.colors.white()};
	&:active {
		box-shadow: 0px 0px 0px 1px ${Theme.colors.white(0.5)};
	}
	${({ disabled }) => (disabled ? disableStyle : null)}
`

export const BaseLabel = styled(Flex)(() => ({
	color: Theme.colors.white(),
	fontSize: '12px',
	fontStyle: 'normal',
	fontWeight: 700,
	lineHeight: '12px',
	letterSpacing: '0px',
	textAlign: 'left',
	marginBottom: Theme.spacing(4)
}))

export const BaseFlex = styled.div<Griddable & { alignItems?; justifyContent?; gap?; width?; direction? }>`
	${handleGriddable}
	display: flex;
	justify-content: ${({ justifyContent }) => justifyContent || 'unset'};
	align-items: ${({ alignItems }) => alignItems || 'unset'};
	gap: ${({ gap }) => gap || 'unset'};
	width: ${({ width }) => width || 'unset'};
	flex-direction: ${({ direction }) => direction || 'row'};
`
