import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { classes as searchInputClasses } from '@components/Micro/Fields/SearchInput/style'
import { BaseFlex } from '@styles/Basics'

export const Header = styled.div`
	position: relative;
	width: 100%;
	flex-wrap: wrap;
	min-height: 50px;
`

export const AbsoluteContainer = styled.div`
	position: absolute;
	display: flex;
	justify-content: end;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	& .${searchInputClasses.base} {
		background-color: ${Theme.colors.blue2};
	}
`

export const HeaderTitle = styled(BaseFlex)`
	margin-bottom: ${Theme.spacing(2)};
	color: ${Theme.colors.white()};
	font-weight: bold;
	font-size: 20px;
	width: 100%;
	& span {
		color: ${Theme.colors.green()};
		text-transform: capitalize;
	}
`

export const HeaderSubTitle = styled(BaseFlex)`
	color: ${Theme.colors.grey3};
	font-size: 14px;
	line-height: 140%;
	width: 100%;
`

export const EmptyStateWrapper = styled(BaseFlex)`
	justify-content: center;
	align-items: center;
	min-height: 200px;
	width: 100%;
	margin-top: ${Theme.spacing(10)};
`

export const CardPickContainer = styled(BaseFlex)`
	width: 100%;
	flex-wrap: wrap;
	margin-top: ${Theme.spacing(1)};
`

export const ItemsWrapper = styled.div`
	width: 100%;
	margin-top: ${Theme.spacing(4)};
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	gap: ${Theme.spacing(2)};
`

export const ItemsCount = styled(BaseFlex)`
	color: ${Theme.colors.grey3};
	margin-left: 5px;
	font-weight: normal;
`
