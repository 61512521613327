import { css } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { tooltipClasses } from '@components/Micro/Tooltip'

export default {
	inputLabel: css`
		position: relative;
		font-weight: bold;
		font-family: 'PlusJakartaSans';
		& .${tooltipClasses.root} {
			margin-left: 2px;
			vertical-align: inherit;
			& i {
				position: inherit;
				bottom: inherit;
				left: inherit;
			}
		}
	`,

	isHidden: css`
		display: none;
	`,

	input: css`
		width: 100%;
		background: none;
		border: 1px solid ${Theme.colors.grey2};
		font-size: 14px;
		padding: 12px 20px 12px;
		outline: none;
		border-radius: 10px;
		appearance: none;
		font-weight: normal;
		font-family: 'PlusJakartaSans';
		line-height: 24px;
		text-overflow: ellipsis;
		&:focus-within {
			border: 1px solid ${Theme.colors.grey3};
		}
		&::-webkit-calendar-picker-indicator {
			background: none;
		}
	`,

	hasError: css`
		border: 1px solid ${Theme.colors.red()};
	`,

	optional: css`
		color: ${Theme.colors.grey3};
	`,

	textArea: css`
		min-width: 100%;
		max-width: 100%;
		height: 100%;
		min-height: 100%;
		max-height: 100%;
		resize: none;
	`,

	isDisabled: css`
		opacity: 0.5;
	`,
	darkAndDisabled: css`
		background-color: ${Theme.colors.blue1};
		border: 1px solid ${Theme.colors.blue1};
		opacity: 1;
		font-size: 14px;
	`,

	el: css`
		position: relative;
		input[type='number']::-webkit-outer-spin-button,
		input[type='number']::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		input[type='number'] {
			-moz-appearance: textfield;
		}
	`,

	suffix: css`
		font-size: 14px;
		color: ${Theme.colors.grey3};
		position: absolute;
		right: 15px;
	`,

	shortcuts: css`
		font-size: 17em;
		position: absolute;
		left: 0;
		bottom: 0;
		height: 40px;
		border-top: 1px solid ${Theme.colors.grey2};
	`,

	shortcut: css`
		cursor: pointer;
	`,

	error: css`
		border-color: ${Theme.colors.red()};
	`,
	success: css`
		border-color: ${Theme.colors.green()};
	`,
	hasPrefixIcon: css`
		input {
			padding-left: 50px;
		}
		i {
			position: absolute;
			bottom: 17px;
			left: 20px;
		}
	`,
	prefixIconWithError: css`
		i {
			bottom: 37px;
		}
	`,
	errorMessage: css`
		font-size: 12px;
	`,
	smallIconInput: css`
		input {
			padding-left: 45px;
		}
		i {
			bottom: 17px;
		}
	`,
	suffixHasError: css`
		bottom: 38px;
		color: ${Theme.colors.red()};
	`
}

export const SpinnerWrapper = styled('div')`
	font-size: 14px;
	color: ${Theme.colors.grey3};
	position: absolute;
	right: 15px;
	bottom: 7px;
	height: 30px;
`

export const EndAdornment = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	height: 52px;
	bottom: 0;
	right: 0;
	padding: ${Theme.spacing(2)};
`

export const InputWrapper = styled.div`
	display: flex;
	align-items: center;
`
