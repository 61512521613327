import axios, { AxiosError, AxiosInstance, AxiosResponse, Method } from 'axios'
import getConfig from 'next/config'
import { makeUseAxios, UseAxios } from 'axios-hooks'
import { useAuth } from '@helpers/contexts/authUserContext'
import { useCallback, useMemo } from 'react'
import { captureException, setUser } from '@sentry/nextjs'
import { AxiosResponseError } from '@customTypes/errors'
import { onResponse } from '@helpers/fetchHelpers'

const { publicRuntimeConfig } = getConfig()

const NEXT_PUBLIC_API_ENDPOINT: string = publicRuntimeConfig.NEXT_PUBLIC_API_ENDPOINT

interface Options {
	url?: string
	method?: Method
	manual?: boolean
	useCache?: boolean
	ssr?: boolean
	autoCancel?: boolean
}

const defaultOptions: Options = {
	method: 'GET',
	manual: true,
	useCache: true,
	ssr: true,
	autoCancel: true
}

interface HeadersOptions {
	'Content-type': string
	Authorization?: string
}

const useAxiosCall = (options: Options = defaultOptions) => {
	const { getUserToken, authUser } = useAuth()

	const axiosInstance: AxiosInstance = axios.create({
		baseURL: NEXT_PUBLIC_API_ENDPOINT
	})

	// Intercept response and errors
	axiosInstance.interceptors.response.use(
		(response: AxiosResponse) => onResponse(response, authUser),
		(error: AxiosError) => {
			// Add user data to sentry error
			if (authUser) {
				setUser(authUser._delegate)
			}

			captureException(new AxiosResponseError(error.message))
			return Promise.reject(error)
		}
	)

	const useAxios: UseAxios = makeUseAxios({
		axios: axiosInstance
	})

	const [state, execute]: [any, any, any] = useAxios(
		{
			url: '',
			method: options.method
		},
		{ manual: true, useCache: options.useCache, ssr: options.ssr, autoCancel: options.autoCancel }
	)

	const executeRequest = (data, options, token?) => {
		const headers: HeadersOptions = { 'Content-type': 'application/json; charset=UTF-8' }
		if (token) headers.Authorization = token
		const executeCommand = {
			...data,
			...options,
			headers
		}
		if (data?.url) {
			executeCommand['data'] = {}
			executeCommand.data = { ...data }
		}
		return execute(executeCommand)
	}

	const callback = useCallback(
		// url should be in options
		(options, data) => {
			authUser
				? getUserToken().then((token) => {
						executeRequest(data, options, token)
				  })
				: executeRequest(data, options)
		},
		[execute]
	)

	return useMemo(() => [state, callback], [state, callback])
}

export { useAxiosCall }
