import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { iconTextClasses } from '@components/Micro/Content/IconAndText/classes'

const PREFIX = 'switch-box'

const iconShapeStyle = ({ iconShape }: SwitchBoxWrapperProps) => {
	switch (iconShape) {
		case 'square':
			return css`
				& .${iconTextClasses.iconContainer} {
					border-radius: 8px;
					background-color: ${Theme.colors.grey1};

					& i {
						height: 15px;
						width: 15px;

						& svg {
							fill: ${Theme.colors.white()};
						}
					}
				}
			`
		case 'circle':
		default:
			return css``
	}
}

const variantStyle = ({ variant }: SwitchBoxWrapperProps) => {
	switch (variant) {
		case 'contained':
			return css`
				background-color: ${Theme.colors.blue1};
				border: 1px solid ${Theme.colors.blue1};
			`
		case 'outline':
		default:
			return css``
	}
}

export const classes = {
	root: PREFIX,
	disabled: `${PREFIX}-disabled`
}

export interface SwitchBoxWrapperProps {
	iconShape?: 'circle' | 'square'
	variant?: 'outline' | 'contained'
	noMargin?: boolean
}

export const SwitchBoxWrapper = styled.div<SwitchBoxWrapperProps>`
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 10px 20px 10px 15px;
	border-radius: 15px;
	border: 1px solid ${Theme.colors.grey2};
	margin-bottom: ${({ noMargin }) => Theme.spacing(noMargin ? 0 : 3)};
	&.${classes.disabled} {
		background-color: ${Theme.colors.blue1};
		border: 1px solid ${Theme.colors.blue1};
		pointer-events: none;
	}
	${iconShapeStyle}
	${variantStyle}
`
