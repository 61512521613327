import { popinClasses } from '@components/Templates/Popin/classes'
import styled from '@emotion/styled'
import Theme from '@styles/theme'

const PREFIX = 'notification'

const bgColors = {
	warning: Theme.colors.yellow,
	success: Theme.colors.green(),
	danger: Theme.colors.red()
}

export type NotificationType = keyof typeof bgColors

export const classes = {
	icon: `${PREFIX}-icon`,
	text: `${PREFIX}-text`
}

export const NotificationPopinWrapper = styled('div')<{ variant: NotificationType }>(({ variant }) => ({
	['& .modal.popin-opened']: {
		pointerEvents: 'none'
	},
	[`& .${popinClasses.base}`]: {
		marginTop: Theme.spacing(6),
		backgroundColor: bgColors[variant],
		maxWidth: '800px',
		width: 'unset',
		minHeight: '55px',
		padding: Theme.spacing(0),
		borderRadius: '10px',
		fontSize: '14px !important',
		fontWeight: 'bold',
		color: Theme.colors.white()
	},
	[`& .${popinClasses.wrapper}`]: {
		alignItems: 'baseline'
	},
	[`& .${popinClasses.overlay}`]: {
		display: 'none'
	}
}))

export const NotificationContent = styled('div')(() => ({
	width: '100%',
	boxSizing: 'border-box',
	display: 'flex',
	justifyContent: 'space-between',
	padding: Theme.spacing(3),
	alignItems: 'center',
	pointerEvents: 'all',
	[`& .${classes.icon}`]: {
		marginRight: Theme.spacing(3),
		['& > i']: {
			height: '16.5px'
		}
	},
	[`& .${classes.text}`]: {
		flexGrow: 1,
		alignContent: 'start',
		lineBreak: 'auto'
	}
}))

export const NotificationTexts = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: ${Theme.spacing(2)};
`
