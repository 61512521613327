import React, { useEffect } from 'react'
import { Box } from 'rebass'
import Style, { PopinWrapper, Title, Subtitle, Overlay, Panel } from './style'
import Icon from '@components/Micro/Icon'
import Theme from '@styles/theme'
import cx from 'classnames'
import { popinClasses } from './classes'
import { PortalDiv } from '@helpers/Portal'

interface Props {
	isOpened?: boolean
	onClose?: Function
	children?: React.ReactNode
	hasCta?: boolean
	noClose?: boolean
	noLock?: boolean
	autohideDuration?: number
}

const Popin: React.FC<Props> = ({ isOpened, onClose, children, hasCta, noClose, noLock, autohideDuration }) => {
	/**
	 * @Effects
	 */
	useEffect(() => {
		document.body.style.overflow = isOpened && !noLock ? 'hidden' : 'auto'
	}, [isOpened])

	useEffect(() => {
		if (autohideDuration && isOpened && onClose) {
			setTimeout(() => {
				onClose()
			}, autohideDuration)
		}
	}, [autohideDuration, isOpened])

	/**
	 * @Methods
	 */
	const closeClickHandler = (e) => {
		if (onClose) {
			onClose(e)
		}
	}

	return (
		<>
			<PopinWrapper className={cx(popinClasses.wrapper, { [popinClasses.opened]: isOpened })}>
				<Overlay
					width={1}
					className={cx(popinClasses.overlay, { [popinClasses.opened]: isOpened })}
					onClick={closeClickHandler}
				/>
				<Panel
					pb={hasCta ? 50 : 70}
					className={cx(`${popinClasses.base}`, { [popinClasses.opened]: isOpened })}
				>
					{!noClose && (
						<Box css={Style.closeIcon} onClick={closeClickHandler} className={popinClasses.close}>
							<Icon width={10} height={10} name="close" fill={Theme.colors.white()} />
						</Box>
					)}
					{children}
				</Panel>
				<PortalDiv />
			</PopinWrapper>
		</>
	)
}

export const PopinSubtitle = Subtitle
export const PopinTitle = Title
export default Popin
