import { availableCurrencies } from '@customTypes/channel'
import { numberWithSpaces, pluralize } from '@utils/format'
import { millisecondsToHours } from 'date-fns'

export type AxesPositions = {
	x: number
	y: number
}

/**
 * Function to exclude decimal for round numbers such as
 * 98.00% => 98
 * 25.40% => 25.40
 */
export const formatNumber = (value: number): string => {
	if (value === null || value === undefined) {
		return `NaN`
	} else {
		return Number.isInteger(value) ? value.toString() : value.toFixed(2).toString()
	}
}

export const formatPercentage = (value: number): string => {
	return `${formatNumber(value)}%`
}

export const formatPrice =
	(currency: string) =>
	(value: number): string => {
		const found = availableCurrencies.find((c) => c.code === currency)
		const code = found?.symbol || ''
		return `${numberWithSpaces(formatNumber(value))} ${code}`
	}

export const formatTimeMinuteSeconds = (timestamp: number): string => {
	const hours = millisecondsToHours(timestamp)
	const date = new Date(timestamp)?.toISOString()
	return hours ? date.slice(11, 19) : date.slice(14, 19)
}

export const formatNumberAndString = (count: number, word: string): string => {
	return `${count} ${pluralize(word, count)}`
}

export const formatClicks = (count: number): string => {
	return `${formatNumberAndString(count, 'click')}`
}

export const formatClicksPercentage = (count: number, rate: number): string => {
	return `${formatClicks(count)} (${formatPercentage(rate)})`
}
