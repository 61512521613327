export const formatToTen = (number: number): string => {
	return number < 10 ? `0${number}` : number.toString()
}

export const toDecimal = (value: number): string => {
	// Vérifie si le nombre est rond (n'a pas de décimales)
	if (value % 1 === 0) {
		return value.toString() // Retourne le nombre sous forme de chaîne sans décimales
	} else {
		return value.toFixed(2) // Retourne le nombre avec 2 décimales
	}
}
