export interface Branding {
	name: string
	brandName: string
	avatar: any
	logo: any
	fonts: {
		fontTitle: any
		fontTextRegular: any
		fontTextBold: any
	}
	colors: {
		textMainColor: string
		textSecondary: string
		backgroundColor: string
		buttonMainColor: string
		buttonSecondColor: string
	}
	networks: Network[]
	uuid?: string
	customCss?: boolean
	squaredEdges?: boolean
}

export const AvailableNetworks: NetworkLabel[] = [
	'Facebook',
	'Instagram',
	'Linkedin',
	'Pinterest',
	'Twitter',
	'Youtube',
	'TikTok'
]

export type NetworkLabel = 'Facebook' | 'Instagram' | 'Linkedin' | 'Pinterest' | 'Twitter' | 'Youtube' | 'TikTok'

export interface Network {
	url: string
	label: NetworkLabel
}

export const brandingDefaultValues: Branding = {
	name: '',
	brandName: '',
	avatar: null,
	logo: null,
	fonts: {
		fontTitle: null,
		fontTextRegular: null,
		fontTextBold: null
	},
	colors: {
		textMainColor: '#000000',
		textSecondary: '#9E9E9E',
		backgroundColor: '#FEF8ED',
		buttonMainColor: '#000000',
		buttonSecondColor: '#9E9E9E'
	},
	networks: [],
	customCss: false,
	squaredEdges: false
}
