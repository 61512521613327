import { css } from '@emotion/core'
import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { switchItemClasses } from './classes'
const PREFIX = 'switch'

export const classes = {
	root: PREFIX
}

export const SwitchBar = styled.div<{ isOn: boolean; isDisabled: boolean }>`
	width: 40px;
	height: 12px;
	border-radius: 10px;
	position: relative;
	background-color: ${({ isOn }) => (isOn ? Theme.colors.green() : Theme.colors.grey1)};
	${({ isDisabled }) =>
		isDisabled &&
		css`
			cursor: unset;
			background-color: ${Theme.colors.grey1};
		`}
`

export const SwitchRoundButton = styled.div<{ isOn: boolean; isDisabled: boolean }>`
	position: absolute;
	top: -4px;
	height: 20px;
	width: 20px;
	border-radius: 10px;
	background-color: ${Theme.colors.white()};
	${({ isOn }) =>
		isOn &&
		css`
			transform: translateX(20px);
		`}
	${({ isDisabled }) =>
		isDisabled &&
		css`
			cursor: unset;
			background-color: ${Theme.colors.grey2};
		`}
`

export const SwitchWrapper = styled.div<{ isDisabled: boolean }>`
	cursor: pointer;
	${({ isDisabled }) =>
		isDisabled &&
		css`
			cursor: unset;
			& .${switchItemClasses.bar} {
				background-color: ${Theme.colors.grey1};
			}
			& .${switchItemClasses.btn} {
				background-color: ${Theme.colors.grey2};
			}
		`}
`
