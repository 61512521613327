import React, { useImperativeHandle, useRef } from 'react'
import { Box } from 'rebass'
import { Domain } from 'types/channel'
import DomainItem from './DomainItem'
import Style from './style'
import { AutoRowGrid } from '@styles/Grid'
import Theme from '@styles/theme'

interface Props {
	innerRef?: any
	domains: Domain[]
	activeDomains?: string[]
	formDatas?: any
	setFormDatas?: any
	onChange?: (activeDomainsIds: string[]) => void
	domainsPath?: string
	isDisabled?: boolean
	isTest?: boolean
	setDataWithFunction?: boolean
}

const DomainsBody: React.FC<Props> = ({
	innerRef,
	domains,
	formDatas,
	onChange,
	setFormDatas,
	domainsPath = 'domains',
	isDisabled = false,
	isTest = false,
	setDataWithFunction = true,
	activeDomains
}) => {
	const itemRefs = useRef([])

	useImperativeHandle(
		innerRef,
		() => ({
			validate() {
				return itemRefs.current.every((domain) => domain.validate())
			}
		}),
		[formDatas]
	)

	const handleDomainsChange = (domain: Domain) => {
		if (activeDomains) {
			if (activeDomains.includes(domain.uuid)) {
				onChange(activeDomains.filter((dId) => dId !== domain.uuid))
			} else {
				onChange(activeDomains.concat(domain.uuid))
			}
		}
	}

	return (
		<>
			<AutoRowGrid gap={Theme.spacing(3)}>
				{domains.map((domain, i) => {
					const isActive =
						domainsPath && formDatas
							? formDatas[domainsPath].find((d) => d.uuid === domain.uuid)
							: activeDomains.includes(domain.uuid)
					return (
						<DomainItem
							domain={domain}
							formDatas={formDatas}
							setFormDatas={setFormDatas}
							onChange={handleDomainsChange}
							key={`domain-item-${i}`}
							innerRef={(el) => (itemRefs.current[i] = el)}
							domainsPath={domainsPath}
							isActive={!isActive}
							isTest={isTest}
							isDisabled={isDisabled}
							setDataWithFunction={setDataWithFunction}
						/>
					)
				})}
			</AutoRowGrid>
			{isDisabled && !domains.length && <Box css={Style.noDomain}>No domains</Box>}
		</>
	)
}

export default DomainsBody
