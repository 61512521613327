import Icon from '@components/Micro/Icon'
import React from 'react'
import { ColoredPaperContainer, ColoredPaperContent, ColoredPaperIcon, getColorByType } from '../style'
import Theme from '@styles/theme'
import Text from '@components/Micro/Text'
import { SimpleGrid } from '@styles/Grid'
import { ColoredPaperProps } from '../types'

const BaseColoredPaper: React.FC<ColoredPaperProps> = ({ icon, title, subTitle, classes, type }) => {
	return (
		<ColoredPaperContainer className={classes.root} type={type}>
			<SimpleGrid gap={Theme.spacing(2)} columns={2}>
				{icon && (
					<ColoredPaperIcon type={type}>
						<Icon name={icon} width="100%" height="100%" fill={Theme.colors.white()} />
					</ColoredPaperIcon>
				)}
				<ColoredPaperContent>
					{title && (
						<Text size="14px" color={getColorByType(type)} weight="bold">
							{title}
						</Text>
					)}
					{subTitle && (
						<Text className={classes.subTitle} size="12px" color={getColorByType(type)} height="14px">
							{subTitle}
						</Text>
					)}
				</ColoredPaperContent>
			</SimpleGrid>
		</ColoredPaperContainer>
	)
}

export default BaseColoredPaper
