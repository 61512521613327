export type Link = {
	url: string
	label: string
	target?: string
}

export type MediaFile = {
	uuid: string
	mimeType: string
	url: string
	name: string
}

export interface MediaData {
	path: string
	label?: string
	acceptedFormats: string
	acceptedSize?: AcceptedSize
	//in megabytes
	acceptedWeightMB?: number
}

export interface AcceptedSize {
	height: number | string
	width: number | string
}

export interface DropdownEntry {
	name: string
	iconName: string
	onClickHandler: () => void
}

export interface HoveredStatsData {
	count: string | number
	name: string
}

export enum FormStatus {
	INITIAL = 'initial',
	CHANGED = 'changed',
	SAVE_UNSAVED_CHANGES = 'save_unsaved_changes',
	REDIRECT = 'redirect',
	CHANGE_TAB = 'change_tab',
	CANCEL_AND_REDIRECT = 'cancel_and_redirect',
	CREATE = 'create',
	OPEN_UNSAVED_CHANGES_POPIN = 'open_unsaved_changes_popin'
}

export interface EmptyStateArgs {
	title: string
	description: string
	iconName: string
}

export interface IconSettings {
	height?: string | number
	width?: string | number
}

export type SaveStatus = 'hasChange' | 'saving' | 'noChange'

export type WaitStatus = 'waiting' | 'ongoing' | 'done'

export type NumberFields<T> = {
	[K in keyof T]: T[K] extends number ? K : never
}[keyof T]

export type DownloadProgress = {
	started: boolean
	percent: number
	zipping?: boolean
}
