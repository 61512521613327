import React, { createContext, useContext, useMemo, useState } from 'react'
import ConfirmPopin, { ConfirmPopinProps } from '@components/Popins/Confirm'
import Notification, { NotificationProps } from '@components/Popins/Notification'
import { getConfirmPopinProps, ConfirmPopinTypes, ConfirmPopinMetadata } from './logics'
import { NotificationType } from '@components/Popins/Notification/style'

type Strings = string | Array<string>

interface GlobalContext {
	openConfirmPopin?: (
		type: string,
		confirmCallback?: () => void,
		cancelCallback?: () => void,
		closeCallback?: () => void,
		children?: any
	) => void
	closeConfirmPopin?: () => void
	openNotification?: (icon?: string, text?: Strings, autohideDuration?: number, variant?: NotificationType) => void
	openWarningNotification?: (text: Strings, autohideDuration?: number, timeout?: number) => void
	openSuccessNotification?: (text: Strings, autohideDuration?: number, timeout?: number) => void
	openErrorNotification?: (text: Strings, autohideDuration?: number, timeout?: number) => void
	setRedirectUrl?: React.Dispatch<React.SetStateAction<string>>
	redirectUrl?: string
	backPath?: string
	setBackPath?: (backPath: string) => void
}

const Context = createContext<GlobalContext | null>({})

export const ConfirmPopinTypesEnum = ConfirmPopinTypes

export const useGlobalContext = () => useContext(Context)

export const GlobalProvider = ({ children }) => {
	const [redirectUrl, setRedirectUrl] = useState<string>('')
	const [confirmPopinProps, setConfirmPopinProps] = useState<ConfirmPopinProps>({})
	const [notificationProps, setNotificationProps] = useState<NotificationProps>({})
	const [notificationOpen, setNotificationOpen] = useState<boolean>(false)
	const [confirmOpen, setConfirmOpen] = useState<boolean>(false)
	const [backPath, setBackPath] = useState<string>(null) // Handle back routing from tabviews
	/**
	 * @Methods
	 */
	const closeConfirmPopin = () => {
		if (confirmPopinProps.onClose) {
			confirmPopinProps.onClose()
		}
		setConfirmOpen(false)
	}

	const openConfirmPopin = (
		type: string,
		confirmCallback: () => void,
		cancelCallback: () => void,
		closeCallback?: () => void,
		metadata?: ConfirmPopinMetadata
	) => {
		setConfirmOpen(true)
		setConfirmPopinProps(getConfirmPopinProps(type, confirmCallback, cancelCallback, closeCallback, metadata))
	}

	const closeNotification = () => {
		setNotificationOpen(false)
	}

	const openNotification = (
		icon?: string,
		text?: string,
		autohideDuration?: number,
		variant?: NotificationType,
		timeout = 0
	) => {
		setTimeout(() => {
			setNotificationOpen(true)
			setNotificationProps({
				text,
				icon,
				autohideDuration,
				variant: variant || 'success'
			})
		}, timeout)
	}

	const openWarningNotification = (text?: string, autohideDuration?: number, timeout?: number) => {
		openNotification('warning', text, autohideDuration || 3000, 'warning', timeout)
	}

	const openSuccessNotification = (text?: string, autohideDuration?: number, timeout?: number) => {
		openNotification('check_round', text, autohideDuration || 3000, 'success', timeout)
	}

	const openErrorNotification = (text?: string, autohideDuration?: number, timeout?: number) => {
		openNotification('cross_round', text, autohideDuration || 3000, 'danger', timeout)
	}

	const values = useMemo(
		(): GlobalContext => ({
			openConfirmPopin,
			closeConfirmPopin,
			openNotification,
			openWarningNotification,
			openSuccessNotification,
			openErrorNotification,
			redirectUrl,
			setRedirectUrl,
			backPath,
			setBackPath
		}),
		[openConfirmPopin, closeConfirmPopin, openNotification, redirectUrl, setRedirectUrl, backPath, setBackPath]
	)

	return (
		<Context.Provider value={values}>
			{children}
			<ConfirmPopin {...confirmPopinProps} isOpened={confirmOpen} onClose={closeConfirmPopin} />
			<Notification {...notificationProps} isOpened={notificationOpen} onClose={closeNotification} />
		</Context.Provider>
	)
}
