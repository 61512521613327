import styled from '@emotion/styled'
import Theme from '@styles/theme'

export const PopinContent = styled.div`
	width: 80%;
`
export const PopinTitle = styled.div`
	color: ${Theme.colors.white()};
	text-align: left;
	font-weight: bold;
	font-size: 20px;
	margin-bottom: 10px;
	width: 100%;
`

export const PopinText = styled.div`
	color: ${Theme.colors.grey3};
	font-weight: normal;
	font-size: 14px;
	margin-bottom: ${Theme.spacing(7)};
`
