import { useState, useEffect } from 'react'
import Firebase from '@helpers/firebase'
import { User } from 'firebase/auth'
require('firebase/compat/auth')

export default function useFirebaseAuth() {
	const [authUser, setAuthUser] = useState<User>(null)
	const [userToken, setUserToken] = useState<string>(null)
	const [isLoading, setIsLoading] = useState<boolean>(true)

	const authStateChanged = async (authState: User) => {
		if (!authState) {
			setIsLoading(false)
			return
		}
		setIsLoading(true)
		authState.getIdTokenResult().then((token) => {
			setUserToken(token.token)
			setAuthUser(authState)
			setIsLoading(false)
		})
	}

	const getUserToken = (): Promise<string> => {
		const setTokenAndReturn = (t: string) => {
			setUserToken(t)
			return t
		}

		if (authUser?.getIdToken) {
			return authUser.getIdToken().then(setTokenAndReturn)
		} else if (Firebase.auth()?.currentUser) {
			return Firebase.auth().currentUser.getIdToken().then(setTokenAndReturn)
		} else {
			return Promise.resolve(userToken)
		}
	}

	const clear = () => {
		setAuthUser(null)
		setIsLoading(true)
	}

	const signInWithEmailAndPassword = (email, password) => Firebase.auth().signInWithEmailAndPassword(email, password)

	const createUserWithEmailAndPassword = (email, password) =>
		Firebase.auth().createUserWithEmailAndPassword(email, password)

	const signOut = () => Firebase.auth().signOut().then(clear)

	useEffect(() => {
		const unsubscribe = Firebase.auth().onIdTokenChanged(authStateChanged)
		return () => unsubscribe()
	}, [])

	return {
		authUser,
		userToken,
		isLoading,
		signInWithEmailAndPassword,
		createUserWithEmailAndPassword,
		signOut,
		setAuthUser,
		getUserToken
	}
}
