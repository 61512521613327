import React from 'react'
import Cta from '@components/Micro/Button'
import Theme from '@styles/theme'
import { PopinContent } from '@components/Popins/style'
import PopinHeader from '@components/Popins/header'
import CreateWatchListForm from '../Common/CreateWatchlistForm'
import { FormWrapper } from './style'
import { useGlobalContext } from '@helpers/contexts/global'
import { ConfirmPopinTypes } from '@helpers/contexts/global/logics'
import { useModalsContext } from '@helpers/contexts/modals/ModalsContext'
import { HighlightsList } from '@customTypes/highlight'
import { addWatchlistClasses } from './classes'

interface AddWatchlistPopinProps {
	onSubmit?: (watchlist: HighlightsList) => void
}

type ChildCtaProps = Partial<any> & {
	onClick: () => void
}

interface AddWatchlistPopinCtaProps {
	onClick?: () => void
	onSubmit?: (watchlist: HighlightsList) => void
	onConfirmClose?: () => void
	Component?: React.FC<ChildCtaProps>
}

export const AddWatchlistPopinCta: React.FC<AddWatchlistPopinCtaProps> = ({
	Component,
	onSubmit,
	onClick,
	onConfirmClose
}) => {
	const { openConfirmPopin } = useGlobalContext()
	const { openModal, closeModal } = useModalsContext()

	const handleSubmit = (watchlist: HighlightsList) => {
		if (onSubmit) onSubmit(watchlist)
		closeModal()
		setTimeout(() => {
			openConfirmPopin(ConfirmPopinTypes.WATCHLIST_CREATED, null, null, onConfirmClose, {
				watchlistId: watchlist.uuid
			})
		}, 200)
	}

	const handleClick = () => {
		if (onClick) onClick()
		openModal('ADD_WATCHLIST', { onSubmit: handleSubmit })
	}

	return (
		<>
			{Component ? (
				<Component onClick={handleClick} className={addWatchlistClasses.cta} />
			) : (
				<Cta
					className={addWatchlistClasses.cta}
					justifyContent={'flex-start'}
					color={Theme.colors.grey1}
					label={'Create'}
					onClick={handleClick}
				/>
			)}
		</>
	)
}

const AddWatchlistPopinContent: React.FC<AddWatchlistPopinProps> = ({ onSubmit }) => {
	return (
		<PopinContent className={addWatchlistClasses.popin}>
			<PopinHeader
				icon="watchlist"
				title="Create playlist"
				description="Centralize video content within a single group"
			/>
			<FormWrapper>
				<CreateWatchListForm onSubmit={onSubmit} />
			</FormWrapper>
		</PopinContent>
	)
}

export default AddWatchlistPopinContent
