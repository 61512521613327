import { SelectEntry } from '@components/Micro/Fields/Select/ControlledSelect'
import { LegalSettings } from './legal_settings'
import { Role } from './user'

type LanguageName = 'Français' | 'English' | 'Deutsch' | 'Español' | 'Português' | 'Italian' | 'Polska'
export type LanguageLocale = 'fr' | 'en' | 'de' | 'es' | 'pt' | 'it' | 'pl'

interface Language {
	name: LanguageName
	locale: LanguageLocale
}

export type WidgetTypes = 'floating' | 'gallery' | 'product'

export const availableLocales: Language[] = [
	{ name: 'Français', locale: 'fr' },
	{ name: 'English', locale: 'en' },
	{ name: 'Deutsch', locale: 'de' },
	{ name: 'Español', locale: 'es' },
	{ name: 'Português', locale: 'pt' },
	{ name: 'Italian', locale: 'it' },
	{ name: 'Polska', locale: 'pl' }
]

export type CurrencySymbols = '€' | '$' | '£' | 'zł' | 'R$' | 'R' | 'AED' | 'SAR'
export type CurrencyCodes = 'EUR' | 'USD' | 'GBP' | 'PLN' | 'BRL' | 'ZAR' | 'AED' | 'SAR'
export type CurrencyNames =
	| 'Euro (€)'
	| 'Dollar ($)'
	| 'Pound (£)'
	| 'Zloty (zł)'
	| 'Brazilian Real (R$)'
	| 'Rand (R)'
	| 'UAE Dirham (AED)'
	| 'Saudi Riyal (SAR)'

export interface Currency {
	name: CurrencyNames
	code: CurrencyCodes
	symbol: CurrencySymbols
}

// https://github.com/datasets/currency-codes/blob/master/data/codes-all.csv code list
export const availableCurrencies: Currency[] = [
	{ name: 'Euro (€)', code: 'EUR', symbol: '€' },
	{ name: 'Dollar ($)', code: 'USD', symbol: '$' },
	{ name: 'Pound (£)', code: 'GBP', symbol: '£' },
	{ name: 'Zloty (zł)', code: 'PLN', symbol: 'zł' },
	{ name: 'Brazilian Real (R$)', code: 'BRL', symbol: 'R$' },
	{ name: 'Rand (R)', code: 'ZAR', symbol: 'R' },
	{ name: 'UAE Dirham (AED)', code: 'AED', symbol: 'AED' },
	{ name: 'Saudi Riyal (SAR)', code: 'SAR', symbol: 'SAR' }
]

export type ProductsMapping = {
	title: string
	description?: string
	images: Array<string>
	product_link: string
	price?: string
	crossOutPrice?: string
	id: string
	variants: Array<string>
	available?: string
}

export type CatalogFeedType = 'YML' | 'CSV' | 'XML' | 'JSON'
export type CSVSeparator = ';' | ',' | '|'

export type CatalogFeed = {
	link: string
	type: CatalogFeedType
	csvSeparator?: CSVSeparator
	xmlPath?: string
	encoding?: string
}

export type ChannelSubscriptionType = 'pilot' | 'starter' | 'growth' | 'enterprise'
export const channelSubscriptionTypes: Array<ChannelSubscriptionType> = ['pilot', 'starter', 'growth', 'enterprise']

export interface Channel {
	uuid: string
	currency: CurrencyCodes
	locale: LanguageLocale
	name: string
	on_air: boolean
	stream_key: string
	stream_playback?: string
	domains: Domain[]
	picture?: any
	legalSettings: LegalSettings
	users: any[]
	brandLegalEmail?: string
	subscriptionPlan?: SubscriptionPlan
	productsMapping?: ProductsMapping
	catalogFeed?: CatalogFeed
	subscriptionType: ChannelSubscriptionType
}

export type ChannelFormCatalogSettings = {
	catalogFeed: CatalogFeed
	productsMapping: ProductsMapping
}

export type ConditionType = 'contains' | 'doesNotContains' | 'is' | 'isNot' | 'regex'

export const conditionsList = {
	contains: 'Contains',
	doesNotContains: 'Does not contain',
	is: 'Is',
	isNot: 'Is not',
	regex: 'Regex'
}
export interface Condition {
	condition: ConditionType
	value: string
}

export const initCondition: Condition = {
	condition: 'contains',
	value: ''
}

export interface DisplayCondition {
	conditions: Array<Condition>
}

type MinifiedPlayerConfig = {
	displayConditions: Array<DisplayCondition>
}

export type FloatingPlayerConfig = MinifiedPlayerConfig & {
	_type?: 'floatingPlayer'
}

export type PictureInPictureConfig = MinifiedPlayerConfig & {
	_type?: 'pictureInPicture'
	position: WidgetPosition
}

export type FloatingHighlightConfig = MinifiedPlayerConfig & {
	_type?: 'floatingHighlight'
	position: WidgetPosition
	highlightId: string
	watchlistId: string
}

export const initFloatingHighlightConfig: FloatingHighlightConfig = {
	_type: 'floatingHighlight',
	displayConditions: [],
	position: 'bottomRight',
	highlightId: '',
	watchlistId: ''
}

export interface WidgetConfigs {
	enableCookieConsent: boolean
	floatingPlayer?: FloatingPlayerConfig
	pictureInPicture?: PictureInPictureConfig
	floatingPlayerPosition?: WidgetPosition
	floatingHighlight: FloatingHighlightConfig
}

export type WidgetConfig = FloatingPlayerConfig | PictureInPictureConfig | FloatingHighlightConfig

export type WidgetPosition = 'bottomRight' | 'bottomLeft' | 'topLeft' | 'topRight'

export const widgetPositionEntries: SelectEntry<WidgetPosition>[] = [
	{ name: 'Bottom right corner', value: 'bottomRight' },
	{ name: 'Bottom left corner', value: 'bottomLeft' },
	{ name: 'Top left corner', value: 'topLeft' },
	{ name: 'Top right corner', value: 'topRight' }
]

export interface Domain {
	uuid: string
	host: string
	name: string
	channelId?: string
	events?: string[]
	isTest?: boolean
	cartUrl?: string
	hasPip?: boolean
	hasFloatingPlayer?: boolean
	widgetConfigs?: WidgetConfigs
}

export interface DomainsData {
	domains: Domain[]
	previewDomain?: Domain
}

export interface ChannelUserForm {
	name: string
	email: string
	role: 'admin' | 'moderator'
	fromEmail: string
	channelName: string
}

export interface ChannelUser {
	displayName?: string
	email: string
	photoURL?: string
	role: Role
	uid: string
}

export interface ChannelEditPopinFormUser {
	name: string
	email: string
	role: string
	fromEmail: string
	channelName: string
}

export interface ChannelEditPopinFormDomain {
	host: string
	name: string
}

export interface CreateChannel {
	name: string
	domains: Array<ChannelEditPopinFormDomain>
	user: ChannelEditPopinFormUser
	locale: string
	currency: string
	brandLegalEmail: string
	subscriptionType: ChannelSubscriptionType
}

export interface AxiosChannelState {
	data: Channel
	error: any
	loading: boolean
}

// CHANNEL PLAN
export type SubscriptionPlanCreation = {
	contractedServiceDuration: number
	endDate: string // ISO Date string
	startDate: string // ISO Date string
}

export type SubscriptionPlan = {
	uuid: string
	contractedServiceDuration: number
	channelId: string
	createdAt: string // ISO Date string
	startDate: string // ISO Date string
	endDate: string // ISO Date string
}
