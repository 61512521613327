import { css } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { simpleButtonClasses } from './classes'

export type ButtonSize = 'xsmall' | 'small' | 'medium'
export type ButtonVariant = 'plain' | 'border' | 'border-light'

export interface ButtonContainerProps {
	size?: ButtonSize
	variant?: ButtonVariant
	bgColor?: string
	hoverBgColor?: string
	color?: string
	hasIcon?: boolean
}

const handleSize = ({ size, variant, hasIcon }: ButtonContainerProps) => {
	switch (size) {
		case 'xsmall':
			return css`
				font-size: 12px;
				border-radius: 8px;
				padding: 9px 8px 8px 8px;
				.icon {
					left: 10px;
				}
			`
		case 'small':
			return css`
				font-size: 12px;
				border-radius: 8px;
				padding: ${hasIcon && variant === 'plain' ? '9px 10px 9px 0px' : '9px 20px 8px 20px'};
				.icon {
					left: 10px;
				}
			`
		case 'medium':
		default:
			let padding = variant == 'plain' ? '17px 25px 17px 25px' : '16px 24px 16px 24px'

			if (hasIcon) {
				padding = variant == 'plain' ? '19px 25px 19px 0px' : '18px 25px 18px 0px'
			}

			return css`
				font-size: 14px;
				border-radius: 10px;
				padding: ${padding};
			`
	}
}

const handleVariant = ({ variant, bgColor, color, hoverBgColor }: ButtonContainerProps) => {
	switch (variant) {
		case 'border':
			return css`
				border: 1px solid ${bgColor || Theme.colors.grey1};
				color: ${color || Theme.colors.white()};
			`
		case 'plain':
		default:
			return css`
				background-color: ${variant === 'plain' && bgColor ? bgColor : 'transparent'};

				&:hover {
					background-color: ${variant === 'plain' && hoverBgColor ? hoverBgColor : bgColor || 'transparent'};
				}
			`
	}
}

const handleIcon = ({ hasIcon, size }: ButtonContainerProps) => {
	if (hasIcon) {
		switch (size) {
			case 'small':
				return css`
					grid-template-columns: 25px 1fr;
					gap: 5px;
					& ${StartAdornement} {
						padding-left: ${Theme.spacing(2)};
					}
				`
			case 'medium':
				return css`
					grid-template-columns: 45px 1fr;
					gap: 5px;
					& ${StartAdornement} {
						padding-left: ${Theme.spacing(4)};
					}
				`
		}
	}
}

export const ButtonContainer = styled.div<ButtonContainerProps>`
	box-sizing: border-box;
	display: grid;
	grid-template-columns: 1fr;
	justify-content: center;
	align-content: center;
	text-align: center;
	white-space: nowrap;
	cursor: pointer;
	font-family: ${Theme.fontFamilies.Second};
	font-weight: ${Theme.fontWeights.bold};
	color: ${({ color }) => color || Theme.colors.white()};
	position: relative;
	transition: background-color 600ms ${Theme.eases.outExpo} 0ms;

	${handleSize}

	${handleVariant}

	${handleIcon}

	&:active {
		opacity: 0.5;
	}
	&.${simpleButtonClasses.disabled} {
		opacity: 0.4;
		pointer-events: none !important;
		* {
			pointer-events: none !important;
		}
	}

	&.${simpleButtonClasses.fullWidth} {
		width: 100%;
	}
`

export const StartAdornement = styled.div``
