import React from 'react'
import { FlexCopyBox } from './style'

interface WatchlistTagProps {
	watchlistId: string
	isDefault?: boolean
	plain?: boolean
}

const WatchlistTag: React.FC<WatchlistTagProps> = ({ watchlistId, isDefault = false, plain = false }) => {
	const tagContent = `<div class="aploze-widget" data-type="carousel"${
		isDefault ? '' : ` data-watchlist="${watchlistId}"`
	}></div>`

	return (
		<FlexCopyBox
			textToCopy={{
				content: tagContent,
				language: 'handlebars'
			}}
			alignItems="flex-start"
			plain={plain}
			bicolor={true}
		/>
	)
}

export default WatchlistTag
