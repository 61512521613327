import React from 'react'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { Flex, Box } from 'rebass'
import Style from './style'
import Theme from '@styles/theme'
import Cta from '@components/Micro/Button'
import Icon from '@components/Micro/Icon'
import SaveCta from '@components/Micro/Button/SaveCta'
import { confirmModalClasses } from './classes'

interface Props {
	datas: any
	onCancel: () => void
	onConfirm: () => void
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const ConfirmModal: React.FC<Props> = ({ datas, onCancel, onConfirm }) => {
	//

	return (
		<>
			{datas.iconName && (
				<Box
					width={50}
					height={50}
					mb={25}
					p={'15px'}
					backgroundColor={datas.iconColor || Theme.colors.red()}
					css={Style.icon}
				>
					<Icon name={datas.iconName} width="100%" height="100%" fill={Theme.colors.white()} />
				</Box>
			)}
			<Flex flexWrap={'wrap'} justifyContent="center" css={[Style.panelText, Style.title]} mb={2}>
				{datas.title || 'Error'}
			</Flex>
			<Flex flexWrap={'wrap'} justifyContent="center" css={[Style.panelText, Style.description]} mb={30}>
				{datas.description || 'An error has occured ! '}
			</Flex>
			<Flex flexWrap={'wrap'} justifyContent="center">
				{onCancel && (
					<Box>
						<Cta
							className={confirmModalClasses.cancel}
							justifyContent={'flex-start'}
							color={Theme.colors.grey1}
							label={datas.cancelLabel || 'Cancel'}
							onClick={onCancel}
						/>
					</Box>
				)}
				{!datas.noConfirm && onConfirm && (
					<Box ml={10}>
						{datas.confirmLabel === 'Save' ? (
							<SaveCta className={confirmModalClasses.valid} status="hasChange" onClick={onConfirm} />
						) : (
							<Cta
								className={confirmModalClasses.valid}
								justifyContent={'flex-start'}
								color={datas.ctaColor || Theme.colors.red()}
								label={datas.confirmLabel || 'Yes'}
								onClick={onConfirm}
								name={'confirm'}
							/>
						)}
					</Box>
				)}
			</Flex>
		</>
	)
}

export default connect(mapStateToProps, null)(ConfirmModal)
