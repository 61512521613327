import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	cta: css`
		text-align: center;
		align-items: center;
		white-space: nowrap;
		cursor: pointer;
		font-family: ${Theme.fontFamilies.Second};
		font-weight: ${Theme.fontWeights.bold};
		color: ${Theme.colors.white()};
		position: relative;
		transition: background-color 600ms ${Theme.eases.outExpo} 0ms;

		&:hover {
			background: ${Theme.colors.grey2};
		}

		/* sizes */

		&.xsmall {
			font-size: 12px;
			border-radius: 8px;
			padding: 9px 8px 8px 8px;
			.icon {
				left: 10px;
			}
		}

		&.small {
			font-size: 12px;
			border-radius: 8px;
			padding: 9px 20px 8px 20px;
			.icon {
				left: 10px;
			}
		}

		&.medium {
			font-size: 14px;
			border-radius: 10px;
			&.plain {
				padding: 17px 25px 17px 25px;
			}
			&.border,
			&.border-light,
			&.border-selected {
				padding: 16px 24px 16px 24px;
			}
		}

		&:active {
			opacity: 0.5;
		}
	`,

	hasIcon: css`
		&.small {
			&.plain {
				padding: 9px 10px 9px 25px;
			}
		}
		&.medium {
			font-size: 14px;
			border-radius: 10px;
			&.plain {
				padding: 19px 25px 19px 45px;
			}
			&.border,
			&.border-light,
			&.border-selected {
				padding: 18px 24px 18px 44px;
			}
		}
	`,

	icon: css`
		display: inline-block;
		position: absolute;
		left: 20px;
		top: 50%;
		transform: translateY(-50%);
	`,

	iconOnly: css`
		display: inline-block;
		position: absolute;
		left: 50% !important;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
	`,

	isDisabled: css`
		opacity: 0.4;
		pointer-events: none !important;
		* {
			pointer-events: none !important;
		}
	`,
	dropdown: css`
		position: absolute;
		background: ${Theme.colors.grey1};
		color: ${Theme.colors.white()};
		z-index: 100;
		overflow: visible;
		border-radius: 8px;
		top: 65px;
		right: -20px;
		padding: 10px;
		&:before {
			position: absolute;
			width: 10px;
			height: 10px;
			top: -5px;
			right: 35px;
			content: '';
			background-color: ${Theme.colors.grey1};
			transform: translateX(-50%) rotate(45deg);
		}
	`,
	dropdownItem: css`
		font-size: 14px;
		padding: 10px 20px;
		border-radius: 8px;
		cursor: pointer;
		pointer-events: all;

		&:hover {
			background: ${Theme.colors.grey2};
		}
	`,
	mediumPosition: css`
		top: 45px;
		right: -30px;
	`
}
