import Icon from '@components/Micro/Icon'
import Theme from '@styles/theme'
import React, { useState } from 'react'
import { TooltipBox, TooltipWrapper, classes, TooltipDirection } from './style'

interface Props {
	text: string
	bicolor?: boolean
	size?: 'small' | 'medium' | 'large'
	link?: string
	direction?: TooltipDirection
}

const Tooltip: React.FC<Props> = ({ text, bicolor, size = 'medium', link, direction = 'bottom' }) => {
	const [tooltipOpened, setTooltipOpened] = useState<boolean>(false)

	const onMouseEnter = () => {
		if (!tooltipOpened) setTooltipOpened(true)
	}
	const onMouseLeave = () => {
		if (tooltipOpened) setTooltipOpened(false)
	}

	const sizeToPx = (): number => {
		switch (size) {
			case 'small':
				return 8
			case 'medium':
				return 10
			case 'large':
				return 12
		}
	}

	return (
		<TooltipWrapper className={classes.root} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<Icon
				name={bicolor ? 'tooltip_bicolor' : 'tooltip'}
				height={sizeToPx()}
				width={sizeToPx()}
				fill={bicolor ? Theme.colors.grey2 : Theme.colors.white()}
			/>
			{tooltipOpened && (
				<TooltipBox direction={direction}>
					<>
						{text}
						{link && (
							<a href={link} target="_blank" onClick={(e) => e.stopPropagation()}>
								{link}
							</a>
						)}
					</>
				</TooltipBox>
			)}
		</TooltipWrapper>
	)
}

export const tooltipClasses = classes

export default Tooltip
