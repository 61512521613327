import { Channel } from '@customTypes/channel'
import AccessChecker from '@helpers/AccessChecker'
import router, { useRouter } from 'next/router'
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import { useApi } from './apiContext'
import { useAuth } from './authUserContext'
import { useDb } from './dbContext'

interface ChannelsContext {
	channels?: Channel[]
	currentChannel?: Channel
	fetchChannels?: () => void
	setCurrentChannel?: Dispatch<SetStateAction<Channel>>
	fetchChannel?: (update?: boolean) => void
	isAdmin?: boolean
	isSuperAdmin?: boolean
	isModerator?: boolean
	translations?: Record<string, any>
	setChannels?: Dispatch<SetStateAction<Channel[]>>
}

const Context = createContext<ChannelsContext>({})

export const useChannelsContext = () => useContext(Context)

export const ChannelsProvider: React.FC = ({ children }) => {
	const [channels, setChannels] = useState<Channel[]>([])
	const [currentChannel, setCurrentChannel] = useState<Channel>(null)
	const [translations, setTranslations] = useState<Record<string, any>>(null)
	const [channelNotFound, setChannelNotFound] = useState<boolean>(false)
	const { getChannels, getChannel, userAccesses, getChannelTranslations } = useApi()
	const { listenChannel } = useDb()
	const { authUser } = useAuth()

	const channelId = useRouter().query?.channelId as string

	/**
	 * @Effects
	 */
	useEffect(() => {
		if (authUser && channelId) {
			fetchChannel()
			getChannelTranslations(channelId).then((translations) => {
				setTranslations(translations)
			})
		} else {
			setCurrentChannel(null)
		}
		return () => {
			setChannels([])
			setCurrentChannel(null)
		}
	}, [channelId, authUser])

	useEffect(() => {
		let channelListener = null

		if (channelId && authUser) {
			// listen live channel
			channelListener = listenChannel(channelId, (snapshot) => {
				let liveChannel = snapshot.data()
				if (!!liveChannel) {
					setCurrentChannel((current) => {
						const output = { ...current }
						output.on_air = liveChannel.on_air
						return output
					})
				}
			})
		}
		return () => {
			if (channelListener) {
				channelListener()
			}
		}
	}, [channelId, authUser])

	/**
	 * @Methods
	 */
	const fetchChannels = () => {
		getChannels().then((response: any) => {
			if (response?.data) {
				setChannels(response.data.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)))
			}
		})
	}

	const fetchChannel = (update = false) => {
		const newChannel = channels?.find((chan) => chan.uuid === channelId)
		if (newChannel && !update) {
			setCurrentChannel(newChannel)
		} else {
			getChannel(channelId).then((channel: any) => {
				if (channel.errors) {
					setChannelNotFound(true)
				}
				setCurrentChannel(channel)
			})
		}
	}

	useEffect(() => {
		if (channelNotFound) {
			router.push('/404')
		}
	}, [channelNotFound])

	const values = useMemo(
		(): ChannelsContext => ({
			channels,
			currentChannel,
			fetchChannels,
			setCurrentChannel,
			fetchChannel,
			isAdmin: AccessChecker.hasAdminAccess(userAccesses, channelId),
			isSuperAdmin: AccessChecker.hasSuperAdminAccess(userAccesses),
			isModerator:
				!AccessChecker.hasAdminAccess(userAccesses, channelId) &&
				!AccessChecker.hasSuperAdminAccess(userAccesses),
			translations,
			setChannels
		}),
		[channels, currentChannel, userAccesses, channelId, translations]
	)

	return <Context.Provider value={values}>{children}</Context.Provider>
}
