import styled from '@emotion/styled'
import { BaseFlex } from '@styles/Basics'
import Theme from '@styles/theme'

export const CardPickerItemWrapper = styled.div<{ isSelected: boolean }>`
	width: 205px;
	background-color: ${({ isSelected }) => (isSelected ? Theme.colors.grey1 : Theme.colors.blue2)};
	border: 1px solid ${Theme.colors.grey1};
	border-radius: 15px;
	overflow: hidden;
	cursor: pointer;
`

export const CardPickerItemContent = styled(BaseFlex)`
	width: 100%;
	flex-wrap: wrap;
	align-items: center;
	padding: ${Theme.spacing(2)};
`

export const CardPickerItemImage = styled.div`
	width: 20%;
	position: relative;
	transform: translate3d(0, 0, 0);
	border-radius: 10px;
	overflow: hidden;
	transition: opacity 2000ms ${Theme.eases.outExpo} 400ms;
	&:active {
		opacity: 0.5;
		transition: opacity 0ms ${Theme.eases.outExpo} 0ms;
	}
`

export const CardPickerItemText = styled.div`
	margin-left: ${Theme.spacing(2)};
	width: calc(80% - 20px);
`

export const CardPickerItemTitle = styled.div`
	width: 100%;

	font-size: 12px;
	font-weight: bold;
	color: ${Theme.colors.white()};

	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
`
