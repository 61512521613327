import styled from '@emotion/styled'

const PREFIX = 'progress-ring'

export const progressRingClasses = {
	root: PREFIX,
	circle: PREFIX + '__circle'
}

export const ProgressRingCircle = styled.circle`
	transition: 0.1s stroke-dashoffset;
	transform: rotate(-90deg);
	transform-origin: 50% 50%;
	overflow: visible;
`

export const ProgressRingSvg = styled.svg`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	position: relative;
	overflow: visible;
`
