import { css } from '@emotion/react'

export default {
	icon: css`
		display: block;
		pointer-events: none;
	`,

	iconSvg: css`
		display: block;
		width: 100%;
		height: 100%;
	`
}
