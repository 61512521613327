import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { BaseFlex } from '@styles/Basics'

export const PopinContent = styled(BaseFlex)`
	width: 70%;
	flex-wrap: wrap;
	margin-top: ${Theme.spacing(1)};
`

export const PopinHeaderWrapper = styled.div`
	position: relative;
	display: grid;
	grid-template-areas: 'icon content';
	grid-template-columns: auto 1fr;
	grid-gap: ${Theme.spacing(3)};
`

export const PopinHeaderContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`

export const PopinHeaderIcon = styled.div`
	height: 50px;
	width: 50px;
	border-radius: 100%;
	background-color: ${Theme.colors.white()};
	& i {
		padding: 17px;
	}
`

export const PopinHeaderSubTitle = styled(BaseFlex)`
	color: ${Theme.colors.grey3};
	font-size: 14px;
	line-height: 140%;
	width: 100%;
`

export const PopinHeaderTitle = styled(BaseFlex)`
	margin-bottom: ${Theme.spacing(2)};
	color: ${Theme.colors.white()};
	font-weight: bold;
	font-size: 20px;
	width: 100%;
	& span {
		color: ${Theme.colors.green()};
		text-transform: capitalize;
	}
`
