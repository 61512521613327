import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import useAplozeApi from '@helpers/hooks/useAplozeApi'
import { LiveProgram } from 'types/live_program'
import { Product, PublicProductInformation } from 'types/product'
import { Paginated } from 'types/pagination'
import { Branding } from 'types/branding'
import { Event } from '@customTypes/event'
import { StreamingDestination } from '@customTypes/streaming_destinations'
import { ApiRequestResult } from '@customTypes/api'
import { useAuth } from './authUserContext'
import { MediaFile } from '@customTypes/common'
import { Boost, Highlight, HighlightCreation } from '@customTypes/highlight'
import { HighlighAnalyticsRequest, HighlighAnalyticsResponse } from '@customTypes/analytics/highlight_analytics_report'
import {
	AllHighlightsAnalyticsRequest,
	AllHighlightsAnalyticsResponse
} from '@customTypes/analytics/all_highlights_report'
import { SubscriptionPlan } from '@customTypes/channel'
import { OverallAnalyticReport, OverallComsumption } from '@customTypes/analytics/dashboard'
import {
	CreateSynchronisedProductsRequest,
	CreateSynchronisedProductsResponse,
	SearchCatalogProductsRequest,
	SearchCatalogProductsResponse
} from '@customTypes/catalog_product'

const apiContext = createContext({
	//

	// files
	//@ts-ignore
	setFile: async (channelId, data, file = 'image.png') => {},
	//@ts-ignore
	deleteFile: async (channelId: string, fileId: string): Promise<void> => {},

	// upload_replay
	//@ts-ignore
	uploadReplayVideo: async (
		//@ts-ignore
		channelId,
		//@ts-ignore
		eventId,
		//@ts-ignore
		data,
		//@ts-ignore
		file = 'replay_video.mp4',
		//@ts-ignore
		progressCallback: (progress: number) => void
		//@ts-ignore
	): Promise<MediaFile> => {},

	// Upload highlight video
	//@ts-ignore
	uploadHighlightVideo: async (
		//@ts-ignore
		channelId,
		//@ts-ignore
		data,
		//@ts-ignore
		file = 'highlight_video.mp4',
		//@ts-ignore
		progressCallback: (progress: number) => void
		//@ts-ignore
	): Promise<MediaFile> => {},

	// channels
	getChannels: async () => {},
	//@ts-ignore
	getChannel: async (channelId) => {},
	//@ts-ignore
	updateChannel: async (channelId, body) => {},

	// legal settings
	//@ts-ignore
	updateChannelLegalSettings: async (channelId, body) => {},
	//@ts-ignore
	updateEventLegalSettings: async (channelId, eventId, body) => {},

	// channelUsers
	//@ts-ignore
	getChannelUsers: async (channelId) => {},
	//@ts-ignore
	createChannelUser: async (channelId, body) => {},
	//@ts-ignore
	updateChannelUser: async (channelId, userId, body) => {},
	//@ts-ignore
	deleteChannelUser: async (channelId, userId) => {},

	// domains
	//@ts-ignore
	getDomains: async (channelId) => {},
	//@ts-ignore
	createDomain: async (channelId, body) => {},
	//@ts-ignore
	updateDomain: async (channelId, domainId, body: Partial<Domain>): Promise<Domain> => {},
	//@ts-ignore
	deleteDomain: async (channelId, domainId) => {},

	// events
	//@ts-ignore
	getEvents: async (channelId) => {},
	//@ts-ignore
	getEvent: async (channelId, eventId): Promise<Event> => {},
	//@ts-ignore
	setEvent: async (channelId, eventId, body) => {},
	//@ts-ignore
	getPublicEvent: async (eventId) => {},
	//@ts-ignore
	deleteEvent: async (channelId, eventId) => {},
	//@ts-ignore
	duplicateEvent: async (channelId, eventId) => {},
	//@ts-ignore
	getEventLiveStats: async (channelId, eventId, body) => {},
	//@ts-ignore
	restartEventRecordConversion: async (channelId, recordId, body: ResetEventRecordRequest): Promise<void> => {},

	// program
	//@ts-ignore
	getProgram: (channelId, eventId) => {
		return Promise.reject<LiveProgram>('Not init yet')
	},
	//@ts-ignore
	setProgram: async (channelId, eventId, body) => {},

	// products
	//@ts-ignore
	getProducts: (channelId) => {
		return Promise.reject<Paginated<Product>>('Not init yet')
	},
	//@ts-ignore
	getProduct: async (channelId, productId) => {},
	//@ts-ignore
	setProduct: async (channelId, productId, body) => {},
	//@ts-ignore
	deleteProduct: async (channelId, productId) => {},

	// brandings
	//@ts-ignore
	getBrandings: (channelId) => {
		return Promise.reject<Paginated<Branding>>('Not init yet')
	},
	//@ts-ignore
	getBranding: async (channelId, brandingId) => {},
	//@ts-ignore
	setBranding: async (channelId, brandingId, body) => {},
	//@ts-ignore
	deleteBranding: async (channelId, brandingId): Promise<{}> => Promise.resolve({}),

	// live
	//@ts-ignore
	startLive: async (channelId, eventId) => {},
	//@ts-ignore
	resetLive: async (channelId, eventId) => {},

	// timecodes
	//@ts-ignore
	createTimecode: async (eventId, body) => {},

	// messages
	//@ts-ignore
	createMessage: async (body) => {},
	//@ts-ignore
	updateMessage: async (body) => {},

	// users
	//@ts-ignore
	getUser: async (userId) => {},
	//@ts-ignore
	updateUser: async (userId, body) => {},
	//@ts-ignore
	setUserPicture: async (userId, data) => {},
	userAccesses: null,
	//@ts-ignore
	resetPassword: async (body) => {},

	// interactions
	//@ts-ignore
	getPolls: async (channelId, eventId) => {},
	//@ts-ignore
	getPoll: async (channelId, eventId, pollId) => {},
	//@ts-ignore
	createPoll: async (channelId, eventId, body) => {},
	//@ts-ignore
	updatePoll: async (channelId, eventId, pollId, body) => {},
	//@ts-ignore
	startPoll: async (channelId, eventId, pollId) => {},
	//@ts-ignore
	stopPoll: async (channelId, eventId, pollId) => {},
	//@ts-ignore
	deletePoll: async (channelId, eventId, pollId) => {},
	//@ts-ignore
	showPollResults: async (channelId, eventId, pollId, body) => {},
	//@ts-ignore
	getWinningInstants: async (channelId, eventId) => {},
	//@ts-ignore
	getWinningInstant: async (channelId, eventId, wiId) => {},
	//@ts-ignore
	createWinningInstant: async (channelId, eventId, body) => {},
	//@ts-ignore
	updateWinningInstant: async (channelId, eventId, wiId, body) => {},
	//@ts-ignore
	showWinningInstantResults: async (channelId, eventId, wiId, body) => {},
	//@ts-ignore
	startWinningInstant: async (channelId, eventId, wiId) => {},
	//@ts-ignore
	stopWinningInstant: async (channelId, eventId, wiId) => {},
	//@ts-ignore
	deleteWinningInstant: async (channelId, eventId, wiId) => {},
	//@ts-ignore
	getEventAnalytics: async (channelId, eventId, body): Promise<any> => {},

	// streaming destinations
	//@ts-ignore
	streamingDestinations: async (channelId: string): Promise<Array<StreamingDestination>> => Promise.resolve([]),

	//@ts-ignore
	getPublicProductInformation: async (
		//@ts-ignore
		channelId: string,
		//@ts-ignore
		url: string
		//@ts-ignore
	): Promise<ApiRequestResult<PublicProductInformation>> => {},

	// @ts-ignore
	getCsats: async (channelId: string, eventId: string, startAfter?: Date) => {},
	progress: 0,
	// @ts-ignore
	setProgress: (progress: number) => {},
	//@ts-ignore
	getHighlights: async (channelId: string): Promise<Array<Highlight>> => Promise.resolve([]),
	//@ts-ignore
	getHighlight: async (channelId: string, highlight: string): Promise<Highlight> => Promise.resolve({}),
	//@ts-ignore
	createHighlight: async (channelId: string, highlight: HighlightCreation): Promise<Highlight> => Promise.resolve({}),
	//@ts-ignore
	deleteHighlight: async (channelId: string, highlightId: string): Promise<{}> => Promise.resolve({}),
	//@ts-ignore
	disableHighlight: async (channelId: string, highlightId: string, body: any): Promise<{}> => Promise.resolve({}),
	//@ts-ignore
	deleteWatchlist: async (channelId: string, highlightsListId: string): Promise<{}> => Promise.resolve({}),
	//@ts-ignore
	restartHighlightConversion: async (channelId: string, highlightId: string): Promise<void> => {},
	//@ts-ignore
	getHighlightAnalytics: async (
		//@ts-ignore
		channelId: string,
		//@ts-ignore
		highlightId: string,
		//@ts-ignore
		body: HighlighAnalyticsRequest
		//@ts-ignore
	): Promise<ApiRequestResult<HighlighAnalyticsResponse>> => Promise.resolve({}),
	//@ts-ignore
	getAllHighlightsAnalytics: async (
		//@ts-ignore
		channelId: string,
		//@ts-ignore
		body: AllHighlightsAnalyticsRequest & { exportTransactions: boolean }
		//@ts-ignore
	): Promise<ApiRequestResult<AllHighlightsAnalyticsResponse>> => Promise.resolve({}),

	//@ts-ignore
	getHighlightsListAnalytics: async (
		//@ts-ignore
		channelId: string,
		//@ts-ignore
		highlightsListId: string,
		//@ts-ignore
		body: AllHighlightsAnalyticsRequest
		//@ts-ignore
	): Promise<ApiRequestResult<AllHighlightsAnalyticsResponse>> => Promise.resolve({}),

	//@ts-ignore
	getOverallAnalytics: async (
		//@ts-ignore
		channelId: string,
		//@ts-ignore
		body: AllHighlightsAnalyticsRequest
		//@ts-ignore
	): Promise<ApiRequestResult<OverallAnalyticReport>> => Promise.resolve({}),

	//@ts-ignore
	getOverallConsumedMinutes: async (
		//@ts-ignore
		channelId: string
		//@ts-ignore
	): Promise<ApiRequestResult<OverallComsumption>> => Promise.resolve({}),

	//boosts
	deleteBoost: async (
		//@ts-ignore
		channelId: string,
		//@ts-ignore
		highlightId: string,
		//@ts-ignore
		boostId: string
		//@ts-ignore
	): Promise<ApiRequestResult<void>> => Promise.resolve({}),

	createBoost: async (
		//@ts-ignore
		channelId: string,
		//@ts-ignore
		highlightId: string,
		//@ts-ignore
		body: Boost
		//@ts-ignore
	): Promise<ApiRequestResult<Boost>> => Promise.resolve({}),

	//boosts
	updateBoost: async (
		//@ts-ignore
		channelId: string,
		//@ts-ignore
		highlightId: string,
		//@ts-ignore
		boostId: string,
		//@ts-ignore
		body: Boost
		//@ts-ignore
	): Promise<ApiRequestResult<Boost>> => Promise.resolve({}),

	// @ts-ignore
	getChannelTranslations: async (channelId: string) => Promise.resolve({}),
	// @ts-ignore
	createSubscriptionPlan: async (channelId: string, body: any): Promise<ApiRequestResult<SubscriptionPlan>> =>
		// @ts-ignore
		Promise.resolve({}),

	// DEPRECATED
	// @ts-ignore
	searchIAdvizeProducts: async (
		// @ts-ignore
		channelId: string,
		// @ts-ignore
		request: SearchCatalogProductsRequest
	): Promise<ApiRequestResult<SearchCatalogProductsResponse>> =>
		// @ts-ignore
		Promise.resolve({}),
	// @ts-ignore
	searchCatalogFeedProducts: async (
		// @ts-ignore
		channelId: string,
		// @ts-ignore
		request: SearchCatalogProductsRequest
	): Promise<ApiRequestResult<SearchCatalogProductsResponse>> =>
		// @ts-ignore
		Promise.resolve({}),

	// @ts-ignore
	createSynchronisedProducts: async (
		// @ts-ignore
		channelId: string,
		// @ts-ignore
		request: CreateSynchronisedProductsRequest
	): Promise<ApiRequestResult<CreateSynchronisedProductsResponse>> =>
		// @ts-ignore
		Promise.resolve({})
})

export function ApiProvider({ children }) {
	const api = useAplozeApi()
	const [userAccesses, setUserAccesses] = useState(null)
	const [progress, setProgress] = useState<number>(0)
	const { authUser } = useAuth()

	useEffect(() => {
		if (authUser?.uid && !userAccesses) {
			api.getUser(authUser?.uid).then((user: any) => {
				setUserAccesses(user?.accesses)
			})
		}
	}, [authUser, userAccesses])

	const values = useMemo(
		() => ({
			...api,
			userAccesses,
			progress,
			setProgress
		}),
		[userAccesses, progress]
	)
	//@ts-ignore
	return <apiContext.Provider value={values}>{children}</apiContext.Provider>
}

export const useApi = () => useContext(apiContext)
