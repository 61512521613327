export interface ModalsContextState {
	modalOpen: boolean
	modalsQueue: Array<ModalBuild>
	currentModal?: ModalBuild
	modalHistory: Array<ModalsTypeUnion>
}

export interface ModalsContextAction {
	type?: ModalsActionType
	modalType?: ModalsTypeUnion
	metadata?: any
	closeCurrent?: boolean
}

export const modalsInitialContextState: ModalsContextState = {
	modalOpen: false,
	modalsQueue: [],
	modalHistory: []
}

export enum ModalsActionType {
	OPEN_MODAL = 'open_modal',
	CLOSE_MODAL = 'clode_modal',
	OPEN_NEXT_MODAL = 'open_next_modal'
}

export interface ModalProps {
	isOpen: boolean
	onClose: () => void
	children: any
}

export interface ModalBuild {
	modalType: ModalsTypeUnion
	props: any
	Component?: JSX.Element
}

export type ModalsTypeUnion =
	| 'ADD_WATCHLIST'
	| 'ADD_HIGHLIGHTS_TO_WATCHLIST'
	| 'ADD_DISPLAY_CONDITION'
	| 'TEST_DISPLAY_CONDITION'
