import CopyBox from '@components/Micro/Fields/CopyBox'
import styled from '@emotion/styled'

export const FlexCopyBox = styled(CopyBox)`
	width: 100%;

	pre {
		text-align: start;
	}

	& > div {
		display: flex;
		flex-direction: column;
	}
`
