import { css } from '@emotion/react'
import Theme from '@styles/theme'

export default {
	img: css`
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
	`,

	fade: css`
		transition: opacity 2000ms ${Theme.eases.outExpo} 0ms;
	`,

	isLoaded: css`
		opacity: 1;
	`
}
