import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { switchItemClasses } from './classes'
import { classes, SwitchBar, SwitchRoundButton, SwitchWrapper } from './style'
interface Props {
	onClick?: (action) => void
	isActive: boolean
	isDisabled?: boolean
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const SwitchButton: React.FC<Props> = ({ onClick = () => {}, isActive, isDisabled = false }) => {
	const [isOn, setIsOn] = useState(false)

	useEffect(() => {
		setIsOn(isActive)
	}, [isActive])

	const handleOnClick = (e) => {
		if (!isDisabled) onClick(e)
	}

	return (
		<SwitchWrapper onClick={handleOnClick} isDisabled={isDisabled} className={switchItemClasses.root}>
			<SwitchBar isOn={isOn} isDisabled={isDisabled} className={switchItemClasses.bar}>
				<SwitchRoundButton isOn={isOn} className={switchItemClasses.btn} isDisabled={isDisabled} />
			</SwitchBar>
		</SwitchWrapper>
	)
}

export const switchClasses = classes

export default connect(mapStateToProps, mapDispatchToProps)(SwitchButton)
