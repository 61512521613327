import React from 'react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'
import { IconWrapper, PanelTextDescriptionWrapper, PanelTextTitleWrapper, ProgressWrapper } from './style'
import Icon from '@components/Micro/Icon'
import Theme from '@styles/theme'
import Spinner from '@components/Micro/Spinner'
import { useApi } from '@helpers/contexts/apiContext'
import ProgressCircle from '@components/Micro/ProgressCircle'

interface Props {
	datas: any
	dispatch?: (action) => void
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const CallbackModal: React.FC<Props> = ({ datas }) => {
	const { progress } = useApi()

	const backgroundColor = () => {
		if (datas?.loading || datas?.hasProgress) {
			return Theme.colors.grey1
		}
		return datas.success ? Theme.colors.green() : Theme.colors.red()
	}

	const getIconName = () => {
		if (datas.iconName) return datas.iconName
		if (datas.hasProgress) return 'upload_cloud'
		return datas.success ? 'check_round' : 'close'
	}

	const reduceIconWidth = (): boolean => {
		return ['check_round', 'close'].some((iconName) => iconName === getIconName())
	}

	return (
		<>
			<IconWrapper backgroundColor={backgroundColor()} reduceIconWidth={reduceIconWidth()}>
				{!datas.loading && (
					<Icon
						name={getIconName()}
						width={datas?.hasProgress ? 40 : '100%'}
						height={datas?.hasProgress ? 40 : '100%'}
						fill={Theme.colors.white()}
					/>
				)}
				{datas.loading && <Spinner />}
				{datas?.hasProgress && (
					<ProgressWrapper>
						<ProgressCircle percent={progress} />
					</ProgressWrapper>
				)}
			</IconWrapper>
			<PanelTextTitleWrapper justifyContent="center">{datas.title || 'Error'}</PanelTextTitleWrapper>
			<PanelTextDescriptionWrapper justifyContent="center">
				{datas.description || 'An error has occured ! '}
			</PanelTextDescriptionWrapper>
		</>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackModal)
