import React, { useState } from 'react'
import { Box } from 'rebass'
import Style from './style'
import { css } from '@emotion/react'

const Picture: React.FC<{
	sizes?: any
	media?: any
	fit?: string
	loading?: any
	onLoad?: any
	fade?: boolean
	source?: any
}> = ({
	media,
	fit = 'cover',
	onLoad,
	loading = 'lazy', // eager or lazy
	fade = true,
	source
}) => {
	//

	const [isLoaded, setIsLoaded] = useState(false)

	const loadHandler = () => {
		if (onLoad) {
			onLoad()
		}
		setIsLoaded(true)
	}

	return (
		<Box as="picture">
			<Box
				as="img"
				src={source}
				css={[
					Style.img,
					fade ? Style.fade : null,
					isLoaded ? Style.isLoaded : null,
					css`
						object-fit: ${fit};
						object-position: center;
					`
				]}
				loading={loading}
				onLoad={loadHandler}
				alt={media.alt}
				className="media"
			/>
		</Box>
	)
}

export default Picture
