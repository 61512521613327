import { IconSettings, Link } from '@customTypes/common'
import React from 'react'
import NextLink from 'next/link'
import IconAndText from '../IconAndText'
import { CategoryHeaderWrapper, LinkWrapper, Title } from './style'
import { categoryHeaderClasses } from './classes'
import { TooltipDirection } from '@components/Micro/Tooltip/style'
import { IconUnion } from '@components/Micro/Icon'

interface Props {
	iconName?: IconUnion
	tooltip?: string
	tooltipLink?: string
	tooltipDirection?: TooltipDirection
	title: string
	noMt?: boolean
	noMb?: boolean
	subtitle?: string
	noWidth?: boolean
	fontSize?: string
	iconSettings?: IconSettings
	link?: Link
}

const CategoryHeader: React.FC<Props> = ({
	iconName,
	tooltip,
	tooltipLink,
	tooltipDirection,
	title,
	noMt,
	noMb,
	subtitle,
	noWidth,
	fontSize,
	iconSettings,
	link
}) => {
	return (
		<CategoryHeaderWrapper className={categoryHeaderClasses.root} noWidth={noWidth}>
			<Title className={categoryHeaderClasses.title} noMt={noMt} noMb={noMb}>
				<IconAndText
					iconName={iconName}
					tooltip={tooltip}
					title={title}
					subtitle={subtitle}
					fontSize={fontSize}
					iconSettings={iconSettings}
					tooltipLink={tooltipLink}
					tooltipDirection={tooltipDirection}
				/>
			</Title>
			{link && (
				<LinkWrapper>
					<NextLink href={link.url} passHref>
						<a target={link.target}>{link.label}</a>
					</NextLink>
				</LinkWrapper>
			)}
		</CategoryHeaderWrapper>
	)
}

export default CategoryHeader
