import React from 'react'
import SimpleCta from '@components/Micro/Button/SimpleCta'
import { SaveStatus } from '@customTypes/common'
import Theme from '@styles/theme'

interface SaveCtaProps {
	status?: SaveStatus
	onClick?: (data?: any) => void
	label?: string
	savingLabel?: string
	disabled?: boolean
	className?: string
	alwaysSave?: boolean
}

const SaveCta: React.FC<SaveCtaProps> = ({
	status = 'noChange',
	onClick,
	savingLabel,
	label,
	disabled,
	alwaysSave,
	...props
}) => {
	const statusBgColor: Record<SaveStatus, { bgColor: string; hoverBgColor?: string }> = {
		noChange: { bgColor: Theme.colors.grey1, hoverBgColor: Theme.colors.grey2 },
		saving: { bgColor: Theme.colors.orange() },
		hasChange: { bgColor: Theme.colors.green() }
	}

	const onSaveClick = () => {
		if (!alwaysSave && status === 'noChange') return

		return onClick()
	}

	return (
		<SimpleCta {...props} {...statusBgColor[status]} disabled={disabled} onClick={onSaveClick}>
			{status === 'saving' ? savingLabel || label || 'Saving...' : label || 'Save'}
		</SimpleCta>
	)
}

export default SaveCta
