import WatchlistTag from '@components/Pages/Channel/Highlights/Watchlists/Common/WatchlistTag'
import { ConfirmPopinProps } from '@components/Popins/Confirm'
import Theme from '@styles/theme'

export enum ConfirmPopinTypes {
	UNSAVED_CHANGES = 'unsavedChanges',
	INVALID_UNSAVED_CHANGES = 'invalidUnsavedChanges',
	START_LIVE = 'startLive',
	DUPLICATE_EVENT = 'duplicateEvent',
	EVENT_FINISHED = 'eventFinished',
	USE_PRODUCT_INFORMATION = 'useProductInformation',
	UPLOAD_EDIT_REPLAY_VIDEO = 'uploadEditReplayVideo',
	WATCHLIST_CREATED = 'watchlistCreated',
	DISABLE_HIGHLIGHT = 'disableHighlight',
	DELETE_CHANNEL = 'deleteChannel'
}

export interface ConfirmPopinMetadata {
	watchlistId?: string
}

export const getConfirmPopinProps = (
	type: string,
	confirmCallback?: () => void,
	cancelCallback?: () => void,
	onCloseCallback?: () => void,
	metadata?: ConfirmPopinMetadata
): ConfirmPopinProps => {
	switch (type) {
		case ConfirmPopinTypes.UNSAVED_CHANGES:
			return {
				icon: 'warning',
				onConfirm: confirmCallback,
				onCancel: cancelCallback,
				title: 'Content edited',
				description: 'You have some unsaved changes, do you want to save them before leaving?',
				ctaProps: {
					confirm: {
						label: 'Save',
						color: Theme.colors.green()
					},
					cancel: {
						label: 'No'
					}
				}
			}
		case ConfirmPopinTypes.INVALID_UNSAVED_CHANGES:
			return {
				icon: 'warning',
				onConfirm: confirmCallback,
				onCancel: cancelCallback,
				title: 'Content edited',
				description: `Your changes won't be saved if you leave, do you wish to leave?`,
				ctaProps: {
					confirm: {
						label: 'Confirm',
						color: Theme.colors.green()
					},
					cancel: {
						label: 'Cancel'
					}
				}
			}
		case ConfirmPopinTypes.START_LIVE:
			return {
				icon: 'warning',
				onConfirm: confirmCallback,
				onCancel: cancelCallback,
				title: 'Start live',
				description: 'You are about to start your live and send all the SMS to subscribers.',
				ctaProps: {
					confirm: {
						label: 'Confirm',
						color: Theme.colors.green()
					},
					cancel: {
						label: 'Cancel'
					}
				}
			}
		case ConfirmPopinTypes.USE_PRODUCT_INFORMATION:
			return {
				icon: 'content',
				iconBackgroundColor: Theme.colors.white(),
				iconColor: Theme.colors.black(),
				onConfirm: confirmCallback,
				onCancel: cancelCallback,
				title: 'Update your product information',
				description:
					'We fetched some product data based on the url you provided. Do you want to use it and override the current product information ?',
				ctaProps: {
					confirm: {
						label: 'Yes',
						color: Theme.colors.green()
					},
					cancel: {
						label: 'Cancel'
					}
				}
			}

		case ConfirmPopinTypes.DUPLICATE_EVENT:
			return {
				icon: 'copy',
				iconBackgroundColor: Theme.colors.white(),
				iconColor: Theme.colors.black(),
				onConfirm: confirmCallback,
				onCancel: cancelCallback,
				title: 'Duplicate this event',
				description: 'You event will be duplicated.',
				ctaProps: {
					confirm: {
						label: 'Confirm',
						color: Theme.colors.green()
					},
					cancel: {
						label: 'Cancel'
					}
				}
			}
		case ConfirmPopinTypes.EVENT_FINISHED:
			return {
				icon: 'check_round',
				title: 'Your live is over',
				description: 'Congratulations, your live is over. \nYou can have access to the replay now.',
				iconBackgroundColor: Theme.colors.green(),
				onClose: onCloseCallback
			}
		case ConfirmPopinTypes.UPLOAD_EDIT_REPLAY_VIDEO:
			return {
				icon: 'upload_cloud',
				iconBackgroundColor: Theme.colors.grey1,
				title: 'Replace replay video',
				description: 'You can upload your own video to replace the original replay video.',
				onConfirm: confirmCallback,
				ctaProps: {
					confirm: {
						label: 'Upload video',
						color: Theme.colors.grey1
					}
				},
				warningProps: {
					icon: 'warning',
					subTitle: 'The action history will be deleted after you upload your new video.'
				}
			}
		case ConfirmPopinTypes.WATCHLIST_CREATED:
			return {
				icon: 'check_round',
				iconBackgroundColor: Theme.colors.green(),
				title: 'Playlist created',
				onClose: onCloseCallback,
				onCancel: cancelCallback,
				description: 'You can display it on your website with the snippet below',
				children: <WatchlistTag watchlistId={metadata?.watchlistId} />
			}
		case ConfirmPopinTypes.DISABLE_HIGHLIGHT:
			return {
				icon: 'warning',
				iconBackgroundColor: Theme.colors.red(),
				onConfirm: confirmCallback,
				onCancel: cancelCallback,
				title: 'Disable Highlight',
				description:
					'Disabling this highlight will also disable it in all highlight playlists where it is present. Are you sure you want to proceed?',
				ctaProps: {
					confirm: {
						label: 'Confirm',
						color: Theme.colors.green()
					},
					cancel: {
						label: 'Cancel'
					}
				}
			}
		case ConfirmPopinTypes.DELETE_CHANNEL:
			return {
				icon: 'warning',
				iconBackgroundColor: Theme.colors.red(),
				onConfirm: confirmCallback,
				onCancel: cancelCallback,
				title: 'Delete channel',
				description: 'Are you sure you want to delete this channel ?',
				ctaProps: {
					confirm: {
						label: 'Delete',
						color: Theme.colors.red()
					},
					cancel: {
						label: 'Cancel'
					}
				}
			}
		default:
			break
	}
}
