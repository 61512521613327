import React, { useState, useEffect } from 'react'
import {
	AbsoluteContainer,
	CardPickContainer,
	EmptyStateWrapper,
	Header,
	HeaderSubTitle,
	HeaderTitle,
	ItemsCount,
	ItemsWrapper
} from './style'
import SearchInput from '@components/Micro/Fields/SearchInput'
import { buildItemsPages } from './logics'
import EmptyState from '@components/Templates/EmptyState'
import CardPickerItem, { CardPickerItemData } from './CardPickerItem'
import Paginator from '@components/Micro/Paginator'
import Spinner from '@components/Micro/Spinner'
import { IconUnion } from '@components/Micro/Icon'

interface EmptyStateProps {
	title: string
	description: string
	iconName: IconUnion
}

interface CardPickerProps {
	perPage?: number
	description?: string
	title: string
	value: Array<string>
	items: Array<CardPickerItemData>
	onChange: (selected: Array<string>) => void
	loading?: boolean
	className?: string
	emptyStateProps: EmptyStateProps
	singleSelect?: boolean
}

const CardPicker: React.FC<CardPickerProps> = ({
	value,
	perPage = 6,
	onChange,
	items,
	description,
	title,
	loading,
	className,
	emptyStateProps,
	singleSelect
}) => {
	/**
	 * @States
	 */
	const [currentPage, setCurrentPage] = useState<number>(0)
	const [pages, setPages] = useState<Array<Array<CardPickerItemData>>>([])
	const [queryString, setQueryString] = useState<string>('')

	const handleItemClick = (itemValue) => {
		let newValue = [...value]
		const index = newValue.indexOf(itemValue)
		if (index >= 0) {
			newValue.splice(index, 1)
		} else {
			if (singleSelect) newValue = [itemValue]
			else newValue.push(itemValue)
		}

		onChange(newValue)
	}

	useEffect(() => {
		if (items?.length) {
			const pagesData = buildItemsPages(items, queryString, perPage)
			setPages(pagesData.itemsPages)
		}
	}, [items, queryString])

	return (
		<CardPickContainer gap={pages.length ? 'unset' : '30px'} className={className}>
			<Header>
				<HeaderTitle>
					{title}
					{value.length !== 0 && <ItemsCount>({value.length} selected)</ItemsCount>}
				</HeaderTitle>
				<HeaderSubTitle>{description}</HeaderSubTitle>
				<AbsoluteContainer>
					<SearchInput placeholder={description} onChange={setQueryString} forceClose />
				</AbsoluteContainer>
			</Header>
			{!pages[currentPage]?.length && (
				<EmptyStateWrapper>
					{!loading && <EmptyState {...emptyStateProps} />}
					{loading && <Spinner height={30} width={30} />}
				</EmptyStateWrapper>
			)}
			<ItemsWrapper>
				{pages[currentPage] &&
					pages[currentPage].map((item) => (
						<CardPickerItem
							key={`card-picker-item-${item.value}`}
							item={item}
							onClick={() => handleItemClick(item.value)}
							selected={value.indexOf(item.value) >= 0}
						/>
					))}
			</ItemsWrapper>
			<Paginator
				pagesCount={pages.length}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				btnsCount={6}
			/>
		</CardPickContainer>
	)
}

export default CardPicker
