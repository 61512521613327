import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { BaseFlex } from '@styles/Basics'

export const IconWrapper = styled.div<{ backgroundColor; reduceIconWidth? }>`
	width: ${({ reduceIconWidth }) => (reduceIconWidth ? '20px' : '50px')};
	height: ${({ reduceIconWidth }) => (reduceIconWidth ? '20px' : '50px')};
	margin-bottom: ${Theme.spacing(5)};
	padding: ${Theme.spacing(3)};
	border-radius: 100%;
	background-color: ${({ backgroundColor }) => backgroundColor || 'unset'};
	position: relative;
	overflow: visible;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const PanelTextTitleWrapper = styled(BaseFlex)`
	text-align: center;
	color: ${Theme.colors.white()};
	font-size: 20px;
	font-weight: bold;
	margin-bottom: ${Theme.spacing(2)};
`

export const PanelTextDescriptionWrapper = styled(BaseFlex)`
	text-align: center;
	color: ${Theme.colors.grey3};
	width: 50%;
	font-size: 14px;
	line-height: 24px;
	margin-bottom: ${Theme.spacing(3)};
`

export const ResultBarWrapper = styled.div`
	width: 80%;
	margin: ${Theme.spacing(4)};
`

export const ProgressWrapper = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;
`
