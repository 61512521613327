import Config from '@styles/default/config/index'
import Merge from 'deepmerge'

import PlusJakartaSans from '@assets/fonts/PlusJakartaSans-Regular.woff'
import PlusJakartaSans2 from '@assets/fonts/PlusJakartaSans-Regular.woff2'

import PlusJakartaSansBold from '@assets/fonts/PlusJakartaSans-Bold.woff'
import PlusJakartaSansBold2 from '@assets/fonts/PlusJakartaSans-Bold.woff2'

import { css } from '@emotion/react'

const Theme = {
	fontFamilies: {
		Second: 'PlusJakartaSans',
		Main: 'PlusJakartaSans'
	},

	fonts: [
		{
			name: 'PlusJakartaSans',
			weight: Config.fontWeights.regular,
			style: Config.fontStyles.normal,
			woff: PlusJakartaSans,
			woff2: PlusJakartaSans2
		},
		{
			name: 'PlusJakartaSans',
			weight: Config.fontWeights.bold,
			style: Config.fontStyles.normal,
			woff: PlusJakartaSansBold,
			woff2: PlusJakartaSansBold2
		}
	],

	text: {
		h2: {
			fontSize: '20px',
			lineHeight: '28px',
			fontWeight: 700,
			color: 'white'
		},
		h3: {
			fontSize: '16px',
			lineHeight: '20px',
			fontWeight: '700',
			color: 'white'
		},
		'content-header': {
			fontSize: '24px',
			lineHeight: '28px',
			fontWeight: 700,
			color: '#fff'
		},
		'thin-lg': {
			fontSize: '14px',
			lineHeight: '24px',
			fontWeight: 400,
			color: '#9F9F9F'
		},
		thin: {
			fontSize: '12px',
			lineHeight: '14px',
			fontWeight: 400,
			color: '#9F9F9F'
		},
		'bold-dark-grey': {
			fontSize: '12px',
			lineHeight: '14px',
			fontWeight: 700,
			color: '#4F4F58'
		},
		thiner: {
			fontSize: '10px',
			lineHeight: '12px',
			fontWeight: 400,
			color: '#9F9F9F'
		},
		thinBlack: {
			fontSize: '12px',
			lineHeight: '14px',
			fontWeight: 400,
			color: 'black'
		},
		thinBlackBold: {
			fontSize: '12px',
			lineHeight: '14px',
			fontWeight: 'bold',
			color: 'black'
		},
		regular: {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 400,
			color: 'white'
		},
		'regular-grey': {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 400,
			color: '#4F4F58'
		},
		'regular-small': {
			fontSize: '12px',
			lineHeight: '14px',
			fontWeight: 400,
			color: 'white'
		},
		bold: {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 700,
			color: 'white'
		},
		'bold-grey': {
			fontSize: '14px',
			lineHeight: '18px',
			fontWeight: 700,
			color: '#9F9F9F'
		},
		'bold-title': {
			fontSize: '20px',
			lineHeight: '24px',
			fontWeight: 700,
			color: 'white'
		},
		'bold-xs': {
			fontSize: '10px',
			lineHeight: '12px',
			fontWeight: 700,
			color: 'white'
		},
		'bold-xxs': {
			fontSize: '8px',
			lineHeight: '10px',
			fontWeight: 700,
			color: 'white'
		},
		'bold-xxxs': {
			fontSize: '7px',
			lineHeight: '9px',
			fontWeight: 700,
			color: 'white'
		},
		'bold-xs-2': {
			fontSize: '12px',
			lineHeight: '14px',
			fontWeight: 700,
			color: 'white'
		},
		'bold-s': {
			fontSize: '12px',
			lineHeight: '14px',
			fontWeight: 700,
			color: 'white'
		},
		'prompter-title': {
			fontSize: '44px',
			lineHeight: '40px',
			fontWeight: 700,
			color: '#FFFF06'
		},
		'prompter-content': {
			fontSize: '70px',
			lineHeight: '76px',
			fontWeight: 400,
			color: 'white'
		},
		'prompter-content-bold': {
			fontSize: '70px',
			lineHeight: '76px',
			fontWeight: 700,
			color: 'white'
		},
		'prompter-chat-title': {
			fontSize: '30px',
			lineHeight: '35px',
			fontWeight: 'bold',
			color: '#FFFF06'
		},
		'prompter-chat-message': {
			fontSize: '30px',
			lineHeight: '35px',
			fontWeight: 'bold',
			color: 'white'
		},
		label: {
			fontSize: '11px',
			fontWeight: 'bold',
			color: 'white',
			lineHeight: '12px'
		},
		'input-label': {
			fontSize: '12px',
			fontWeight: 'bold',
			color: 'white',
			lineHeight: '12px'
		},
		'table-label': {
			fontSize: '10px',
			fontWeight: 'bold',
			color: 'white',
			lineHeight: '12px'
		},
		'label-option': {
			fontSize: '14px',
			fontWeight: 400,
			lineHeight: '24px'
		}
	},

	colors: {
		text_main: `#000`,
		text_second: `#333`,
		background: `#FEF8ED`,
		cta_main: `#FEF8ED`,
		cta_second: `#666`,
		blue1: `#0A0A13`,
		blue2: `#1A1A23`,
		grey1: `#2E2E36`,
		grey2: `#4F4F58`,
		grey3: `#9F9F9F`,
		yellow: `#D5A300`,
		violet: `#9487f0`,
		lightBlue: `#7bd2f5`,
		prompterGrey: `#d9d9d9`,
		green: (a = 1) => {
			return `rgba(0, 213, 163, ${a})`
		},
		red: (a = 1) => {
			return `rgba(213, 0, 50, ${a})`
		},
		orange: (a = 1) => {
			return `rgba(255, 165, 0, ${a})`
		},
		graph: {
			blue: `#0032D5`,
			blue2: `#204CDA`,
			blue3: `#4065E0`,
			blue4: `#607FE5`,
			red: `#D50032`,
			yellow: `#D5A300`,
			green: `#00D5A3`
		},
		library: {
			grey2: '#D9D9D9',
			grey3: '#9F9F9F',
			grey4: '#F1F1F1'
		}
	},

	breakPoints: ['950px', '1220px', '1441px', '1600px'],

	grid: {
		width: [0.85, 0.9],
		maxWidth: [1044]
	},

	zIndices: {
		stories: 1,
		livestream: 1,
		header: 2,
		sideBar: 4,
		bottomBar: 2,
		liveProducts: 4,
		modal: 100,
		panels: 200,
		popin: 210,
		pip: 900,
		unmute: 900
	},

	scrollbar: {
		grey3: css`
			&::-webkit-scrollbar-thumb {
				background-color: #9f9f9f !important;
				width: 8px;
				border-radius: 10px;
				border: 2px solid white;
			}
			&::-webkit-scrollbar {
				background-color: transparent;
				width: 10px;
			}
		`
	},

	spacingInt: (multiple) => multiple * 5,
	spacing: (multiple) => `${multiple * 5}px`,
	multiSpacing: (topBottom, leftRight) =>
		`${topBottom * 5}px ${leftRight * 5}px ${topBottom * 5}px ${leftRight * 5}px`
}

const FinalConfig = Merge(Config, Theme)

// Parse breakpoints to integer

for (const b of FinalConfig.breakPoints) {
	FinalConfig.breakPointsInt.push(parseInt(b))
	// parse Breakpoints to medias
}

// Create css breakpoints medias

FinalConfig.media = (bp) => {
	return `screen and (min-width: ${FinalConfig.breakPoints[bp]})`
}

FinalConfig.mediaMin = (bp) => {
	return `screen and (min-width: ${FinalConfig.breakPoints[bp]})`
}

FinalConfig.mediaMax = (bp) => {
	return `screen and (max-width: ${FinalConfig.breakPoints[bp]})`
}

// set css medias

// screen and (max-width: ${Theme.breakPoints[0]})

export type Variant = keyof typeof Theme.text

export default FinalConfig
