import React, { Dispatch, SetStateAction } from 'react'
import { Flex, Box } from 'rebass'
import Style from './style'
import Theme from '@styles/theme'
import Icon from '@components/Micro/Icon'

interface Props {
	pagesCount: number
	currentPage: number
	setCurrentPage: Dispatch<SetStateAction<number>>
	btnsCount?: number
	isLoading?: boolean
}

const Paginator: React.FC<Props> = ({ pagesCount, currentPage, setCurrentPage, btnsCount = 7, isLoading }) => {
	const btnsCountAfterCurrent = Math.ceil(btnsCount / 2)

	const showFirstButtons = (index) => currentPage <= btnsCount - btnsCountAfterCurrent && index < btnsCount
	const showMiddleButtons = (index) =>
		currentPage > btnsCount - btnsCountAfterCurrent &&
		index >= currentPage + btnsCountAfterCurrent - btnsCount &&
		index < currentPage + btnsCountAfterCurrent
	const showLastButtons = (index) =>
		index >= pagesCount - btnsCount && currentPage < pagesCount && currentPage >= pagesCount - btnsCountAfterCurrent

	const showPaginationBtn = (index) => {
		return showFirstButtons(index) || showMiddleButtons(index) || showLastButtons(index)
	}
	return (
		<>
			{pagesCount > 1 && (
				<Flex mt={20} width={1}>
					{pagesCount > btnsCount && (
						<Flex
							mr={10}
							backgroundColor={currentPage === 0 ? Theme.colors.blue1 : Theme.colors.blue2}
							css={[Style.pageBtn, currentPage === 0 ? Style.inactiveBtn : null]}
							onClick={() => {
								if (currentPage !== 0 && !isLoading) setCurrentPage(currentPage - 1)
							}}
							justifyContent="center"
						>
							<Icon width={10} height={10} name="chevron_left" fill={Theme.colors.white()} />
						</Flex>
					)}
					{pagesCount &&
						new Array(pagesCount).fill(0).map((_, index) => {
							if (showPaginationBtn(index)) {
								return (
									<Box
										key={`page-${index}`}
										mr={10}
										backgroundColor={
											index === currentPage ? Theme.colors.white() : Theme.colors.blue2
										}
										color={index === currentPage ? Theme.colors.blue2 : Theme.colors.white()}
										css={Style.pageBtn}
										onClick={() => {
											!isLoading && setCurrentPage(index)
										}}
									>
										{index + 1}
									</Box>
								)
							}
							return null
						})}
					{pagesCount > btnsCount && (
						<Flex
							backgroundColor={currentPage === pagesCount - 1 ? Theme.colors.blue1 : Theme.colors.blue2}
							css={[Style.pageBtn, currentPage === pagesCount - 1 ? Style.inactiveBtn : null]}
							onClick={() => {
								if (currentPage !== pagesCount - 1 && !isLoading) setCurrentPage(currentPage + 1)
							}}
							justifyContent="center"
						>
							<Icon width={10} height={10} name="chevron" fill={Theme.colors.white()} />
						</Flex>
					)}
				</Flex>
			)}
		</>
	)
}

export default Paginator
