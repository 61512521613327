import { Branding } from '@customTypes/branding'
import { useApi } from '@helpers/contexts/apiContext'
import router from 'next/router'
import { useEffect, useMemo, useState } from 'react'

interface BrandingsHookInterface {
	brandings: Branding[]
	defaultBranding: Branding
}

const useBrandings = (): BrandingsHookInterface => {
	const channelId = router.query.channelId as string
	const [brandings, setBrandings] = useState<Array<Branding>>([])
	const { getBrandings } = useApi()

	const defaultBranding = brandings?.length && (brandings?.find((b) => b.name === 'Default') || brandings[0])

	useEffect(() => {
		if (channelId)
			getBrandings(channelId).then((response: any) => {
				if (response.count) {
					setBrandings([...response.data])
				}
			})
	}, [channelId])

	return useMemo(
		(): BrandingsHookInterface => ({
			brandings,
			defaultBranding
		}),
		[brandings, channelId]
	)
}

export default useBrandings
