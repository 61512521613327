import React, { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'

const PORTAL_ID = 'aplz-portal'

export const Portal: React.FC = (props) => {
	const el = useRef(document.createElement('div'))
	useEffect(() => {
		const portal = document.getElementById(PORTAL_ID)
		portal.appendChild(el.current)

		return () => {
			portal.removeChild(el.current)
		}
	}, [props.children])

	return ReactDOM.createPortal(props.children, el.current)
}

export const PortalDiv = () => <div id={PORTAL_ID}></div>
