import Firebase from '@helpers/firebase'
import { QuerySnapshot, FirestoreDataConverter, QueryDocumentSnapshot } from '@firebase/firestore-types'
import { MessagesFilter, FirebaseMessage } from '@customTypes/Message'
import { OrderByDirection } from '@google-cloud/firestore'
import { FirebaseBannedViewer } from '@customTypes/user'
import { captureException } from '@sentry/nextjs'
import { TimecodeDocument } from '@customTypes/timecode'
import { ListenedHighlightStatus } from '@customTypes/highlight'

export default function useFirebaseDb() {
	//
	const db = Firebase.firestore()

	const listenChannel = (channelId, handler) => {
		//@ts-ignore
		const channelDoc = db.collection('channels').doc(channelId)
		return channelDoc.onSnapshot(
			(snapshot) => {
				if (snapshot) handler(snapshot)
			},
			(err) => {
				captureException(err)
			}
		)
	}

	const listenEvent = (eventId, handler) => {
		const eventDoc = db.collection('events').doc(eventId)
		return eventDoc.onSnapshot(
			(snapshot) => {
				handler(snapshot)
			},
			(err) => {
				captureException(err)
			}
		)
	}

	const listenEvents = (channelId, handler) => {
		const eventDoc = db.collection('events').where('channel', '==', db.collection('channels').doc(channelId))
		return eventDoc.onSnapshot(
			(snapshot) => {
				if (snapshot) handler(snapshot)
			},
			(err) => {
				captureException(err)
			}
		)
	}

	const listenTimecodes = (eventId, handler) => {
		//@ts-ignore
		const timecodesDocs = db
			.collection('timecodes')
			.where('event', '==', db.collection('events').doc(eventId))
			.orderBy('created_at', 'asc')
		return timecodesDocs.onSnapshot(
			(snapshot) => {
				if (snapshot) handler(snapshot)
			},
			(err) => {
				captureException(err)
			}
		)
	}

	const getTimecodes = (eventId: string): Promise<QuerySnapshot<TimecodeDocument>> => {
		return db
			.collection('timecodes')
			.withConverter(timecodeConverter)
			.where('event', '==', db.collection('events').doc(eventId))
			.orderBy('created_at', 'asc')
			.get()
	}

	const getMessages = (eventId) => {
		//@ts-ignore
		return db
			.collection('messages')
			.where('event', '==', db.collection('events').doc(eventId))
			.orderBy('created_at', 'desc')
			.get()
	}

	const listenPolls = (eventId: string, channelId: string, handler: Function) => {
		const eventDoc = db.collection('events').doc(eventId)
		const channelDoc = db.collection('channels').doc(channelId)
		const pollsDocs = db
			.collection('polls')
			.where('channel', '==', channelDoc)
			.where('event', '==', eventDoc)
			.orderBy('startTime', 'desc')

		return pollsDocs.onSnapshot(
			(snapshot) => {
				if (snapshot) handler(snapshot)
			},
			(err) => {
				captureException(err)
			}
		)
	}

	const listenWinningInstants = (eventId: string, channelId: string, handler: Function) => {
		const eventDoc = db.collection('events').doc(eventId)
		const channelDoc = db.collection('channels').doc(channelId)
		const winningInstantsDocs = db
			.collection('winning_instants')
			.where('channel', '==', channelDoc)
			.where('event', '==', eventDoc)
			.orderBy('startTime', 'desc')

		return winningInstantsDocs.onSnapshot(
			(snapshot) => {
				if (snapshot) handler(snapshot)
			},
			(err) => {
				captureException(err)
			}
		)
	}

	const listenMessages = (
		eventId: string,
		handler: Function,
		filter: MessagesFilter,
		order: OrderByDirection = 'desc'
	) => {
		const messagesDocs = db
			.collection('messages')
			.withConverter(messagesConverter)
			.where('event', '==', db.collection('events').doc(eventId))
			.where(filter.name, '==', filter.value)
			.orderBy('created_at', order)

		return messagesDocs.onSnapshot(
			(snapshot) => {
				if (snapshot) handler(snapshot)
			},
			(err) => {
				captureException(err)
			}
		)
	}

	const listenPrompterMessages = (eventId: string, handler: Function) => {
		return listenMessages(eventId, handler, { name: 'is_shown', value: true })
	}

	const listenModeratedMessages = (eventId: string, handler: Function) => {
		return listenMessages(eventId, handler, { name: 'is_moderated', value: true }, 'asc')
	}

	const listenModeratingMessages = (eventId: string, handler: Function) => {
		return listenMessages(eventId, handler, { name: 'is_answering', value: true }, 'asc')
	}

	const listenPinnedMessages = (eventId: string, handler: Function) => {
		return listenMessages(eventId, handler, { name: 'is_pinned', value: true }, 'asc')
	}

	const listenBannedUsers = (eventId: string, handler: Function) => {
		const bannedUsersDoc = db
			.collection('blacklist')
			.withConverter(bannedViewerConverter)
			.where('event', '==', db.collection('events').doc(eventId))
		return bannedUsersDoc.onSnapshot(
			(snapshot) => handler(snapshot),
			(err) => {
				captureException(err)
			}
		)
	}

	const listenFrontendNotifications = (channelId: string, eventId: string, handler: Function) => {
		const query = db
			.collection('frontend_notifications')
			.where('event', '==', db.collection('events').doc(eventId))
			.where('channel', '==', db.collection('channels').doc(channelId))
			.where('createdAt', '>=', Date.now())
			.orderBy('createdAt', 'desc')

		return query.onSnapshot((snapshot) => {
			snapshot.docChanges().forEach((change) => {
				if (change) handler(change.doc.data())
			})
		})
	}

	const listenDownloadedRecords = (channelId: string, eventId: string, handler: Function) => {
		const eventDoc = db.collection('events').doc(eventId)
		const channelDoc = db.collection('channels').doc(channelId)
		const query = db.collection('records').where('event', '==', eventDoc).where('channel', '==', channelDoc)

		return query.onSnapshot(
			(snapshot) => {
				if (snapshot) handler(snapshot)
			},
			(err) => {
				captureException(err)
			}
		)
	}

	const listenProcessingHighlights =
		(channelId: string) =>
		(handler: (highlightIds: ListenedHighlightStatus[]) => void): (() => void) => {
			const channelDoc = db.collection('channels').doc(channelId)
			const query = db
				.collection('highlights')
				.where('channel', '==', channelDoc)
				.where('conversionStatus', 'in', ['PENDING', 'PROCESSING', 'FAILED'])

			return query.onSnapshot(
				(snapshot) => {
					if (snapshot) {
						const highlights = snapshot.docs.map((d) => d.data())

						handler(
							highlights.map((highlight) => ({ id: highlight.uuid, status: highlight.conversionStatus }))
						)
					}
				},
				(err) => {
					captureException(err)
				}
			)
		}

	return {
		listenEvent,
		listenEvents,
		listenChannel,
		listenTimecodes,
		getTimecodes,
		getMessages,
		listenPolls,
		listenWinningInstants,
		listenPrompterMessages,
		listenFrontendNotifications,
		listenModeratedMessages,
		listenModeratingMessages,
		listenPinnedMessages,
		listenBannedUsers,
		listenDownloadedRecords,
		listenProcessingHighlights
	}
}

const timecodeConverter: FirestoreDataConverter<TimecodeDocument> = {
	toFirestore: (timecode: TimecodeDocument) => {
		return { ...timecode }
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot): TimecodeDocument => {
		const doc = snapshot.data()

		return {
			uuid: doc.uuid,
			created_at: doc.created_at,
			event: doc.event,
			record: doc.record,
			step: doc.step,
			ts: doc.ts
		}
	}
}

const bannedViewerConverter: FirestoreDataConverter<FirebaseBannedViewer> = {
	toFirestore: (timecode: FirebaseBannedViewer) => {
		return { ...timecode }
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot): FirebaseBannedViewer => {
		const doc = snapshot.data()

		return {
			displayName: doc.displayName,
			viewerId: doc.viewer.id,
			uuid: doc.uuid,
			event: doc.event.id
		}
	}
}

const messagesConverter: FirestoreDataConverter<FirebaseMessage> = {
	toFirestore: (message: FirebaseMessage) => {
		return { ...message }
	},
	fromFirestore: (snapshot: QueryDocumentSnapshot): FirebaseMessage => {
		const data = snapshot.data()

		return {
			value: data.value,
			created_at: data.created_at || null,
			event: data.event,
			is_brand: data.is_brand,
			is_moderated: data.is_moderated,
			is_pinned: data.is_pinned,
			is_question: data.is_question,
			is_answering: data.is_answering,
			moderating_user: data.moderating_user || null,
			is_shown: data.is_shown,
			was_shown: data.was_shown || undefined,
			user_displayName: data.user_displayName || null,
			user_uid: data.user_uid,
			uuid: data.uuid,
			parent_message: data.parent_message || null,
			parent_user_displayName: data.parent_user_displayName || null,
			parent_value: data.parent_value || null,
			parent_user_uid: data.parent_user_uid || null,
			parent_viewer_uid: data.parent_viewer_uid,
			viewer: data.viewer
		}
	}
}
