import arrow_0_deg from '@assets/icons/arrow_0_deg.svg'
import arrow_45_deg from '@assets/icons/arrow_45_deg.svg'
import arrow_90_deg from '@assets/icons/arrow_90_deg.svg'
import arrow_135_deg from '@assets/icons/arrow_135_deg.svg'
import arrow_180_deg from '@assets/icons/arrow_180_deg.svg'
import arrow_225_deg from '@assets/icons/arrow_225_deg.svg'
import arrow_270_deg from '@assets/icons/arrow_270_deg.svg'
import arrow_315_deg from '@assets/icons/arrow_315_deg.svg'
import arrow_circular from '@assets/icons/arrow_circular.svg'
import ban from '@assets/icons/ban.svg'
import bounce from '@assets/icons/bounce.svg'
import branding from '@assets/icons/branding.svg'
import bubble from '@assets/icons/bubble.svg'
import content from '@assets/icons/content.svg'
import calendar from '@assets/icons/calendar.svg'
import calendar_positive from '@assets/icons/calendar_positive.svg'
import cart from '@assets/icons/cart.svg'
import cart_add from '@assets/icons/cart_add.svg'
import chat_border from '@assets/icons/chat_border.svg'
import chat_plain from '@assets/icons/chat_plain.svg'
import check from '@assets/icons/check.svg'
import check_round from '@assets/icons/check_round.svg'
import cross_round from '@assets/icons/cross_round.svg'
import check_green from '@assets/icons/check_green.svg'
import chevron from '@assets/icons/chevron.svg'
import chevron_left from '@assets/icons/chevron_left.svg'
import chevron_down from '@assets/icons/chevron_down.svg'
import chevron_up from '@assets/icons/chevron_up.svg'
import cinema_set from '@assets/icons/cinema_set.svg'
import cinema_unset from '@assets/icons/cinema_unset.svg'
import clock from '@assets/icons/clock.svg'
import close from '@assets/icons/close.svg'
import dashboard from '@assets/icons/dashboard.svg'
import drag from '@assets/icons/drag.svg'
import domains from '@assets/icons/domains.svg'
import ellipsis from '@assets/icons/ellipsis.svg'
import euro from '@assets/icons/euro.svg'
import email from '@assets/icons/email.svg'
import events from '@assets/icons/events.svg'
import facebook from '@assets/icons/facebook.svg'
import fullscreen from '@assets/icons/fullscreen.svg'
import google_calendar from '@assets/icons/google_calendar.svg'
import unfullscreen from '@assets/icons/unfullscreen.svg'
import heart_plain from '@assets/icons/heart_plain.svg'
import heart_border_thin from '@assets/icons/heart_border_thin.svg'
import heart_border_bold from '@assets/icons/heart_border_bold.svg'
import instagram from '@assets/icons/instagram.svg'
import light from '@assets/icons/light.svg'
import link from '@assets/icons/link.svg'
import linkedin from '@assets/icons/linkedin.svg'
import list from '@assets/icons/list.svg'
import live from '@assets/icons/live.svg'
import logo from '@assets/icons/logo.svg'
import logo_large from '@assets/icons/logo_large.svg'
import logo_by_iadvize from '@assets/icons/logo_by_iadvize.svg'
import logout from '@assets/icons/logout.svg'
import mobile from '@assets/icons/mobile.svg'
import notification from '@assets/icons/notification.svg'
import outlook from '@assets/icons/outlook.svg'
import pen from '@assets/icons/pen.svg'
import pinned from '@assets/icons/pinned.svg'
import pinned_rotate from '@assets/icons/pinned_rotate.svg'
import pinterest from '@assets/icons/pinterest.svg'
import play from '@assets/icons/play.svg'
import play_round from '@assets/icons/play_round.svg'
import plane from '@assets/icons/plane.svg'
import plane_45 from '@assets/icons/plane_45.svg'
import products from '@assets/icons/products.svg'
import question from '@assets/icons/question.svg'
import screen from '@assets/icons/screen.svg'
import settings from '@assets/icons/settings.svg'
import share from '@assets/icons/share.svg'
import shirt from '@assets/icons/shirt.svg'
import stats from '@assets/icons/stats.svg'
import success from '@assets/icons/success.svg'
import trash from '@assets/icons/trash.svg'
import twitter from '@assets/icons/twitter.svg'
import upload from '@assets/icons/upload.svg'
import users from '@assets/icons/users.svg'
import user from '@assets/icons/user.svg'
import user_group from '@assets/icons/user_group.svg'
import vibrator from '@assets/icons/vibrator.svg'
import warning from '@assets/icons/warning.svg'
import waves from '@assets/icons/waves.svg'
import yahoo from '@assets/icons/yahoo.svg'
import youtube from '@assets/icons/youtube.svg'
import chevron_round from '@assets/icons/chevron_round.svg'
import font from '@assets/icons/font.svg'
import plus from '@assets/icons/plus.svg'
import minus from '@assets/icons/minus.svg'
import reset from '@assets/icons/reset.svg'
import eye from '@assets/icons/eye.svg'
import tooltip from '@assets/icons/tooltip.svg'
import search from '@assets/icons/search.svg'
import toggle from '@assets/icons/toggle.svg'
import gift from '@assets/icons/gift.svg'
import emoji from '@assets/icons/emoji.svg'
import down_arrow from '@assets/icons/down_arrow.svg'
import pause from '@assets/icons/pause.svg'
import cookie from '@assets/icons/cookie.svg'
import portrait from '@assets/icons/portrait.svg'
import landscape from '@assets/icons/landscape.svg'
import legal from '@assets/icons/legal.svg'
import copy from '@assets/icons/copy.svg'
import tos from '@assets/icons/tos.svg'
import switch_icon from '@assets/icons/switch_icon.svg'
import world from '@assets/icons/world.svg'
import rtmp from '@assets/icons/rtmp.svg'
import twitch from '@assets/icons/twitch.svg'
import facebook_plain from '@assets/icons/facebook_plain.svg'
import network from '@assets/icons/network.svg'
import product_widget from '@assets/icons/product_widget.svg'
import tooltip_bicolor from '@assets/icons/tooltip_bicolor.svg'
import tiktok from '@assets/icons/tiktok.svg'
import cursor from '@assets/icons/cursor.svg'
import download from '@assets/icons/download.svg'
import stop_right from '@assets/icons/stop_right.svg'
import stop_left from '@assets/icons/stop_left.svg'
import upload_cloud from '@assets/icons/upload_cloud.svg'
import delete_round from '@assets/icons/delete_round.svg'
import undo from '@assets/icons/undo.svg'
import redo from '@assets/icons/redo.svg'
import wrench from '@assets/icons/wrench.svg'
import watchlist from '@assets/icons/watchlist.svg'
import browser from '@assets/icons/browser.svg'
import target from '@assets/icons/target.svg'
import bolt from '@assets/icons/bolt.svg'
import circle_check from '@assets/icons/circle_check.svg'
import refresh from '@assets/icons/refresh.svg'
import chatgpt from '@assets/icons/chatgpt.svg'

export default {
	youtube,
	arrow_0_deg,
	arrow_45_deg,
	arrow_90_deg,
	arrow_135_deg,
	arrow_180_deg,
	arrow_225_deg,
	arrow_270_deg,
	arrow_315_deg,
	arrow_circular,
	ban,
	bounce,
	branding,
	content,
	chevron_round,
	bubble,
	calendar,
	calendar_positive,
	cart,
	cart_add,
	chat_border,
	chat_plain,
	check,
	check_round,
	cross_round,
	check_green,
	chevron,
	chevron_left,
	chevron_down,
	chevron_up,
	cinema_set,
	cinema_unset,
	clock,
	close,
	drag,
	dashboard,
	domains,
	ellipsis,
	email,
	euro,
	events,
	facebook,
	fullscreen,
	google_calendar,
	unfullscreen,
	heart_plain,
	heart_border_thin,
	heart_border_bold,
	instagram,
	light,
	link,
	linkedin,
	list,
	live,
	logo,
	logo_large,
	logo_by_iadvize,
	logout,
	mobile,
	notification,
	outlook,
	pen,
	pinned,
	pinterest,
	plane,
	plane_45,
	products,
	play,
	play_round,
	question,
	search,
	screen,
	settings,
	share,
	shirt,
	stats,
	success,
	trash,
	twitter,
	upload,
	user,
	users,
	user_group,
	vibrator,
	warning,
	waves,
	yahoo,
	font,
	plus,
	minus,
	reset,
	eye,
	tooltip,
	toggle,
	gift,
	down_arrow,
	pause,
	emoji,
	pinned_rotate,
	cookie,
	portrait,
	landscape,
	legal,
	copy,
	tos,
	switch_icon,
	world,
	rtmp,
	twitch,
	facebook_plain,
	network,
	product_widget,
	tooltip_bicolor,
	tiktok,
	cursor,
	download,
	stop_left,
	stop_right,
	upload_cloud,
	delete_round,
	undo,
	redo,
	watchlist,
	wrench,
	browser,
	target,
	bolt,
	circle_check,
	refresh,
	chatgpt
}
