// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const DB_ENV = process.env.NEXT_PUBLIC_DB_ENV === 'dev' ? 'development' : process.env.NEXT_PUBLIC_DB_ENV

const rate = DB_ENV === 'development' ? 0.0 : 1.0

Sentry.init({
	dsn: SENTRY_DSN || 'https://24dff0751719473b9757c1975265579a@o62907.ingest.sentry.io/6726149',
	rate: rate,
	tracesSampleRate: rate,
	environment: DB_ENV,
	ignoreErrors: ['<unknown>', '[object XMLHttpRequest]', '/Non-Error exception captured with keys: */']
})
