import SwitchBox from '@components/Templates/SwitchBox'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import React, { useImperativeHandle } from 'react'
import { connect } from 'react-redux'
import { Flex } from 'rebass'
import { Domain } from 'types/channel'

interface Props {
	formDatas?: any
	setFormDatas?: any
	domain: Domain
	innerRef: any
	domainsPath?: string
	isActive?: boolean
	isDisabled?: boolean
	isTest?: boolean
	setDataWithFunction?: boolean
	onChange?: (d: Domain) => void
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const DomainItem: React.FC<Props> = ({
	formDatas,
	setFormDatas,
	onChange,
	domain,
	innerRef,
	domainsPath = 'domains',
	isActive = false,
	isDisabled = false,
	isTest = false,
	setDataWithFunction = true
}) => {
	const onClickHandler = () => {
		if (setFormDatas) {
			const output = { ...formDatas }
			if (isActive) {
				//enable
				output[domainsPath].push(domain)
			} else {
				output[domainsPath] = output[domainsPath].filter((d) => !(d.uuid === domain.uuid))
			}
			setFormDatas(setDataWithFunction ? () => output : output)
		}
		if (onChange) onChange(domain)
	}

	useImperativeHandle(
		innerRef,
		() => ({
			validate() {
				return true
			}
		}),
		[formDatas]
	)

	return (
		<>
			<Flex justifyContent={'space-between'} alignItems={'center'} width={1}>
				<SwitchBox
					variant="contained"
					onClick={() => onClickHandler()}
					isActive={!isActive}
					title={domain.name}
					subTitle={domain.host}
					isDisabled={isTest || isDisabled}
					noMargin={true}
				/>
			</Flex>
		</>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(DomainItem)
