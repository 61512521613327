import React from 'react'
import { Box } from 'rebass'
import Style from '../style'

interface InputLabelProps {
	optional?: boolean
	optionalText?: string
	fontSize?: string[]
	mb?: number
	color?: string
	lineHeight?: string
}

export const InputLabel: React.FC<InputLabelProps> = ({ children, optional, optionalText, ...props }) => {
	return (
		<Box css={Style.inputLabel} {...props}>
			{children}
			<Box as="span" css={Style.optional} ml={'4px'}>
				{optional ? '(OPTIONAL)' : null}
			</Box>
			{optionalText && (
				<Box as="span" css={Style.optional}>
					{optionalText}
				</Box>
			)}
		</Box>
	)
}
