import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SearchInputWrapper, BaseInput, ExpandableBox, UnderlinedText, SearchTextField, classes } from './style'
import Icon from '@components/Micro/Icon'
import { CSSTransition } from 'react-transition-group'
import Theme from '@styles/theme'
import _debounce from 'lodash/debounce'
import Spinner from '@components/Micro/Spinner'

interface SearchInputProps {
	name?: string
	onChange?: (value: string) => void
	isHidden?: boolean
	placeholder?: string
	id?: string
	forceClose?: boolean
	debounce?: number
	unfolded?: boolean
	isLoading?: boolean
}

const SearchInput: React.FC<SearchInputProps> = ({
	name,
	onChange,
	isHidden,
	placeholder,
	forceClose,
	id,
	unfolded,
	debounce = 0,
	isLoading
}) => {
	/**
	 * @States
	 */
	const [isActive, setIsActive] = useState<boolean>(false)
	const [inputValue, setInputValue] = useState<string>('')
	const inputRef = useRef(null)

	/**
	 * @Effects
	 */
	useEffect(() => {
		if (inputValue) {
			setInputValue('')
			onChange('')
		}
	}, [isActive])

	useEffect(() => {
		if (unfolded) {
			setIsActive(true)
		}
	}, [])

	useEffect(() => {
		if (forceClose) {
			setIsActive(false)
		}
	}, [forceClose])

	/**
	 * @Methods
	 */
	const handleClick = () => {
		if (!isActive) {
			setIsActive(true)
			inputRef.current.focus()
		}
	}

	const debouncedHandleChange = useCallback(_debounce(onChange, debounce), [])

	const handleInputChange = (event) => {
		const val = event?.target?.value
		setInputValue(val)
		debouncedHandleChange(val)
	}

	return (
		<CSSTransition in={isActive} classNames={classes.base} timeout={0}>
			<SearchInputWrapper width={1} isHidden={isHidden} isActive={isActive} className={classes.base}>
				<BaseInput isActive={isActive} onClick={handleClick} outlined={true}>
					<Icon name="search" width="18px" height="18px" fill={Theme.colors.white()} />
					<ExpandableBox isActive={isActive}>
						<SearchTextField
							id={id}
							type="text"
							name={name}
							value={inputValue}
							as="input"
							placeholder={placeholder}
							onChange={(e) => handleInputChange(e)}
							ref={inputRef}
						/>
						{isLoading && <Spinner height={24} width={24} />}
						{!unfolded && <UnderlinedText onClick={() => setIsActive(false)}>Close</UnderlinedText>}
					</ExpandableBox>
				</BaseInput>
			</SearchInputWrapper>
		</CSSTransition>
	)
}

export default SearchInput
