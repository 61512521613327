import Button from '@components/Micro/Button/SimpleCta'
import Input, { ImperativeHandleType } from '@components/Micro/Fields/Input'
import Spinner from '@components/Micro/Spinner'
import Text from '@components/Micro/Text'
import { PopinContent, PopinText } from '@components/Pages/Channel/Form/Configuration/DestinationsPopin/style'
import { PopinTitle } from '@components/Templates/Popin'
import { DisplayCondition } from '@customTypes/channel'
import { routes } from '@helpers/hooks/useAplozeApi'
import { useAxiosCall } from '@helpers/hooks/useAxios'
import { BaseFlex } from '@styles/Basics'
import { SimpleGrid } from '@styles/Grid'
import Theme from '@styles/theme'
import router from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { ButtonWrapper } from '../DisplayConditionPopinForm/style'

interface TestConditionPopinProps {
	displayConditions: Array<DisplayCondition>
	label: String
	activated?: boolean
}

const TestConditionPopin: React.FC<TestConditionPopinProps> = ({ displayConditions, label, activated }) => {
	const [testedUrl, setTestedUrl] = useState<string>('')
	const [{ data: testConditionResult, loading: loadingTestConditionResult }, testDisplayCondition] = useAxiosCall({
		method: 'POST'
	})
	const urlRef = useRef<ImperativeHandleType>(null)

	const testUrl = () => {
		testDisplayCondition(
			{ url: routes.testConditions(router.query.channelId as string), activated },
			{ url: testedUrl, displayConditions, activated }
		)
	}

	const onChange = (e) => {
		setTestedUrl(e.target.value)
	}

	useEffect(() => {
		if (testConditionResult) {
			const urlIsValid = testConditionResult.conditions_result
			urlIsValid
				? urlRef.current.setSuccess('Good ! This URL matches your display conditions')
				: urlRef.current.setError('This URL does not match your display conditions')
		}
	}, [testConditionResult])

	return (
		<>
			<PopinContent>
				<>
					<PopinTitle>Test your conditions</PopinTitle>
					<PopinText>Type in an URL to test if your {`${label} will work`}</PopinText>
				</>
			</PopinContent>
			<SimpleGrid gap={Theme.spacing(10)} width="80%">
				<SimpleGrid gap={Theme.spacing(4)}>
					<Input
						label={'URL'}
						name="url"
						onChange={onChange}
						innerRef={urlRef}
						value={testedUrl}
						onEnter={testUrl}
						placeholder="Type in a URL you want to test"
					/>
					{loadingTestConditionResult && (
						<BaseFlex gap={Theme.spacing(1)} alignItems="center" justifyContent="center">
							<Spinner width={30} height={30} />
							<Text variant="thin-lg">Loading...</Text>
						</BaseFlex>
					)}
				</SimpleGrid>
				<ButtonWrapper>
					<Button size="medium" bgColor={Theme.colors.grey1} onClick={testUrl} disabled={!testedUrl?.length}>
						Launch test
					</Button>
				</ButtonWrapper>
			</SimpleGrid>
		</>
	)
}

export default TestConditionPopin
