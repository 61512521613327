import {
	liveTabs,
	moderatorLiveTabs,
	moderatorReplayTabs
} from '@components/Pages/Channel/Events/Event/EventTabsView/logic'
import { ChannelSubscriptionType } from '@customTypes/channel'
import { EventStatus, EventTag } from '@customTypes/event'
import { Role, UserAccess } from 'types/user'

const AdminRequiredRoles: Role[] = ['admin', 'superadmin']

export default class AccessChecker {
	static hasAdminAccess(accesses: UserAccess[], channelId: string | string[]): boolean {
		if (accesses?.find((e) => e.role === 'superadmin')) return true

		return AdminRequiredRoles.some(
			(role) => accesses?.find((access) => access.role === role && access.channelId === channelId)
		)
	}
	static hasSuperAdminAccess(accesses: UserAccess[]): boolean {
		return accesses?.some((e) => e.role === 'superadmin')
	}

	static isModerator(accesses: UserAccess[], channelId: string | string[]) {
		return !this.hasAdminAccess(accesses, channelId) && !this.hasSuperAdminAccess(accesses)
	}

	static hasAccessToTab(
		tab: EventTag,
		accesses: UserAccess[],
		channelId: string | string[],
		eventStatus: EventStatus
	): boolean {
		if (!eventStatus) return false
		if (this.isModerator(accesses, channelId)) {
			if (eventStatus === 'replay') {
				return moderatorReplayTabs.indexOf(tab) !== -1
			} else {
				return moderatorLiveTabs.indexOf(tab) !== -1
			}
		}
		if (eventStatus === 'live') {
			const checkedTabs = this.hasSuperAdminAccess(accesses) ? [...liveTabs, 'sms'] : liveTabs
			return checkedTabs.indexOf(tab) !== -1
		}
		if (!this.hasSuperAdminAccess(accesses)) {
			return true
		}
		return true
	}

	static hasSubscriptionTypeRights(
		subscriptionType: ChannelSubscriptionType,
		subscriptionTypeNeeded: ChannelSubscriptionType = 'starter'
	): boolean {
		switch (subscriptionTypeNeeded) {
			case 'pilot':
				return true
			case 'starter':
				return subscriptionType !== 'pilot'
			case 'growth':
				return subscriptionType === 'growth' || subscriptionType === 'enterprise'
			case 'enterprise':
				return subscriptionType === 'enterprise'
			default:
				return false
		}
	}

	static hasAccessToFeature(
		subscriptionType: ChannelSubscriptionType,
		isSuperAdmin: boolean,
		subscriptionTypeNeeded: ChannelSubscriptionType = 'starter'
	) {
		return this.hasSubscriptionTypeRights(subscriptionType, subscriptionTypeNeeded) || isSuperAdmin
	}
}
