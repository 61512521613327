import styled from '@emotion/styled'
import { ellipsisText } from '@styles/shared'
import Theme from '@styles/theme'

export const CopyBoxWrapper = styled.div<{ alignItems?; plain? }>`
	font-size: 14px;
	padding: 0.75rem 1rem;
	color: ${Theme.colors.white()};
	background: ${({ plain }) => (plain ? Theme.colors.blue1 : 'transparent')};
	border-radius: 8px;
	border: ${({ plain }) => plain || `1px solid ${Theme.colors.grey2}`};
	display: flex;
	align-items: ${({ alignItems }) => alignItems || 'center'};
	justify-content: space-between;
	flex-wrap: wrap;
	cursor: pointer;

	pre {
		${ellipsisText};
	}
`

export const CopyTextWrapper = styled.div`
	width: calc(100% - 70px);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
`

export const CopyButtonWrapper = styled.div`
	padding: 7px 9px;
	color: ${Theme.colors.white()};
	background: ${Theme.colors.grey1};
	border-radius: 4px;
	font-size: 10px;
	line-height: 12px;
	text-align: center;
	font-weight: bold;
	border: none;
	min-width: fit-content;
	:active {
		box-shadow: 0px 0px 0px 1px ${Theme.colors.white(0.5)};
	}
`
