import React from 'react'
import {
	CardPickerItemContent,
	CardPickerItemImage,
	CardPickerItemText,
	CardPickerItemTitle,
	CardPickerItemWrapper
} from './style'
import Theme from '@styles/theme'
import Image from '@components/Micro/Image'
import { MediaFile } from '@customTypes/common'
import { cardPickerClasses } from '../classes'

export interface CardPickerItemData {
	value: string
	image: MediaFile | { url: string }
	title: string
}

interface CardPickerItemProps {
	item?: CardPickerItemData
	onClick?: any
	selected?: boolean
}

const CardPickerItem: React.FC<CardPickerItemProps> = ({ item, onClick, selected = false }) => {
	return (
		<CardPickerItemWrapper
			isSelected={selected}
			onClick={() => {
				if (onClick) {
					onClick(item)
				}
			}}
		>
			<CardPickerItemContent className={cardPickerClasses.item}>
				<CardPickerItemImage>
					<Image sizes={[[152, 197]]} media={item.image} color={Theme.colors.background} fit="cover" />
				</CardPickerItemImage>
				<CardPickerItemText>
					<CardPickerItemTitle>{item.title}</CardPickerItemTitle>
				</CardPickerItemText>
			</CardPickerItemContent>
		</CardPickerItemWrapper>
	)
}

export default CardPickerItem
