import IconAndText from '@components/Micro/Content/IconAndText'
import Switch from '@components/Micro/Switch'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import React from 'react'
import { connect } from 'react-redux'
import { Flex, Box } from 'rebass'
import { classes, SwitchBoxWrapper, SwitchBoxWrapperProps } from './style'
import cx from 'classnames'

interface SwitchBoxProps extends SwitchBoxWrapperProps {
	onClick: (action) => void
	isActive: boolean
	iconName?: string
	title: string
	subTitle?: string
	isDisabled?: boolean
	noMargin?: boolean
}

const mapStateToProps = (state: State, props: SwitchBoxProps) => {
	return { ...state, ...props }
}

const SwitchBox: React.FC<SwitchBoxProps> = ({
	onClick,
	isActive,
	iconName,
	title,
	subTitle,
	isDisabled = false,
	...props
}) => {
	return (
		<SwitchBoxWrapper className={cx(classes.root, { [classes.disabled]: isDisabled })} {...props}>
			<Box>
				<Flex alignItems="center" width={1}>
					<IconAndText iconName={iconName} title={title} subtitle={subTitle} />
				</Flex>
			</Box>
			{!isDisabled && <Switch onClick={onClick} isActive={isActive} />}
		</SwitchBoxWrapper>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchBox)
