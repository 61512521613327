import App, { AppProps } from 'next/app'
import React from 'react'
import { AuthUserProvider } from '@helpers/contexts/authUserContext'
import { ApiProvider } from '@helpers/contexts/apiContext'
import { DbProvider } from '@helpers/contexts/dbContext'
import { ReduxProvider } from '@helpers/contexts/reduxContext'
import { ThemeProvider } from '@helpers/contexts/themeContext'
import { LoggedContext } from '@helpers/contexts/loggedContext'
import withReduxStore, { Store } from '@helpers/wrappers/withReduxStore'
import Modals from '@components/Modals'
import { GlobalProvider } from '@helpers/contexts/global'
import { ChannelsProvider } from '@helpers/contexts/channelsContext'
import { ModalsProvider } from '@helpers/contexts/modals/ModalsContext'

interface Props {
	reduxStore: Store
}

class BO extends App<Props & AppProps> {
	render() {
		const { Component, pageProps, reduxStore } = this.props
		return (
			<>
				<AuthUserProvider>
					<ApiProvider>
						<DbProvider>
							<ReduxProvider store={reduxStore}>
								<ChannelsProvider>
									<ThemeProvider>
										<LoggedContext>
											<Modals />
											<GlobalProvider>
												<ModalsProvider>
													<Component {...pageProps} />
												</ModalsProvider>
											</GlobalProvider>
										</LoggedContext>
									</ThemeProvider>
								</ChannelsProvider>
							</ReduxProvider>
						</DbProvider>
					</ApiProvider>
				</AuthUserProvider>
			</>
		)
	}
}

export default withReduxStore(BO)
