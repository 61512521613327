import { css } from '@emotion/core'
import Theme from '@styles/theme'

export default {
	pageBtn: css`
		width: 30px;
		height: 30px;
		font-size: 12px;
		border: 1px solid ${Theme.colors.grey2};
		border-radius: 8px;
		text-align: center;
		padding-top: 8px;
		cursor: pointer;
	`,
	inactiveBtn: css`
		border-color: ${Theme.colors.blue1};
	`
}
