import { css } from '@emotion/react'
import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { BaseFlex } from '@styles/Basics'

export default {
	categoryHeaderFont: css`
		background-color: ${Theme.colors.white()};
		padding: 10px;
		border-radius: 100%;
	`,
	categoryHeaderText: css`
		color: ${Theme.colors.white()};
		font-size: 16px;
		font-weight: bold;
	`
}

export const CategoryHeaderWrapper = styled(BaseFlex)<{ noWidth: boolean }>`
	justify-content: space-between;
	width: ${({ noWidth }) => (noWidth ? 'auto' : '100%')};
`

export const Title = styled(BaseFlex)<{ noMt: boolean; noMb: boolean }>`
	margin-top: ${({ noMt }) => (noMt ? `0px` : Theme.spacing(3))};
	margin-bottom: ${({ noMb }) => (noMb ? `0px` : Theme.spacing(6))};
`

export const LinkWrapper = styled(BaseFlex)`
	align-items: center;
	&:hover {
		opacity: 0.8;
	}
	> a {
		text-decoration: underline;
		font-size: 12px;
		color: ${Theme.colors.grey3};
	}
	& > a:visited {
		color: ${Theme.colors.grey3};
	}
`
