import Icon from '@components/Micro/Icon'
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import Input from '../Input'
import {
	DropdownWrapper,
	EntriesWrapper,
	EntryGroup,
	EntryItem,
	EntryItemIconWrapper,
	Select,
	SelectIcon,
	SelectLabel,
	SelectWrapper
} from './style'
import Theme from '@styles/theme'
import { selectClasses } from './classes'

interface SelectFieldProps<T, U extends SelectEntry<T>> {
	innerRef?: React.MutableRefObject<any>
	name?: string
	label?: string
	entries: Array<{ entries: Array<U> }>
	onChange: (action, value: U) => void
	initialValue?: number
	position?: string
	error?: boolean
	isDisabled?: boolean
	// when something different needs to be displayed in the input than the value in the select
	displayedInputValue?: {
		name: string
		icon?: string
		bigIcon?: boolean
	}
}

export type SelectEntry<T> = {
	name: string
	value?: T
	icon?: string
	hidden?: boolean
	bigIcon?: boolean
	index?: number
}

const SelectField = <T, U extends SelectEntry<T>>({
	entries,
	name,
	onChange,
	label,
	initialValue,
	position = 'top',
	innerRef,
	error,
	isDisabled,
	displayedInputValue
}: SelectFieldProps<T, U>) => {
	const selectRef = useRef(null)
	const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
	const [selectedItem, setCurrentValue] = useState<{ value: U; index: number }>(null)
	const [entryHoveredOnIndex, setEntryHoveredOnIndex] = useState<number>(null)
	const [hasError, setHasError] = useState(false)

	const onClick = () => {
		setTimeout(() => {
			setDropdownOpen(!dropdownOpen)
		}, 5)
	}

	const onEntryClick = (event, entry: U, i: number) => {
		event.target.value = entry.index || i
		onChange(event, entry)
		setDropdownOpen(!dropdownOpen)
		setCurrentValue({ value: entry, index: entry.index || i })
		setHasError(false)
	}

	useEffect(() => {
		setHasError(error)
	}, [error])

	useEffect(() => {
		const allEntries = entries.reduce((acc, next) => acc.concat(next.entries), [])

		if (allEntries.length > 0) {
			if (!selectedItem) {
				if ((initialValue || initialValue === 0) && initialValue !== -1) {
					setCurrentValue({ value: allEntries[initialValue], index: initialValue })
				} else {
					setCurrentValue({ value: allEntries[0], index: 0 })
				}
			} else {
				setCurrentValue({ value: allEntries[selectedItem.index], index: selectedItem.index })
			}
		}
	}, [initialValue, entries])

	useImperativeHandle(
		innerRef,
		() => ({
			setError() {
				setHasError(true)
			}
		}),
		[]
	)

	const renderEntryGroup = (group: { entries: Array<U>; startsAt: number }, index: number) => {
		const display = !group.entries.every((e) => e.hidden)
		return display ? (
			<EntryGroup hasTopBorder={index !== 0} key={`entry-group-${index}`}>
				{group.entries.map((entry, i) => (
					<EntryItem
						hidden={entry.hidden}
						onClick={(event) => onEntryClick(event, entry, group.startsAt + i)}
						key={`${name}-option-${i}`}
						onMouseEnter={() => setEntryHoveredOnIndex(i)}
						onMouseLeave={() => setEntryHoveredOnIndex(null)}
					>
						{entry.icon && (
							<EntryItemIconWrapper>
								<Icon
									height={entry.bigIcon ? '20px' : '13px'}
									width={entry.bigIcon ? '20px' : '13px'}
									name={entry.icon}
									fill={entryHoveredOnIndex === i ? Theme.colors.white() : Theme.colors.grey2}
								/>
							</EntryItemIconWrapper>
						)}
						{entry.name}
					</EntryItem>
				))}
			</EntryGroup>
		) : (
			<div key={`entry-group-${index}`}></div>
		)
	}

	const entryGroupsAndElementsCount = entries.reduce((acc, next) => {
		if (acc.length === 0) {
			return acc.concat([{ ...next, startsAt: 0 }])
		} else {
			const previous = acc[acc.length - 1]
			return acc.concat([
				{ ...next, startsAt: previous.startsAt + previous.entries.length + next.entries.length - 1 }
			])
		}
	}, [])

	const displayedValue = displayedInputValue || selectedItem?.value

	return (
		<>
			{label && <SelectLabel>{label}</SelectLabel>}
			{isDisabled && (
				<Input
					isDisabled={true}
					isDark={true}
					value={displayedValue?.name || '...'}
					prefixIconName={displayedValue?.icon}
				/>
			)}
			{!isDisabled && (
				<SelectWrapper className={selectClasses.root}>
					<DropdownWrapper isOpen={dropdownOpen} onClick={onClick}>
						<Icon
							name={dropdownOpen ? 'chevron_up' : 'chevron_down'}
							width="10px"
							height="10px"
							fill={dropdownOpen ? Theme.colors.black() : Theme.colors.white()}
						/>
					</DropdownWrapper>

					<Select ref={selectRef} onClick={onClick} hasError={hasError} isOpen={dropdownOpen}>
						{displayedValue?.icon && (
							<SelectIcon>
								<Icon
									height={displayedValue?.bigIcon ? '20px' : '13px'}
									width={displayedValue?.bigIcon ? '20px' : '13px'}
									name={displayedValue?.icon}
									fill={Theme.colors.white()}
								/>
							</SelectIcon>
						)}

						{displayedValue?.name || '...'}
					</Select>
					{dropdownOpen && (
						<OutsideClickHandler onOutsideClick={onClick}>
							<EntriesWrapper position={position}>
								{entryGroupsAndElementsCount.map(renderEntryGroup)}
							</EntriesWrapper>
						</OutsideClickHandler>
					)}
				</SelectWrapper>
			)}
		</>
	)
}

export default SelectField
