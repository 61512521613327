import styled from '@emotion/styled'
import Spinner from '@assets/img/spinner.gif'
import { BaseFlex } from '@styles/Basics'

const PREFIX = 'spinner'

export const classes = {
	root: PREFIX
}

export const SpinnerWrapper = styled(BaseFlex)<{ isSmall; width; padding; height }>`
	background: url(${Spinner}) no-repeat center;
	background-size: ${({ isSmall }) => (isSmall ? '20px 20px' : '30px 30px')};
	width: ${({ width }) => (typeof width === 'number' ? width + 'px' : width || 'unset')};
	height: ${({ height }) => (typeof height === 'number' ? height + 'px' : height) || 'unset'};
	padding: ${({ padding }) => (typeof padding === 'number' ? padding + 'px' : padding) || 'unset'};
`
