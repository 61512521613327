import styled from '@emotion/styled'

interface GridProps {
	columns?: number
	rows?: number
	gap?: number | string
	width?: number | string
}

interface AutoRowProps {
	columns?: number
	gap?: string
	width?: number | string
}

export const BaseGrid = styled.div`
	display: grid;
`

export const SimpleGrid = styled(BaseGrid)<GridProps>`
	grid-template: ${({ rows, columns }) => `repeat(${rows || 1}, auto) / repeat(${columns || 1}, auto)`};
	grid-gap: ${({ gap }) => gap || 0};
	width: ${({ width }) => width || 'auto'};
`

export const AutoRowGrid = styled(BaseGrid)<AutoRowProps>`
	grid-gap: ${({ gap }) => gap || 0};
	grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
	grid-auto-rows: 1fr;
	grid-auto-flow: row;
	width: ${({ width }) => width || 'auto'};
`
