import styled from '@emotion/styled'
import Theme from '@styles/theme'

export const Select = styled('div')<{ isOpen: boolean; hasError: boolean }>`
	display: flex;
	align-items: center;
	background: none;
	border: 1px solid ${Theme.colors.grey2};
	font-size: 14px;
	padding: 12px 20px 12px;
	outline: none;
	border-radius: 10px;
	appearance: none;
	font-weight: normal;
	font-family: 'PlusJakartaSans';
	line-height: 24px;
	color: ${Theme.colors.white()};
	cursor: pointer;
	border-color: ${({ hasError, isOpen }) =>
		isOpen ? `${Theme.colors.white()}` : hasError ? `${Theme.colors.red()};` : `${Theme.colors.grey2}`};
`

export const SelectIcon = styled('div')`
	display: flex;
	padding-right: 10px;
`
export const SelectLabel = styled('div')`
	font-weight: bold;
	color: ${Theme.colors.white()};
	font-size: 12px;
	margin-bottom: 20px;
`

export const SelectWrapper = styled('div')`
	position: relative;
`

export const EntriesWrapper = styled('div')<{ position: string }>`
	position: absolute;
	background: ${Theme.colors.blue1};
	color: ${Theme.colors.grey2};
	z-index: 100;
	overflow: auto;
	border-radius: 10px;
	width: 100%;
	max-height: 250px;
	height: fit-content;
	${({ position }) => (position === 'bottom' ? 'top: 55px' : 'bottom: 55px')};
`
export const EntryGroup = styled('div')<{ hasTopBorder: boolean }>`
	pointer-events: none;
	padding: 10px;
	${({ hasTopBorder }) => (hasTopBorder ? `border-top: 1px solid ${Theme.colors.grey2}` : 'unset')};
`

export const EntryItem = styled('div')<{ hidden: boolean }>`
	font-size: 14px;
	padding: ${Theme.spacing(2)};
	border-radius: 10px;
	cursor: pointer;
	pointer-events: all;
	display: flex;
	align-items: center;

	display: ${({ hidden }) => (hidden ? 'none' : 'visible')};

	&:hover {
		background: ${Theme.colors.grey2};
		color: ${Theme.colors.white()};
	}
`

export const EntryItemIconWrapper = styled.div`
	margin-right: 10px;
`

export const DropdownWrapper = styled('div')<{ isOpen: boolean }>`
	background: ${({ isOpen }) => (isOpen ? `${Theme.colors.white()}` : `${Theme.colors.grey1}`)};
	padding: 9px;
	border-radius: 10px;
	cursor: pointer;
	pointer-events: all;
	position: absolute;
	right: 10px;
	bottom: 10px;
`
