import styled from '@emotion/styled'
import Theme from '@styles/theme'

export const CategoryHeaderIcon = styled.div`
	background-color: ${Theme.colors.white()};
	padding: 9px;
	border-radius: 100%;
	margin-right: ${Theme.spacing(2)};
`

export const CategoryHeaderTitle = styled.div<{ fontSize?: number | string }>`
	color: ${Theme.colors.white()};
	font-weight: bold;
	font-size: ${({ fontSize }) => (typeof fontSize === 'string' ? fontSize : fontSize + 'px')};
`

export const CategoryHeaderSubtitle = styled.div`
	font-size: 12px;
	color: ${Theme.colors.grey3};
`
