const PREFIX = 'popin'

export const popinClasses = {
	base: `${PREFIX}`,
	opened: `${PREFIX}-opened`,
	title: `${PREFIX}-title`,
	close: `${PREFIX}-close`,
	wrapper: 'modal',
	overlay: 'modal__box'
}
