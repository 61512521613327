import { CardPickerItemData } from './CardPickerItem'

export const buildItemsPages = (items: Array<CardPickerItemData>, filter: string, limit: number) => {
	let sortedItems = items.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))
	let itemsPages = []
	let pageBuilder = []

	if (filter) {
		sortedItems = sortedItems.filter((item) => item.title.toLowerCase().includes(filter.toLowerCase()))
	}

	sortedItems.forEach((product, index) => {
		if (pageBuilder.length < limit) {
			pageBuilder.push(product)
		}
		if (pageBuilder.length === limit || sortedItems.length === index + 1) {
			itemsPages.push(pageBuilder)
			pageBuilder = []
		}
	})

	return {
		count: sortedItems.length,
		pages: itemsPages.length,
		itemsPages
	}
}
