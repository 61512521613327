import React, { useEffect, useState } from 'react'
import { mapDispatchToProps } from '@helpers/wrappers/withReduxStore'
import { State } from '@stores/index'
import { connect } from 'react-redux'

import Popin from '@components/Templates/Popin'
import Callback from '@components/Modals/Callback'
import Confirm from '@components/Modals/Confirm'

interface Props {
	storeModal?: any
	dispatch?: (action) => void
}

const mapStateToProps = (state: State, props: Props) => {
	return { ...state, ...props }
}

const Modal: React.FC<Props> = ({ storeModal, dispatch }) => {
	//

	const [content, setContent] = useState(null)

	useEffect(() => {
		if (storeModal !== null) {
			if (storeModal.type === 'callback') {
				setContent(<Callback datas={storeModal} />)
			} else if (storeModal.type === 'confirm') {
				setContent(
					<Confirm
						datas={storeModal}
						onCancel={storeModal.noCancel ? null : onCloseHandler}
						onConfirm={() => {
							storeModal.onConfirm()
							dispatch({ type: 'REMOVE_MODAL' })
						}}
					/>
				)
			} else {
				setContent(storeModal.content)
			}
		} else {
			setContent(null)
		}
	}, [storeModal])

	const onCloseHandler = () => {
		if (!storeModal.loading || !storeModal?.hasProgress) {
			if (storeModal.onClose) {
				storeModal.onClose()
			}
			dispatch({ type: 'REMOVE_MODAL' })
		}
	}

	return (
		<Popin
			noClose={storeModal?.loading}
			isOpened={content !== null}
			onClose={onCloseHandler}
			hasCta={storeModal && storeModal.type === 'confirm'}
		>
			{content}
		</Popin>
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
