import React from 'react'
import Icon from '@components/Micro/Icon'
import { CategoryHeaderIcon, CategoryHeaderSubtitle, CategoryHeaderTitle } from './style'
import Theme from '@styles/theme'
import { IconSettings } from '@customTypes/common'
import Tooltip from '@components/Micro/Tooltip'
import { BaseFlex } from '@styles/Basics'
import { SimpleGrid } from '@styles/Grid'
import { iconTextClasses } from './classes'
import { TooltipDirection } from '@components/Micro/Tooltip/style'

interface Props {
	iconName?: string
	tooltip?: string
	tooltipLink?: string
	tooltipDirection?: TooltipDirection
	title: string
	fontSize?: number | string
	subtitle?: string
	iconSettings?: IconSettings
}
const IconAndText: React.FC<Props> = ({
	iconName,
	tooltip,
	tooltipLink,
	tooltipDirection,
	title,
	fontSize = 14,
	subtitle,
	iconSettings
}) => {
	return (
		<BaseFlex alignItems={'center'} className={iconTextClasses.root}>
			{iconName && (
				<CategoryHeaderIcon className={iconTextClasses.iconContainer}>
					<Icon name={iconName} height={iconSettings?.height || 12} width={iconSettings?.width || 12} />
				</CategoryHeaderIcon>
			)}
			<SimpleGrid gap={Theme.spacing(1)}>
				<CategoryHeaderTitle fontSize={fontSize}>
					<BaseFlex gap={Theme.spacing(1)}>
						{title}
						{tooltip && (
							<Tooltip text={tooltip} link={tooltipLink} bicolor={true} direction={tooltipDirection} />
						)}
					</BaseFlex>
				</CategoryHeaderTitle>
				{subtitle && (
					<CategoryHeaderSubtitle className={iconTextClasses.subtitle}>{subtitle}</CategoryHeaderSubtitle>
				)}
			</SimpleGrid>
		</BaseFlex>
	)
}

export default IconAndText
