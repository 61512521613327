import Icon, { IconUnion } from '@components/Micro/Icon'
import React from 'react'
import { PopinHeaderContent, PopinHeaderIcon, PopinHeaderSubTitle, PopinHeaderTitle, PopinHeaderWrapper } from './style'
import Theme from '@styles/theme'

interface PopinHeaderProps {
	title: string
	description?: string
	icon?: IconUnion
}

const PopinHeader: React.FC<PopinHeaderProps> = ({ title, description, icon }) => {
	return (
		<PopinHeaderWrapper>
			{icon && (
				<PopinHeaderIcon>
					<Icon name={icon} width="100%" height="100%" fill={Theme.colors.blue2} />
				</PopinHeaderIcon>
			)}
			<PopinHeaderContent>
				<PopinHeaderTitle>{title}</PopinHeaderTitle>
				{description && <PopinHeaderSubTitle>{description}</PopinHeaderSubTitle>}
			</PopinHeaderContent>
		</PopinHeaderWrapper>
	)
}

export default PopinHeader
