import { addMinutes, differenceInMinutes, differenceInSeconds } from 'date-fns'
import { formatTimeMinuteSeconds } from './StatsFormatter'
import { formatToTen } from '@utils/number_utils'

export const timeMap = {
	0: 3_600_000, // 1 hour
	1: 60_000, // 1 minute
	2: 1_000 // 1 second
}

export interface TimeDifference {
	minutes: string | number
	seconds: string | number
}

export class TimeFormater {
	static getTimeDifference = (now: Date, to: Date): TimeDifference => {
		const minutes = differenceInMinutes(to, now)
		now = addMinutes(now, minutes)
		const seconds = differenceInSeconds(to, now)

		return {
			minutes: minutes,
			seconds: seconds < 10 ? '0' + seconds : '' + seconds
		}
	}

	static getMsFromHours = (value: string): number => {
		const split: Array<string> = value.split(':')
		return split.reduce((prev, curr, index) => {
			return prev + parseInt(curr) * timeMap[index]
		}, 0)
	}

	static formatMsToTime = (timestamp: number, hasHours?: boolean): string => {
		if (typeof timestamp !== 'number') return
		if (!timestamp) return hasHours ? '00:00:00' : '00:00'

		const date = new Date(timestamp).toISOString()
		return hasHours ? date.slice(11, 19) : date.slice(14, 19)
	}

	/**
	 * @param ms milliseconds
	 * @returns seconds without decimals
	 */
	static getSecondsFromMs = (ms: number) => {
		return Math.floor(ms / 1000)
	}

	static formatDuration = (duration: number) => {
		return duration / 1000 / 60 // Should always be in minutes
	}

	/**
	 * Get time difference between two dates
	 * as default value === 'ms'
	 * @returns seconds or milliseconds depending on as optionnal param
	 */
	static getTimeBetweenDates = (a: string | Date, b: string | Date, as: 'ms' | 's' = 'ms'): number => {
		const dateOne = typeof a === 'string' ? new Date(a) : a
		const dateTwo = typeof b === 'string' ? new Date(b) : b
		const diviser = as === 's' ? 1000 : 1
		return dateTwo.getTime() - dateOne.getTime() / diviser
	}

	static getTcAsTime = (tc: number) => {
		return formatTimeMinuteSeconds(tc)
	}

	static getPercentage = (total: number, partial: number, decimals: number = 2) =>
		((100 * partial) / total).toFixed(decimals)

	static toDatetimeLocal = (date: Date) => {
		const YYYY = date.getFullYear()
		const MM = formatToTen(date.getMonth() + 1)
		const DD = formatToTen(date.getDate())
		const HH = formatToTen(date.getHours())
		const II = formatToTen(date.getMinutes())
		const SS = formatToTen(date.getSeconds())
		return YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II + ':' + SS
	}

	static getDefaultDate = () => {
		const t = new Date()
		t.setDate(t.getDate() + 1)
		const date = ('0' + t.getDate()).slice(-2)
		const month = ('0' + (t.getMonth() + 1)).slice(-2)
		const year = t.getFullYear()
		return `${year}-${month}-${date}T18:00`
	}

	/**
	 * @verify given date is greater than current date
	 * with given timezone
	 */
	static validDateWithTimezone = (ISODate: string, timezone: string) => {
		const currentDateTime = new Date()
		const formatter = new Intl.DateTimeFormat('en-US', {
			timeZone: timezone,
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit'
		})

		const currentDate = new Date(formatter.format(currentDateTime))
		const givenDate = new Date(ISODate)

		return givenDate >= currentDate
	}

	static formatToISO = (dateString: string): string => {
		return dateString.slice(0, 16) + ':00.000Z'
	}

	static applyCurrentTimezoneToDate = (ISODate: string) => {
		var offsetMilliseconds = new Date().getTimezoneOffset() * 60 * 1000 // Convert offset to milliseconds
		return new Date(new Date(ISODate).getTime() + offsetMilliseconds).toISOString()
	}
}
