import Spinner from '@assets/img/spinner.gif'
import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { ColoredPaperUnion } from './types'

const PREFIX = 'paper'

export const classes = {
	root: PREFIX,
	spinning: `${PREFIX}-spinning`
}
export const PaperWrapper = styled('div')<{ overflow?: string }>`
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 100%;
	padding: ${Theme.spacing(6)} 0;
	background-color: ${Theme.colors.blue2};
	border-radius: 30px;
	overflow: ${(props) => props.overflow};
	transform: translateZ(0); // Makes border-radius work with video / images
	font-family: PlusJakartaSans;
	&.${classes.spinning} {
		background: ${Theme.colors.blue2} url(${Spinner}) no-repeat center;
		background-size: 30px 30px;
	}
`
export const OutlinedPaper = styled.div`
	position: relative;
	box-sizing: border-box;
	width: 100%;
	border-radius: 30px;
	background-color: transparent;
	border: solid 1px ${Theme.colors.grey2};
	padding: ${Theme.spacing(4)} ${Theme.spacing(6)};
`

const WARNING_PREFIX = 'warning-paper'

export const warningPaperClasses = {
	root: WARNING_PREFIX,
	subTitle: `${WARNING_PREFIX}-subtitle`
}
const SUCCESS_PREFIX = 'success-paper'

export const successPaperClasses = {
	root: SUCCESS_PREFIX,
	subTitle: `${SUCCESS_PREFIX}-subtitle`
}

export const getColorByType = (type: ColoredPaperUnion, opacity: number = 1) => {
	switch (type) {
		case 'SUCCESS_PAPER':
			return Theme.colors.green(opacity)
		case 'WARNING_PAPER':
			return Theme.colors.red(opacity)
	}
}

export const ColoredPaperContainer = styled.div<{ type: ColoredPaperUnion }>`
	box-sizing: border-box;
	display: flex;
	width: 100%;
	padding: ${Theme.spacing(3)} ${Theme.spacing(4)};
	align-items: center;
	background-color: ${({ type }) => getColorByType(type, 0.2)};
	border-radius: 10px;
	& .${warningPaperClasses.subTitle} {
		display: flex;
		align-items: center;
	}
`

export const ColoredPaperIcon = styled.div<{ type: ColoredPaperUnion }>`
	box-sizing: border-box;
	width: 30px;
	min-width: 30px;
	height: 30px;
	padding: 9px;
	background-color: ${({ type }) => getColorByType(type)};
	border-radius: 100px;
`

export const ColoredPaperContent = styled.div`
	display: grid;
	gap: ${Theme.spacing(1)};
`
