import React from 'react'
import { ButtonContainer, ButtonContainerProps, StartAdornement } from './style'
import Theme from '@styles/theme'
import Icon from '@components/Micro/Icon'
import cx from 'classnames'
import { simpleButtonClasses } from './classes'

interface ButtonProps extends Omit<ButtonContainerProps, 'hasIcon'> {
	icon?: string
	iconLabel?: string
	iconRotate?: number
	onClick?: () => void
	bigIcon?: boolean
	disabled?: boolean
	fullWidth?: boolean
}

const SimpleButton: React.FC<ButtonProps> = ({
	disabled,
	icon,
	iconRotate = 0,
	onClick,
	bigIcon = false,
	fullWidth,
	children,
	variant = 'plain',
	...props
}) => {
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation()
		if (onClick) onClick()
	}

	return (
		<ButtonContainer
			className={cx(simpleButtonClasses.root, {
				[simpleButtonClasses.disabled]: disabled,
				[simpleButtonClasses.fullWidth]: fullWidth
			})}
			onClick={handleClick}
			hasIcon={!!icon}
			variant={variant}
			{...props}
		>
			<StartAdornement>
				<Icon name={icon} width={13} r={iconRotate} height={13} fill={props.color || Theme.colors.white()} />
			</StartAdornement>
			{children}
		</ButtonContainer>
	)
}

export default SimpleButton
