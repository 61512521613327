import React, { useRef } from 'react'
import { CopyBoxWrapper, CopyButtonWrapper, CopyTextWrapper } from './style'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { default as AtomOneDark } from 'react-syntax-highlighter/dist/cjs/styles/hljs/atom-one-dark'
import Tooltip from '@components/Micro/Tooltip'
import { SimpleGrid } from '@styles/Grid'
import { BaseFlex } from '@styles/Basics'
import Text from '@components/Micro/Text'
import { copyBoxClasses } from './classes'
import Theme from '@styles/theme'

interface Props {
	textToCopy: TextToCopy
	plain?: boolean
	alignItems?: string
	tooltipText?: string
	hideText?: boolean
	bicolor?: boolean
}

interface TextToCopy {
	content: string
	type?: string
	language?: string
}

const CopyBox: React.FC<Props> = ({
	textToCopy,
	plain = true,
	alignItems,
	tooltipText,
	hideText = false,
	bicolor = false,
	...props
}) => {
	//
	const infoRef = useRef(null)

	const copyTextToClipboard = async () => {
		navigator.clipboard.writeText(textToCopy.content)
		infoRef.current.innerText = 'COPIED!'
		await setTimeout(() => {
			infoRef.current.innerText = 'COPY'
		}, 1000)
	}

	const override = {
		fontSize: '13px',
		lineHeight: '130%',
		backgroundColor: 'inherit',
		fontFamily: 'monospace, monospace',
		fontWeight: '400',
		filter: 'contrast(140%)'
	}
	return (
		<div className={copyBoxClasses.root} {...props}>
			<SimpleGrid gap={Theme.spacing(3)}>
				{textToCopy.type && (
					<BaseFlex gap="5px">
						<Text variant="bold-xs">{textToCopy.type}</Text>
						{tooltipText && <Tooltip text={tooltipText} bicolor={bicolor} />}
					</BaseFlex>
				)}
				<CopyBoxWrapper
					alignItems={alignItems || 'center'}
					plain={plain}
					onClick={copyTextToClipboard}
					className={copyBoxClasses.wrapper}
				>
					{!hideText && (
						<CopyTextWrapper className={copyBoxClasses.text}>
							{textToCopy.language ? (
								<SyntaxHighlighter
									language={textToCopy.language}
									style={AtomOneDark}
									customStyle={override}
								>
									{textToCopy.content}
								</SyntaxHighlighter>
							) : (
								<div>{textToCopy.content}</div>
							)}
						</CopyTextWrapper>
					)}

					<CopyButtonWrapper className={copyBoxClasses.button} ref={infoRef}>
						COPY
					</CopyButtonWrapper>
				</CopyBoxWrapper>
			</SimpleGrid>
		</div>
	)
}

export default CopyBox
