import React from 'react'
import Icons from './icons'
import { css } from '@emotion/react'
import Style from './style'
import Utils from '@utils/index'
import Theme from '@styles/theme'
import { Box } from 'rebass'
import cx from 'classnames'

export type IconUnion = keyof typeof Icons

const Icon: React.FC<{
	name?: string
	url?: string
	width?: any
	height?: any
	r?: number
	fill?: any
	stroke?: any
	className?: string
	onClick?: (e?: any) => void
}> = ({
	name = null,
	url = null,
	width = null,
	height = null,
	r,
	fill = null,
	stroke = null,
	className = null,
	onClick
}) => {
	if (name) {
		name = name.toLowerCase()
		let customFill = null
		let customStroke = null

		// custom fill
		if (Utils.is.isArray(fill)) {
			let medias = ''
			for (const i in fill) {
				const index = parseInt(i)
				if (index !== fill.length - 1) {
					medias += `
				@media ${Theme.media(index)} {
					fill: ${fill[index]};
				}
			`
				}
			}
			customFill = css`
				fill: ${fill[fill.length - 1]};
				${medias}
			`
		} else {
			customFill = css`
				fill: ${fill};
			`
		}

		// custom stroke
		if (Utils.is.isArray(stroke)) {
			let medias = ''
			for (const i in stroke) {
				const index = parseInt(i)
				if (index !== stroke.length - 1) {
					medias += `
				@media ${Theme.media(index)} {
					stroke: ${stroke[index]};
				}
			`
				}
			}
			customStroke = css`
				stroke: ${stroke[stroke.length - 1]};
				${medias}
			`
		} else {
			customStroke = css`
				stroke: ${stroke};
			`
		}

		const customStyleIcon = css({ width, height })
		// const customStyleIconSvg = css`
		// 	fill: ${fill};
		// 	stroke: ${stroke};
		// `
		const rotation = r
			? css`
					transform: rotate(${r}deg);
			  `
			: null

		if (Icons[name]) {
			return (
				<>
					<Box as="i" css={[Style.icon, customStyleIcon, rotation]} onClick={onClick}>
						<Box
							id={`icon-${name}`}
							as="svg"
							className={cx(className)}
							css={[Style.iconSvg, customFill, customStroke]}
							viewBox={`${Icons[name].viewBox}`}
						>
							<use xlinkHref={`#${name}`} />
						</Box>
					</Box>
				</>
			)
		} else {
			// console.log(name)
			return <></>
		}
	} else if (url) {
		return <Box as="img" src={url} height="100%" />
	} else {
		return <></>
	}
}

export default Icon
