import { Tab } from '@components/TabView/types'
import { EventStatus, eventTabs, EventTag } from '@customTypes/event'

const tabToAlwaysFilter: EventTag[] = ['highlights', 'boosts'] // should be refacto
const liveTabToFilter: EventTag[] = ['chat', 'products', ...tabToAlwaysFilter]
const replayTabToFilter: EventTag[] = ['program', 'moderation', ...tabToAlwaysFilter]
export const moderatorReplayTabs: EventTag[] = ['chat', 'interactions']
export const moderatorLiveTabs: EventTag[] = ['moderation']
export const liveTabs: EventTag[] = ['program', 'interactions', 'moderation', 'settings']

export const getEventTabsByStatus = (status: EventStatus, isSuperAdmin: boolean, isModerator: boolean): Tab[] => {
	let newTabs
	switch (status) {
		case 'finishing':
		case 'replay':
			newTabs = eventTabs
				.filter((tab) => !replayTabToFilter.includes(tab.path as EventTag))
				.sort((a, b) => a.replayIndex - b.replayIndex)
			if (isModerator) {
				return (newTabs = newTabs.filter((tab) => moderatorReplayTabs.indexOf(tab.path) !== -1))
			}
			return newTabs
		case 'prelive':
			newTabs = eventTabs
				.filter((tab) => !liveTabToFilter.includes(tab.path as EventTag))
				.sort((a, b) => a.liveIndex - b.liveIndex)
			if (isModerator) {
				return (newTabs = newTabs.filter((tab) => moderatorLiveTabs.indexOf(tab.path) !== -1))
			}
			if (!isSuperAdmin) {
				return (newTabs = newTabs.filter((tab) => tab.path !== 'sms'))
			}
			return newTabs
		case 'live':
			newTabs = eventTabs
				.filter((tab) => !liveTabToFilter.includes(tab.path as EventTag))
				.sort((a, b) => a.liveIndex - b.liveIndex)
			if (isModerator) {
				return (newTabs = newTabs.filter((tab) => moderatorLiveTabs.indexOf(tab.path) !== -1))
			} else {
				newTabs = newTabs.filter((tab) => liveTabs.indexOf(tab.path) !== -1)
			}
			return newTabs
	}
}
