import React from 'react'
import { Box } from 'rebass'
import Style from './style'

const Video: React.FC<{
	media?: any
	loading?: any
	fade?: boolean
	source?: any
}> = ({
	media,
	loading = 'lazy', // eager or lazy
	fade = true,
	source
}) => {
	//

	return (
		<>
			<Box as="video" width={1} height={'100%'} css={[Style.img, fade ? Style.fade : null, Style.isLoaded]}>
				<Box
					as="source"
					src={source}
					type={media.mimeType}
					loading={loading}
					alt={media.alt}
					className="media"
				></Box>
			</Box>
		</>
	)
}

export default Video
