import { classes, SpinnerWrapper } from './style'

interface Props {
	width?: number | string
	height?: number | string
	padding?: number | string
	small?: boolean
}

const Spinner: React.FC<Props> = ({ width = '100%', height = '100%', padding = 'unset', small, ...props }) => {
	return (
		<SpinnerWrapper
			{...props}
			className={classes.root}
			width={width}
			height={height}
			alignItems="center"
			justifyContent="center"
			padding={padding}
			isSmall={small}
		/>
	)
}

export const spinnerClasses = classes

export default Spinner
