import { css } from '@emotion/react'
import Theme from '@styles/theme'

const PREFIX = 'placeholder'

export const classes = {
	root: PREFIX
}

export default {
	wrap: css`
		position: relative;
		width: 100%;
	`,

	hoverScale: css`
		overflow: hidden;
		cursor: pointer;
		&:hover .media {
			transform: scale(1.05);
		}
	`,

	overlay: css`
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 1;
	`,

	link: css`
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: 3;
	`,

	placeholder: css`
		width: 100%;
	`,

	img: css`
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		opacity: 0;
	`,

	fade: css`
		transition: opacity 2000ms ${Theme.eases.outExpo} 0ms;
	`,

	isLoaded: css`
		opacity: 1;
	`
}
