import AddWatchlistPopinContent from '@components/Pages/Channel/Highlights/Watchlists/AddWatchlistPopin'
import AddHighlightsToWatchlist from '@components/Pages/Channel/Highlights/Watchlists/Edit/List/AddHighlighToListPopin'
import { ModalBuild, ModalsTypeUnion } from './constants'
import AddDisplayConditionModal from '@components/Templates/DisplayConditionsBuilder/AddDisplayConditionModal'
import TestConditionPopin from '@components/Pages/Channel/Form/ChannelDomains/ChannelDomain/TestConditionPopin'

export const getModalBuild = (modalType: ModalsTypeUnion, metadata: any): ModalBuild => {
	switch (modalType) {
		case 'ADD_WATCHLIST':
			return {
				modalType,
				props: metadata,
				Component: <AddWatchlistPopinContent {...metadata} />
			}
		case 'ADD_HIGHLIGHTS_TO_WATCHLIST':
			return {
				modalType,
				props: metadata,
				Component: <AddHighlightsToWatchlist {...metadata} />
			}
		case 'ADD_DISPLAY_CONDITION':
			return {
				modalType,
				props: metadata,
				Component: <AddDisplayConditionModal {...metadata} />
			}
		case 'TEST_DISPLAY_CONDITION':
			return {
				modalType,
				props: metadata,
				Component: <TestConditionPopin {...metadata} />
			}
	}
}
