import { Domain } from '@customTypes/channel'
import { useApi } from '@helpers/contexts/apiContext'
import router from 'next/router'
import { useEffect, useMemo, useState } from 'react'

const useDomains = (): Domain[] => {
	const channelId = router.query.channelId as string
	const [domains, setDomains] = useState<Array<Domain>>([])
	const { getDomains } = useApi()

	useEffect(() => {
		if (channelId)
			getDomains(channelId).then((response: any) => {
				if (response.count) {
					setDomains(
						[...response.data]
							.filter((domain) => !domain.isTest)
							.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
					)
				}
			})
	}, [channelId])

	return useMemo(() => [...domains], [domains, channelId])
}

export default useDomains
