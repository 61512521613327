import Theme from '@styles/theme'
import styled from '@emotion/styled'
import { EmptyStateIconProps } from '.'

export const EmptyStateWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	align-content: center;
	flex-wrap: wrap;
	height: calc(100% - 20px);
	gap: ${Theme.spacing(4)};
`

export const EmptyStateIconWrapper = styled.div<EmptyStateIconProps>`
	display: flex;
	border-radius: 100%;
	background-color: ${Theme.colors.white()};
	width: ${({ width }) => width + 'px'};
	height: ${({ height }) => height + 'px'};
	& i {
		margin: auto;
		width: ${({ iconWidth }) => iconWidth + 'px'};
		height: ${({ iconHeight }) => iconHeight + 'px'};
	}
`

export const EmptyStateTitle = styled.div`
	width: 100%;
	color: ${Theme.colors.white()};
	font-weight: bold;
	font-size: 20px;
	text-align: center;
`

export const EmptyStateText = styled.div`
	width: 300px;
	color: ${Theme.colors.grey3};
	font-size: 14px;
	text-align: center;
	opacity: 0.75;
	line-height: 140%;
`
