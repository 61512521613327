import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
// import { persistStore } from 'redux-persist'

export const appInitialState = {
	storeUser: {},
	storeChannels: [],
	storeProducts: [],
	// storeDatas: null,
	// storeProducts: [],
	storeModal: null
}

export type State = {
	storeUser: any
	storeChannels: any
	storeProducts: any
	// storeDatas: any
	// storeCurrentStep: any
	// storeLivestreamSeekTo: any
	// storeProducts: any
	// storeStepsProgram: any
	// storeMessages: any
	// storeTimecodes: any
	// storeReplayTimecodes: any
	// storeUserRegistration: any
	// storePanels: any
	// storePopin: any
	storeModal: any
	// storeIsPip: number
	// storeIsFullscreen: boolean
}

export const actionTypes = {
	UPDATE_USER: 'UPDATE_USER',
	SET_CHANNELS: 'SET_CHANNELS',
	SET_PRODUCTS: 'SET_PRODUCTS',
	// SET_DATAS: 'SET_DATAS',
	// SET_CURRENT_STEP: 'SET_CURRENT_STEP',
	// SET_PRODUCTS: 'SET_PRODUCTS',
	// SET_STEPS_PROGRAM: 'SET_STEPS_PROGRAM',
	// LIVESTREAM_SEEK_TO: 'LIVESTREAM_SEEK_TO',
	// SET_MESSAGES: 'SET_MESSAGES',
	// SET_TIMECODES: 'SET_TIMECODES',
	// SET_REPLAY_TIMECODES: 'SET_REPLAY_TIMECODES',
	// UPDATE_USER: 'UPDATE_USER',
	// UPDATE_USER_REGISTRATION: 'UPDATE_USER_REGISTRATION',
	// SET_CHANNELS: 'SET_CHANNELS',
	// ADD_PANEL: 'ADD_PANEL',
	// REMOVE_LAST_PANEL: 'REMOVE_LAST_PANEL',
	// REMOVE_ALL_PANELS: 'REMOVE_ALL_PANELS',
	// ADD_POPIN: 'ADD_POPIN',
	// REMOVE_POPIN: 'REMOVE_POPIN',
	ADD_MODAL: 'ADD_MODAL',
	REMOVE_MODAL: 'REMOVE_MODAL'
	// SET_PIP: 'SET_PIP',
	// UNSET_PIP: 'UNSET_PIP',
	// SET_FULLSCREEN: 'SET_FULLSCREEN',
	// UNSET_FULLSCREEN: 'UNSET_FULLSCREEN'
}

// REDUCER

export const reducer: (state: State, action: any) => any = function (state: any, action: any): any {
	// export const reducer = (state = appInitialState, action) => {
	switch (action.type) {
		//

		// case actionTypes.SET_DATAS:
		// 	return Object.assign({}, state, {
		// 		storeDatas: action.datas
		// 	})

		// case actionTypes.SET_CURRENT_STEP:
		// 	return Object.assign({}, state, {
		// 		storeCurrentStep: action.step
		// 	})

		// case actionTypes.LIVESTREAM_SEEK_TO:
		// 	return Object.assign({}, state, {
		// 		storeLivestreamSeekTo: action.time
		// 	})

		case actionTypes.SET_PRODUCTS:
			return Object.assign({}, state, {
				storeProducts: action.products
			})

		// case actionTypes.SET_STEPS_PROGRAM:
		// 	return Object.assign({}, state, {
		// 		storeStepsProgram: action.stepsProgram
		// 	})

		// case actionTypes.SET_MESSAGES:
		// 	return Object.assign({}, state, {
		// 		storeMessages: action.messages
		// 	})

		// case actionTypes.SET_TIMECODES:
		// 	return Object.assign({}, state, {
		// 		storeTimecodes: action.timecodes
		// 	})

		// case actionTypes.SET_REPLAY_TIMECODES:
		// 	return Object.assign({}, state, {
		// 		storeReplayTimecodes: action.timecodes
		// 	})

		// Users

		case actionTypes.UPDATE_USER:
			return Object.assign({}, state, {
				storeUser: action.user
			})

		// Channel

		case actionTypes.SET_CHANNELS:
			return Object.assign({}, state, {
				storeChannels: action.channels
			})

		// // Registrations

		// case actionTypes.UPDATE_USER_REGISTRATION:
		// 	return Object.assign({}, state, {
		// 		storeUserRegistration: action.registration
		// 	})

		// // Panels

		// case actionTypes.ADD_PANEL:
		// 	window.history.pushState(state.storePanels.length + 1, null, '#' + (state.storePanels.length + 1))
		// 	return Object.assign({}, state, {
		// 		storePanels: [...state.storePanels, action.panel]
		// 	})

		// case actionTypes.REMOVE_LAST_PANEL:
		// 	window.history.pushState(
		// 		state.storePanels.length - 1,
		// 		null,
		// 		'#' + (state.storePanels.length === 0 ? 0 : state.storePanels.length - 1)
		// 	)
		// 	return Object.assign({}, state, {
		// 		storePanels: state.storePanels.filter((_, i) => i !== state.storePanels.length - 1),
		// 		storeIsPip: state.storePanels.length === 1 ? 0 : state.storeIsPip
		// 	})

		// case actionTypes.REMOVE_ALL_PANELS:
		// 	window.history.pushState(0, null, '#0')
		// 	return Object.assign({}, state, {
		// 		storePanels: [],
		// 		storeIsPip: 0
		// 	})

		// case actionTypes.ADD_POPIN:
		// 	return Object.assign({}, state, {
		// 		storePopin: action.popin
		// 	})

		// case actionTypes.REMOVE_POPIN:
		// 	return Object.assign({}, state, {
		// 		storePopin: null
		// 	})

		case actionTypes.ADD_MODAL:
			return Object.assign({}, state, {
				storeModal: action.modal
			})

		case actionTypes.REMOVE_MODAL:
			return Object.assign({}, state, {
				storeModal: null
			})

		// case actionTypes.SET_PIP:
		// 	return Object.assign({}, state, {
		// 		storeIsPip: state.storeIsPip !== 0 ? state.storeIsPip : +new Date()
		// 	})

		// case actionTypes.UNSET_PIP:
		// 	return Object.assign({}, state, {
		// 		storeIsPip: 0
		// 	})

		// // FULLSCREEN

		// case actionTypes.SET_FULLSCREEN:
		// 	return Object.assign({}, state, {
		// 		storeIsFullscreen: true
		// 	})

		// case actionTypes.UNSET_FULLSCREEN:
		// 	return Object.assign({}, state, {
		// 		storeIsFullscreen: false
		// 	})

		// case actionTypes.SET_PRODUCT_SPINNER:
		// 	return Object.assign({}, state, {
		// 		productSpinner: true
		// 	})

		// case actionTypes.UNSET_PRODUCT_SPINNER:
		// 	return Object.assign({}, state, {
		// 		productSpinner: false
		// 	})

		// case actionTypes.OPEN_PRODUCT_MODAL:
		// 	return Object.assign({}, state, {
		// 		productModalIsOpened: true
		// 	})

		// case actionTypes.CLOSE_PRODUCT_MODAL:
		// 	return Object.assign({}, state, {
		// 		productModalIsOpened: false
		// 	})

		// case actionTypes.OPEN_CART_MODAL:
		// 	return Object.assign({}, state, {
		// 		cartModalIsOpened: true
		// 	})

		// case actionTypes.CLOSE_CART_MODAL:
		// 	return Object.assign({}, state, {
		// 		cartModalIsOpened: false
		// 	})

		// case actionTypes.OPEN_CATALOG_MODAL:
		// 	return Object.assign({}, state, {
		// 		catalogModalIsOpened: true
		// 	})

		// case actionTypes.CLOSE_CATALOG_MODAL:
		// 	return Object.assign({}, state, {
		// 		catalogModalIsOpened: false
		// 	})

		// case actionTypes.OPEN_QUESTIONS_MODAL:
		// 	return Object.assign({}, state, {
		// 		questionsModalIsOpened: true
		// 	})

		// case actionTypes.CLOSE_QUESTIONS_MODAL:
		// 	return Object.assign({}, state, {
		// 		questionsModalIsOpened: false
		// 	})

		// // SUBSCRIBE MODAL

		// case actionTypes.SET_NOTIFY_MODAL:
		// 	return Object.assign({}, state, {
		// 		notifyModal: action.mode,
		// 		notifyModalValue: action.value,
		// 		notifyModalTracker: action.tracker,
		// 		notifyModalCallback: action.callback
		// 	})

		// case actionTypes.UNSET_NOTIFY_MODAL:
		// 	return Object.assign({}, state, {
		// 		notifyModal: null,
		// 		notifyModalValue: null,
		// 		notifyModalTracker: null,
		// 		notifyModalCallback: null
		// 	})

		// case actionTypes.OPEN_NOTIFY_MODAL:
		// 	return Object.assign({}, state, {
		// 		notifyModalIsOpened: true
		// 	})

		// case actionTypes.CLOSE_NOTIFY_MODAL:
		// 	return Object.assign({}, state, {
		// 		notifyModalIsOpened: false
		// 	})

		// // SUCCESS MODAL

		// case actionTypes.OPEN_SUCCESS_MODAL:
		// 	return Object.assign({}, state, {
		// 		successModalIsOpened: true
		// 	})

		// case actionTypes.CLOSE_SUCCESS_MODAL:
		// 	return Object.assign({}, state, {
		// 		successModalIsOpened: false
		// 	})

		// // ADD CART MODAL

		// case actionTypes.SET_ADD_CART_MODAL:
		// 	return Object.assign({}, state, {
		// 		addCartModal: action.product
		// 	})

		// case actionTypes.UNSET_ADD_CART_MODAL:
		// 	return Object.assign({}, state, {
		// 		addCartModal: null
		// 	})

		// case actionTypes.OPEN_ADD_CART_MODAL:
		// 	return Object.assign({}, state, {
		// 		addCartModalIsOpened: true
		// 	})

		// case actionTypes.CLOSE_ADD_CART_MODAL:
		// 	return Object.assign({}, state, {
		// 		addCartModalIsOpened: false
		// 	})

		// // UPDATE CART

		// case actionTypes.UPDATE_CART:
		// 	return Object.assign({}, state, {
		// 		cart: action.cart
		// 	})

		// // UPDATE USER

		// case actionTypes.UPDATE_USER:
		// 	return Object.assign({}, state, {
		// 		user: action.user
		// 	})

		// // UI LIGHT

		// case actionTypes.TOGGLE_UI_IS_LIGHT:
		// 	return Object.assign({}, state, {
		// 		uiIsLight: !state.uiIsLight
		// 	})

		// // PAGES

		// case actionTypes.SET_PAGES:
		// 	return Object.assign({}, state, {
		// 		pages: state.pages
		// 	})

		// case actionTypes.INCR_PAGE_DEEPNESS:
		// 	return Object.assign({}, state, {
		// 		pageDeepNess: state.pageDeepNess + 1
		// 	})

		// case actionTypes.DECR_PAGE_DEEPNESS:
		// 	return Object.assign({}, state, {
		// 		pageDeepNess: state.pageDeepNess - 1
		// 	})

		default:
			return state
	}
}

export function initializeStore(initialState = appInitialState) {
	// const isClient = typeof window !== 'undefined'
	// let store

	// if (isClient) {
	// 	const { persistReducer } = require('redux-persist')
	// 	const storage = require('redux-persist/lib/storage').default
	// 	const persistConfig = {
	// 		key: 'root',
	// 		storage
	// 		// whitelist: ['cartId']
	// 	}
	// 	store = createStore(
	// 		persistReducer(persistConfig, reducer),
	// 		initialState,
	// 		composeWithDevTools(applyMiddleware(thunkMiddleware))
	// 	)
	// 	store.__PERSISTOR = persistStore(store)
	// 	// store.__PERSISTOR.purge()
	// } else {
	// 	store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)))
	// }

	return createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)))
}
