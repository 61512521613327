import styled from '@emotion/styled'
import Theme from '@styles/theme'
import { Box } from 'rebass'
import { BaseField } from '@styles/Basics'

const PREFIX = 'search-input'

export const classes = {
	base: PREFIX,
	enter: `${PREFIX}-enter`,
	enterActive: `${PREFIX}-enter-active`,
	enterDone: `${PREFIX}-enter-done`,
	exit: `${PREFIX}-exit`,
	exitActive: `${PREFIX}-exit-active`,
	exitDone: `${PREFIX}-exit-done`
}

export const SearchInputWrapper = styled(Box)((props) => ({
	background: 'inherit',
	display: `${props.isHidden ? 'none' : 'flex'}`,
	position: 'relative',
	width: 'auto',
	flexGrow: 1,
	maxWidth: '50px',
	[`&.${classes.enterActive}, &.${classes.enterDone}`]: {
		transition: `all .3s ease 0s`,
		maxWidth: '100%',
		[`& ${ExpandableBox}`]: {
			flexGrow: 1,
			width: 'auto'
		}
	},
	[`&.${classes.exit}`]: {
		transition: `all .2s ${Theme.eases.outExpo} 0s`,
		[`& ${ExpandableBox}`]: {
			display: 'none',
			width: '0px'
		}
	},
	[`&.${classes.exitActive}, &.${classes.exitDone}`]: {
		transition: `all .6s ${Theme.eases.outExpo} 0s`,
		maxWidth: '50px',
		['& input']: {
			width: '0px'
		}
	}
}))

export const BaseInput = styled(BaseField)((props) => {
	return {
		padding: Theme.spacing(3),
		flexGrow: 1,
		cursor: `${props.isActive ? 'auto' : 'pointer'}`,
		alignItems: 'center',
		['&:hover']: {
			border: `1px solid ${props.isActive ? Theme.colors.grey3 : Theme.colors.grey2}`
		}
	}
})

export const ExpandableBox = styled(Box)(() => {
	return {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		flexGrow: 0,
		width: '0px',
		overflow: 'hidden',
		['& > input']: {
			width: '100%',
			marginLeft: '12px'
		}
	}
})

export const SearchTextField = styled(Box)(() => ({
	background: 'none',
	outline: 'none',
	border: 'none',
	appearance: 'none',
	fontSize: '14px',
	fontWeight: 'normal',
	fontFamily: 'PlusJakartaSans',
	lineHeight: '24px',
	color: Theme.colors.white()
}))

export const UnderlinedText = styled.u(() => ({
	fontSize: '12px',
	color: Theme.colors.grey3,
	cursor: 'pointer',
	textDecoration: 'underline',
	flexShrink: 1
}))
