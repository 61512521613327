import Icon, { IconUnion } from '@components/Micro/Icon'
import Theme from '@styles/theme'
import React from 'react'
import { emptyStateClasses } from './classes'
import { EmptyStateIconWrapper, EmptyStateText, EmptyStateTitle, EmptyStateWrapper } from './style'

export interface EmptyStateIconProps {
	width?: number
	height?: number
	iconWidth?: number
	iconHeight?: number
}

interface Props {
	title: string
	description: string
	iconName: IconUnion
	iconProps?: EmptyStateIconProps
}

const defaultIconProps: EmptyStateIconProps = {
	width: 50,
	height: 50,
	iconWidth: 16,
	iconHeight: 16
}

const EmptyState: React.FC<Props> = ({ title, description, iconName, iconProps: customIconProps }) => {
	const iconProps = { ...defaultIconProps, ...customIconProps }

	return (
		<EmptyStateWrapper className={emptyStateClasses.root}>
			<EmptyStateIconWrapper {...iconProps}>
				<Icon name={iconName} fill={Theme.colors.blue2} />
			</EmptyStateIconWrapper>
			<EmptyStateTitle>{title}</EmptyStateTitle>
			<EmptyStateText>{description}</EmptyStateText>
		</EmptyStateWrapper>
	)
}

export default EmptyState
