import { createContext, useContext } from 'react'
import useFirebaseDb from '@helpers/hooks/useFirebaseDb'
import { QuerySnapshot } from '@firebase/firestore-types'
import { TimecodeDocument } from '@customTypes/timecode'
import { ListenedHighlightStatus } from '@customTypes/highlight'

const notInitYet = <T,>(): Promise<T> => {
	return Promise.reject<T>('Not init yet')
}

const firebaseDbContext = createContext({
	//@ts-ignore
	listenChannel: (channelId, handler) => () => {},
	//@ts-ignore
	listenEvent: (eventId, handler) => () => {},
	//@ts-ignore
	listenEvents: (channelId, handler) => () => {},
	//@ts-ignore
	listenTimecodes: (eventId, handler) => () => {},
	//@ts-ignore
	getTimecodes: (eventId) => notInitYet<QuerySnapshot<TimecodeDocument>>(),
	//@ts-ignore
	getMessages: async (eventId) => notInitYet(),
	//@ts-ignore
	listenPolls: (eventId, channelId, handler) => () => {},
	//@ts-ignore
	listenWinningInstants: (eventId, channelId, handler) => () => {},
	//@ts-ignore
	listenPrompterMessages: (eventId, handler) => () => {},
	//@ts-ignore
	listenModeratedMessages: (eventId, handler) => () => {},
	//@ts-ignore
	listenModeratingMessages: (eventId, handler) => () => {},
	//@ts-ignore
	listenPinnedMessages: (eventId, handler) => () => {},
	//@ts-ignore
	listenBannedUsers: (eventId, handler) => () => {},
	//@ts-ignore
	listenFrontendNotifications: (eventId, channelId, handler) => () => {},
	//@ts-ignore
	listenDownloadedRecords: (eventId, channelId, handler) => () => {},
	listenProcessingHighlights:
		//@ts-ignore


			(channelId: string) =>
			//@ts-ignore
			(handler: (listenedHighlightStatus: ListenedHighlightStatus[]) => void): (() => void) => {}
})

export function DbProvider({ children }) {
	const db = useFirebaseDb()
	return <firebaseDbContext.Provider value={db}>{children}</firebaseDbContext.Provider>
}

export const useDb = () => useContext(firebaseDbContext)
