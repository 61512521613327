import React, { useEffect } from 'react'
import Router, { useRouter } from 'next/router'
import { useGlobalContext, ConfirmPopinTypesEnum } from '@helpers/contexts/global'
import { FormStatus } from 'types/common'
import isEqual from 'lodash.isequal'

interface UnsavedChangesHandlerProps {
	formStatus: FormStatus
	setStatusChange?: React.Dispatch<React.SetStateAction<string>>
	action?: () => void
	onRedirect?: () => void
	formData?: any
	initData?: any
	loading?: boolean
	hasChange?: boolean
	isValid?: boolean
	validator?: () => boolean
	querySensitive?: boolean
	checkQueryString?: boolean
	onCancel?: () => void
}

const UnsavedChangesHandler: React.FC<UnsavedChangesHandlerProps> = ({
	formStatus,
	setStatusChange,
	action,
	onRedirect,
	formData,
	initData,
	loading,
	hasChange,
	isValid = true,
	validator = () => true,
	querySensitive = true,
	checkQueryString = false,
	onCancel
}) => {
	/**
	 * @States
	 */
	const { asPath } = useRouter()

	const { push } = useRouter()
	const { openConfirmPopin, closeConfirmPopin, openSuccessNotification, redirectUrl, setRedirectUrl } =
		useGlobalContext()
	/**
	 * @Methods
	 */
	const interceptRouteChange = (url?: string): boolean => {
		let nextRootPath = url.split('?')[0]
		let currentRootPath = asPath.split('?')[0]
		if (checkQueryString) {
			nextRootPath = url
			currentRootPath = asPath
		}
		if (!querySensitive && nextRootPath === currentRootPath) {
			return true
		} else {
			setRedirectUrl(url)
			if (isValid && validator()) {
				openConfirmPopin(
					ConfirmPopinTypesEnum.UNSAVED_CHANGES,
					() => setStatusChange(FormStatus.SAVE_UNSAVED_CHANGES),
					() => {
						if (onCancel) onCancel()
						setStatusChange(FormStatus.CANCEL_AND_REDIRECT)
					}
				)
			} else {
				openConfirmPopin(
					ConfirmPopinTypesEnum.INVALID_UNSAVED_CHANGES,
					() => setStatusChange(FormStatus.CANCEL_AND_REDIRECT),
					() => {
						if (onCancel) onCancel
						setStatusChange(FormStatus.CHANGED)
					}
				)
			}

			return false
		}
	}

	/**
	 * @Effects
	 */
	useEffect(() => {
		if ((formData && initData) || hasChange) {
			if (!loading && formStatus !== FormStatus.SAVE_UNSAVED_CHANGES && formStatus !== FormStatus.CREATE) {
				const formHasChange = typeof hasChange === 'boolean' ? hasChange : !isEqual(formData, initData)
				setStatusChange(formHasChange ? FormStatus.CHANGED : FormStatus.INITIAL)
			}
		}
	}, [formData, initData])

	/**
	 * @intercept route change when unsaved form changes
	 */
	useEffect(() => {
		let intercept = hasChange && formStatus !== FormStatus.CANCEL_AND_REDIRECT && formStatus !== FormStatus.REDIRECT

		if (formStatus === FormStatus.CHANGED || intercept) {
			const onRouteChangeStart = (url?: string) => {
				const ok = interceptRouteChange(url)
				//@ts-ignore
				if (!ok) {
					Router.events.emit('routeChangeError')
					// need to throw an error to stop prevent route change
					throw 'intercept route change'
				}
			}
			Router.events.on('routeChangeStart', onRouteChangeStart)
			return () => {
				Router.events.off('routeChangeStart', onRouteChangeStart)
			}
		}
	}, [formStatus, hasChange, formData, isValid])

	/**
	 * @handle popins' events
	 */
	useEffect(() => {
		switch (formStatus) {
			case FormStatus.INITIAL:
			case FormStatus.CHANGED:
			case FormStatus.OPEN_UNSAVED_CHANGES_POPIN:
				break
			case FormStatus.CHANGE_TAB:
				if (onRedirect) {
					openSuccessNotification('Your changes have been validated')
					setTimeout(() => {
						onRedirect()
					}, 500)
				}
				break
			case FormStatus.REDIRECT:
				openSuccessNotification('Your changes have been validated')

				if (redirectUrl) {
					setTimeout(() => {
						push(redirectUrl)
					}, 500)
				}
				break
			case FormStatus.CANCEL_AND_REDIRECT:
				if (redirectUrl) {
					closeConfirmPopin()
					push(redirectUrl)
				}
				break
			case FormStatus.SAVE_UNSAVED_CHANGES:
				closeConfirmPopin()
				action()
				break
		}
	}, [formStatus])

	return <></>
}

export default UnsavedChangesHandler
