import { ProgressRingCircle, progressRingClasses, ProgressRingSvg } from './style'
import Theme from '@styles/theme'
import { useEffect, useRef } from 'react'

interface Props {
	percent: number
	size?: number
}

const ProgressCircle: React.FC<Props> = ({ percent = 0, size = 40 }) => {
	const circleRef = useRef<SVGCircleElement>()

	useEffect(() => {
		if (circleRef?.current) {
			const radius = circleRef?.current.r.baseVal.value
			const circumference = radius * 2 * Math.PI

			circleRef.current.style.strokeDasharray = `${circumference} ${circumference}`
			circleRef.current.style.strokeDashoffset = circumference.toString()

			const offset = (circumference - (percent / 100) * circumference).toString()
			circleRef.current.style.strokeDashoffset = offset
		}
	}, [circleRef?.current, percent])

	return (
		<ProgressRingSvg className={progressRingClasses.root} width={size * 2} height={size * 2}>
			<ProgressRingCircle
				className={progressRingClasses.circle}
				stroke={Theme.colors.green()}
				strokeWidth="4"
				strokeLinecap="round"
				fill="transparent"
				r={size}
				cx={size}
				cy={size}
				ref={circleRef}
			/>
		</ProgressRingSvg>
	)
}
export default ProgressCircle
