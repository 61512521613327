import React from 'react'
import { Box } from 'rebass'
import Style, { classes } from './style'
import Utils from '@utils/index'
import Theme from '@styles/theme'
import Video from '../Video'
import Picture from '../Picture'
import { computeSource } from '@utils/media_url_utils'

const Placeholder: React.FC<{
	sizes?: any
	media?: any
	style?: any
	color?: any
	fit?: string
	children?: any
	hoverScale?: boolean
	loading?: any
	onLoad?: any
	fade?: boolean
	forcedPadding?: number
	isVideo?: boolean
	className?: string
	noFormat?: boolean
}> = ({
	sizes,
	media,
	style,
	color = Theme.colors.black(0.04),
	fit = 'cover',
	children,
	hoverScale = false,
	onLoad,
	loading = 'lazy', // eager or lazy
	fade = true,
	forcedPadding,
	isVideo,
	className,
	noFormat = false
}) => {
	//

	sizes = !Utils.is.isArray(sizes[0]) ? [sizes] : sizes
	const paddings = []

	for (const size of sizes) {
		paddings.push(100.0 / (size[0] / size[1]) + '%')
	}

	if (!media) {
		media = {
			url: 'medias/default/cover-1600x900.jpg',
			alt: 'default image'
		}
	}
	return (
		<Box
			className={classes.root}
			backgroundColor={color}
			css={[Style.wrap, style, hoverScale ? Style.hoverScale : null]}
		>
			<Box className={className} css={Style.placeholder} pt={forcedPadding || paddings} />
			{!isVideo && (
				<Picture
					media={media}
					fit={fit}
					onLoad={onLoad}
					loading={loading}
					fade={fade}
					source={`${computeSource(media.url)}${noFormat ? '' : '?auto=format'}`}
				/>
			)}
			{isVideo && (
				<Video media={media} loading={loading} fade={fade} source={computeSource(media.url) + '?auto=format'} />
			)}
			{children}
		</Box>
	)
}

export const placeholderClasses = classes

export default Placeholder
