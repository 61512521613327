import Spinner from '@components/Micro/Spinner'
import { useAuth } from '@helpers/contexts/authUserContext'
import { useRouter } from 'next/router'
import { Flex } from 'rebass'

export function LoggedContext({ children }) {
	const channelId = useRouter().query.channelId
	const { userToken, isLoading } = useAuth()
	const { asPath, push } = useRouter()

	if (!isLoading) {
		// Redirect to login if not logged

		if (
			!userToken &&
			asPath.indexOf('/login') === -1 &&
			asPath.indexOf('/shopper') === -1 &&
			asPath.indexOf('/chat') === -1 &&
			asPath.indexOf(`/${channelId}/preview`) === -1 &&
			asPath.indexOf(`reset_password`) === -1
		) {
			push('/login')
			return <></>
		}

		// Else : show content

		return (
			<Flex as="section" flexWrap="wrap" justifyContent="center">
				{children}
			</Flex>
		)
	}

	return <Spinner />
}
