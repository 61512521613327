import React, { createContext, cloneElement, useContext, useEffect, useMemo, useReducer } from 'react'
import { ModalsActionType, modalsInitialContextState, ModalsTypeUnion } from './constants'
import { modalsReducer } from './logic'
import Popin from '@components/Templates/Popin'

interface ModalsContext {
	openModal?: (modalType: ModalsTypeUnion, metadata?, closeCurrent?: boolean) => void
	modalHistory?: ModalsTypeUnion[]
	closeModal?: (onClose?: Function) => void
}

interface ModalsProviderProps {}

const Context = createContext<ModalsContext | null>({})

export const useModalsContext = () => useContext(Context)

export const ModalsProvider: React.FC<ModalsProviderProps> = ({ children }) => {
	const [{ modalOpen, currentModal, modalsQueue, modalHistory }, manageModals] = useReducer(
		modalsReducer,
		modalsInitialContextState
	)

	const openModal = (modalType: ModalsTypeUnion, metadata: any, closeCurrent: boolean) =>
		manageModals({
			type: ModalsActionType.OPEN_MODAL,
			modalType,
			closeCurrent,
			metadata
		})

	const closeModal = () => {
		manageModals({ type: ModalsActionType.CLOSE_MODAL })
	}

	useEffect(() => {
		if (modalsQueue.length > 0) {
			manageModals({ type: ModalsActionType.OPEN_NEXT_MODAL })
		}
	}, [modalsQueue])

	const values = useMemo(
		(): ModalsContext => ({
			openModal,
			closeModal,
			modalHistory
		}),
		[modalsQueue, modalHistory, modalOpen]
	)

	return (
		<Context.Provider value={values}>
			{children}
			<Popin isOpened={modalOpen} onClose={closeModal}>
				{currentModal?.Component && cloneElement(currentModal.Component, currentModal.props)}
			</Popin>
		</Context.Provider>
	)
}
